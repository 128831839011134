import React, { Component } from "react";
import Time from 'react-time';

import { Tooltip, OverlayTrigger } from "react-bootstrap";

class Message extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="rcw-message">
          {!this.props.content.user ?
            <img src={this.props.content.logo} className="rcw-avatar" alt="profile" /> : ''
          }
          <div className={this.props.content.user ? "rcw-client" : "rcw-response"}>
            <div className="rcw-message-text">               
            <p /* onFocus={this.props.findIndex((message)=>message.index == 56)} */>{this.props.content.message}</p>
              {/* <p>{console.log(this.props)}</p> */}
              {!this.props.content.user} {
                <span className="rcw-respname">{this.props.content.respname}</span>
              }
              <div className="rcw-datetime">
			          <Time value={this.props.content.datetime} format="DD/MM/YYYY HH:mm" />
                {this.props.content.edit ? <span> (Editado)</span> : ''}
              </div>
              {this.props.content.user ?
                <div className="rcw-action-buttons">
                  <OverlayTrigger placement="top" overlay={<Tooltip id="edit_tooltip">Editar</Tooltip>}>
                    <i className="fa fa-edit" onClick={() => this.props.onUpdate(this.props.index)} />
                  </OverlayTrigger>
                  <OverlayTrigger placement="top" overlay={<Tooltip id="remove_tooltip">Remover</Tooltip>}>
                    <i className="fa fa-trash" onClick={() => this.props.onRemove(this.props.index)} />
                  </OverlayTrigger>
                </div>
              : '' }
            </div>
          </div>
      </div>
    );
  }
}

export default Message;
