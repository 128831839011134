import React, { Component } from "react";
import {impact_texts, urgency_texts} from "./val.jsx";
import { FormControl } from "react-bootstrap";

import api from "services/api";

class ListOfVal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options : {},
      error   : false
    };
  }

  componentDidMount() {

    if(this.props.name === 'impact'){
      this.setState({
        options: impact_texts
      });
    }

    else if(this.props.name ==='urgency'){
      this.setState({
        options: urgency_texts
      });
    }

    else{
      api
        .post("/list_of_val.php", { type : this.props.type })
        .then(response => {
          this.setState({
            options: response.data.lov
          });
        })
        .catch(error => console.log(error.response));
    }
}

  render() {
    const { options } = this.state;

    let optionsList = options !== undefined ? Object.keys(options).map((k) => {
      return (
        //<option key={k} value={k} selected={this.props.value == options[k]}>{options[k]}</option>
        <option key={k} value={options[k]} selected={this.props.value == options[k]}>{options[k]}</option>
      )
    }, this) : [];

    return (
      <FormControl 
        componentClass="select" 
        name={this.props.name} 
        ref={this.props.reference} 
        onChange={this.props.onChange ? this.props.onChange : ''}
        readOnly={this.props.readOnly ? this.props.readOnly : false}
        disabled = {this.props.readOnly ? this.props.readOnly : false}
      >
        {this.props.all !== undefined && this.props.all ? <option value="">Todas</option> : <option></option>}
        {optionsList}
      </FormControl>
    );
  }
}

export default ListOfVal;