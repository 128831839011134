import React, { Component } from "react";


import { Grid, Row, Col } from "react-bootstrap";
import loading    from "assets/img/loading.gif";
// react component for creating dynamic tables
import ReactTable from "react-table";
import Card       from "components/Card/Card.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import api        from "services/api";

import 'moment/locale/pt-br.js';


class FavPage extends Component {

  constructor(props){
    super(props);
    this.state = {
      favs  : [],
      fav_config : [],
      pages : 0,
      error : false,
      alert : '',
  
      loading   : true
    }
  }

  componentDidMount(){
    this.getFavs();
  }
 
  getFavs= async() =>{
   try{
      const response = await api.post("/favorites.php", { 
        method    : 'getFavoritesAdm',
        /*sorted    : sorted,
        postn     : sessionStorage.getItem('postn')*/
      });

      if (response.data == 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {
        
      
        this.setState({
          favs: 
          response.data.favlist.map((row) => {

            return{
              fav_id       : row['rowid'],
              fav_name     : row['fav_name'],
              screen_title : row['screen_title'],
              view_title   : row['view_title'],
              last_upd     : row['last_upd'],
              filtro       : row['filtro']
            };
          }),
          fav_config : response.data.favconfig,
          fav_qtd : response.data.favqtd,
          loading : false
        });
  
      }

    }
    catch(err){
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }

  }

  parseFilters(obj, cfg_structure){
	  var gp_operator = obj['groupOp'];
	  var gp_rules = obj;

	  var rules_size = gp_rules['rules'].length;

    if(gp_operator === 'AND'){
      cfg_structure.push(<span id='conditionLg'>[Todas verdadeiras]</span>);
    }
    else if(gp_operator === 'OR'){
      cfg_structure.push(<span id='conditionLg'>[Alguma verdadeira]</span>);
    }

	  for(var i=0; i<rules_size; i++){
	  	var field, op, value;

      field = gp_rules['rules'][i]['f'];
      if (field ==='Name'){
        field = 'Cód.Ticket';
      }
      
      op = gp_rules['rules'][i]['op'];
      value = gp_rules['rules'][i]['v'];

      cfg_structure.push(<li> {field+' '+op+' "'+value+'"'} </li>);
	  	
	  }
  
	  if(obj['groups']!== undefined && obj['groups'] !== '' && obj['groups']!== null)
	  {
    
      var temp = [];
      cfg_structure.push(<br/>);
      for(var i=0; i< obj['groups'].length; i++){
        temp = this.parseFilters(obj['groups'][i], temp);
      }
      
      cfg_structure.push(<li><ul> {temp}</ul></li>);
	  }
	  return cfg_structure;
  }

  openFavInfo (param){
    
    var struc = [];

    struc = this.parseFilters(this.state.fav_config[param.original.fav_id], struc);

    var cfg = <div id="favDesc">
                <h3>Favorito: {param.original.fav_name}</h3>
                  <ul id="listRoot">
                    {struc}
                  </ul>
              </div>
              
    try{
      this.setState({
        alert : (
          <SweetAlert 
          style={{ display: "block", marginTop: "-240px"}}
          title=''
          onConfirm={() => this.hideAlert()}>{cfg}</SweetAlert>
        )
      });
    }
  
    catch(err){
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }
  
  }  


  hideAlert() {
    this.setState({
      alert : null
    });
  }

  render(){

    return(
      <div className="main-content">
        {this.state.alert}
      <Grid fluid>
        <Row>
          <Col md={12}>
            <Card
              minheight={true}
              title='Lista de favoritos'
              loadingText={<img src={loading} className="loading" alt="Carregando.." />}
              loading={this.state.loading}

              content={
                <ReactTable
                  ref={(instance) => { this.table = instance; }}
                  data={this.state.favs}
                  getTrProps={(state, rowInfo, column, instance) => ({
                    onDoubleClick: e => 
                      this.openFavInfo(rowInfo)
                    })
                  }
                  columns={[
                    
                    {
                      Header: "",
                      accessor: "spacer",
                      sortable: false,
                      //filterable: this.state.filter ? true : false,
                    },

                    {
                      Header: "ID",
                      accessor: "fav_id",
                      sortable: false,
                      show: false
                      //filterable: this.state.filter ? true : false,
                    },

                    {
                      Header: "Favorito",
                      accessor: "fav_name",
                      sortable: false
                      //filterable: this.state.filter ? true : false,
                    },
                    {
                      Header: "Módulo",
                      accessor: "screen_title",
                      sortable: false
                      //filterable: this.state.filter ? true : false,
                    },
                    {
                      Header: "Tela",
                      accessor: "view_title",
                      sortable: false
                      //filterable: this.state.filter ? true : false
                    },
                    {
                      Header: "Atualizado em",
                      accessor: "last_upd",
                      sortable: false,
                      
                    },
                    {
                      Header: "",
                      accessor: "empty",
                      sortable: false
                    }
                    
                  ]}
                  resized={[  
                    {"id": "spacer", "value": 50},
                    {"id": "fav_name", "value": 200},
                    {"id": "screen_title", "value": 200},
                    {"id": "view_title", "value": 200},
                    {"id": "last_upd", "value": 160}
                    
                  ]}
                  defaultPageSize={this.state.fav_qtd}
                  minRows={this.state.fav_qtd}
                  showPaginationTop={false}
                  showPaginationBottom ={false}
                  className="-striped -highlight"
                  previousText="Anterior"
                  nextText="Próxima"
                  loadingText={<img src={loading} className="loading" alt="Carregando.." />}
                  noDataText={this.state.loading ? "" : (this.state.error ? this.state.error : "Sem registros encontrados")}
                  pageText="Página"
                  ofText="de"
                  rowsText="registros"
                  loading={this.state.loading}
                  //manual // this would indicate that server side pagination has been enabled 
                
                  /*onFetchData={(state, instance) => {
                    this.setState({loading: true});
                    this.getFavs();
                  }}*/
              />
                
              }
            />
           {/*  <span> A criação e edição de favoritos deve ser feita pelo <a style={{color:'#6204BF'}} href = 'http://ecrm.bexpert.net.br/ecrm' target='_blank'>ECRM</a></span> */}
          </Col>
        </Row>
      </Grid>
    </div>
    )
  }
}

export default FavPage;
