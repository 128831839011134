import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";

import Button from "components/CustomButton/CustomButton.jsx";

class FooterForm extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      alerta: false
    }
  }

  hideAlert = (e) => {
    this.setState({ alerta: false })
  }

  componentDidMount(){
  }

  handleAlertConfirm = (e) => {
    e.preventDefault()
    
    this.setState({ alerta: true })
    
  }
  
  
  render() {
    return (
      <div>
        {this.props.disabled !== undefined && !this.props.disabled ?
          <>
            {/* <button className="btnFecharChamado" onClick={this.handleAlertConfirm}>Finalizar Chamado</button> */}
            <button type="submit" className="btnResetPassword" fill onClick={this.props.click}>Salvar alterações</button>
          </>
          : ''
        }
        <NavLink to={`../../${this.props.url}`}>
          <button className="btnResetPassword" fill>Voltar</button>
        </NavLink>
        <div className="clearfix"></div>
        <SweetAlert
          show={this.state.alerta}
          showCancel
          style={{ display: "block", marginTop: "-240px" }}
          warning
          confirmBtnText="Sim"
          cancelBtnText="Não"
          title="Gostaria de finalizar o chamado"
          onConfirm={this.hideAlert}
          onCancel={this.hideAlert}
          >
          Por favor, tente novamente.
        </SweetAlert>
      </div>
    );
  }
}

export default FooterForm;
