import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import Footer from "components/Footer/Footer.jsx";
import PagesHeader from "components/Header/PagesHeader.jsx";

// dinamically create pages routes
import pagesRoutes from "routes/pages.jsx";


//import bgImage from "assets/img/bggrupobex.png";
import accImgLogin from 'assets/img/accentureLoginNew.png';

class Pages extends Component {
  getPageClass() {
    var pageClass = "";
    switch (this.props.location.pathname) {
      case "/login":
        pageClass = " login-page";
        break; 
      case "/authenticator-page":
        pageClass = " authenticator-page";
        break; 
      case "/validation-page":
        pageClass = " validation-page";
        break;   
      case "/reset-mfa-page":
        pageClass = " reset-page";
        break;  
      case "/reset-password-page":
        pageClass = "reset-password-page";
         break;  
      case "/new-password-page":
        pageClass = "new-password-page";
        break;    
      case "/pages/register-page":
        pageClass = " register-page";
        break;
      case "/pages/lock-screen-page":
        pageClass = " lock-page";
        break;
      default:
        pageClass = "";
        break;
    }
   

    console.log(this.props.location.pathname);
    return pageClass;
  }
  componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  render() {
    return (
      <div>
        {/*<PagesHeader />*/}
        <div className="wrapper wrapper-full-page">
          <div
            className={"full-page" + this.getPageClass()}
            data-image={accImgLogin}
          >
            <div className="content">
              <Switch>
                {pagesRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      component={prop.component}
                      key={key}
                    />
                  );
                })}
              </Switch>
            </div>
            {/*<Footer transparent />*/}
            <div
              className="full-page-background"
              style={{ backgroundImage: "url(" + accImgLogin + ")" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Pages;
