import React, { Component } from "react";
import { Timeline, TimelineEvent } from '@mailtop/horizontal-timeline';
import { FaStar, FaClipboardList, FaDatabase, FaExclamation, FaCode, FaClipboardCheck, FaClock, FaCheckCircle} from 'react-icons/fa';
import api from "services/api";
import { Button } from "react-bootstrap/lib/InputGroup";
import { OverlayTrigger } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { Tooltip } from "chart.js";

class HeaderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      severity : {}
    }
  }

  componentDidMount() {
  
    api
      .post("/list_of_val.php", { type : "ADJUST_PRIORITY" })
      .then(response => {

        let self = this

        Object.keys(response.data.lov).map((index) => {
          self.setState(prevState => ({
            severity: {
              ...prevState.severity,
              [response.data.lov[index]] : response.data.lov[index]
            }
          }));
        })

      })
      .catch(error => console.log(error.response));
  }

  render() {

    /*let status = {
      'Aberto'                : 'statusAberto',
      'Atribuído'             : 'statusAtribuido',
      'Validado'              : 'statusValidado',
      'Melhoria pendente'     : 'statusMelhoria',
      'Em desenvolvimento'    : 'statusDesenvolvimento',
      'Parado'                : 'statusParado',
      'Pendente informação'   : 'statusPendenteInformacao',
      'Em Homologação'        : 'statusHomologacao',
      'Homologação aprovada'  : 'statusHomologacaoAprovada',
      'Homologação rerovada'  : 'statusHomologacaoReprovada',
      'Pendente aprovação'    : 'statusPendenteAprovacao',
      'Finalizado'            : 'statusFinalizado'
    }*/
    var statuses = '';
    if(this.props.status_lst !== undefined && this.props.status_lst !== ''){
      var flg_completed = true;
      statuses = this.props.status_lst.map((row) =>{
        let current_flg = false;
        let icon_map = {
          'Aberto'                  : FaStar,
          'Pendente Cliente'        : FaClipboardList,
          'Backlog'                 : FaDatabase,
          'Priorizado'              : FaExclamation,
          'Em Andamento'            : FaCode,
          'Validação'               : FaClipboardCheck,
          'Aguardando implantação'  : FaClock,
          'Finalizado'              : FaCheckCircle
        }
  
        let componentList = [], 
            obj_color = (flg_completed )? '#9305F2' : '#D3D3D3', 
            obj_icon = icon_map[row];
  
        if(this.props.ticket.status === row) {
          obj_color = '#6204BF';
          flg_completed = false;
          current_flg = true;
        }
        componentList.push(<TimelineEvent title={row} color={obj_color} icon={obj_icon} completed = {current_flg || flg_completed }/>);
        return componentList
      }); 
    }

    return (
      <div style={{color:'#000', fontWeight:'bold'}} className={`header-form ${this.props.className ? this.props.className : ""}`}>
        <div>
          <h4 className="title-form">
            {this.props.ticket.severity !== '' ?
              <div className={`img-header circle_${this.props.ticket.severity}`} title={this.state.severity[this.props.ticket.severity]}></div> 
            : ''}

            {this.props.ticket.code}{this.props.ticket.title !== '' ? ` -  ${this.props.ticket.title}` : ''} {this.props.ticket.title !== '' ? <NavLink to={ '/tickets/view/' + this.props.ticket.code}><i className="fa fa-edit" style={{color:'#6204BF'}}/></NavLink>: ''}
           
                  
                
              
          </h4>
        </div>
        <Timeline variant = 'simple-custom'>
          {statuses}
        </Timeline> 
      </div>
    );
  }
}

export default HeaderForm;
