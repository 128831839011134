import React, { Component } from "react";

import Card from "components/Card/Card.jsx";

import { Grid, Row, Col } from "react-bootstrap";

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import TabForm from "../../../components/SharedForm/TabForm";
import Message from "../../../components/Message/Message";

import 'react-chat-widget/lib/styles.css';

import logo from "assets/img/default-avatar.png";
import logo2 from "assets/img/default-avatar-2.png";
import send from "assets/img/send.png";
import cancel_edit from "assets/img/cancel-edit.png";

import SweetAlert from "react-bootstrap-sweetalert";

class Interactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value     : '',
      messages  : [
        {rowid : '1', logo : logo,   message : 'Qualquer dúvida, estou à disposição',                    respname : 'João de Souza',   datetime : new Date('2019-04-10 09:30'), user : false },
        {rowid : '2', logo : '',     message : 'Favor detalhar o cenário para reprodução do problema.',  respname : '',                datetime : new Date('2019-04-10 10:01'), user : true  },
        {rowid : '3', logo : logo2,  message : 'Anexado arquivo com as evidências encontradas.',         respname : 'Maria da Silva',  datetime : new Date('2019-04-11 14:25'), user : false },
        {rowid : '4', logo : '',     message : 'Obrigado!',                                              respname : '',                datetime : new Date('2019-04-11 16:47'), user : true  }
      ],
      edit  : false,
      rowid : false,
      alert : null
    }
  }

  componentDidMount(){
    this.inputMsg.focus();
  }

  onChangeValue = event => {
    this.setState({ value: event.target.value });
  };

  onAddItem = (e) => {

    this.setState(state => {
      if (state.value !== '') {
        if (state.edit && state.rowid) {
          this.onConfirmUpdateItem()
        } else {
          const messages = [...state.messages,
            {message : state.value, datetime : new Date(), user : true }
          ];

          return {
            messages,
            value: '',
          };
        }
      }
    });
  }

  onUpdateItem = i => {
    this.inputMsg.focus()

    this.setState(state => {
        let id
        let msg

        state.messages.map((item, j) => {
          if (j === i) {
            id  = j;
            msg = item.message
          }
        });

        return {
          value : msg,
          edit  : true,
          rowid : id
        };
    });
  };

  onConfirmUpdateItem = i => {
    this.setState(state => {
      if (state.value !== '') {
        console.log(state)
        const messages = state.messages.map((item, j) => {
          if (j === state.rowid && state.value !== item.message) {
            return {message : state.value, datetime : new Date(), user : true, edit : true };
          } else {
            return item;
          }
        });

        return {
          messages,
          value : '',
          edit  : false,
          rowid : false
        };
      }
    });
  };

  onConfirmRemoveItem(i) {
    this.setState({
      alert: (
        <SweetAlert 
              warning
              style={{ display: "block", marginTop: "-240px" }}
              showCancel
              confirmBtnText="Sim"
              cancelBtnText="Não"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={<span className="spanAlert">Tem certeza que deseja remover esta mensagem?</span>}
              onConfirm={() => this.onRemoveItem(i)}
              onCancel={() => this.hideAlert()}
        >Esta ação não poderá ser desfeita!</SweetAlert>
      )
    });
  }

  onRemoveItem = i => {
    this.setState(state => {
      const messages = state.messages.filter((item, j) => i !== j);

      return {
        messages,
        alert: null
      };
    });
  };

  onCancelEdit = i => {
    this.inputMsg.focus()

    this.setState(state => {
      return {
        value : '',
        edit  : false
      };
    });
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.onAddItem()
    } else if (e.key === 'Escape' && this.state.edit) {
      this.onCancelEdit()
    }
  }

  render() {
    let handle = this.props.match.params.handle;

    let ticket = {
      'code'      : this.props.match.params.handle,
      'title'     : 'Erro ao exportar dados',
      'status'    : 'Reprovado',
      'severity'  : '1'
    }

    return (
      <div className="main-content">
        {this.state.alert}
        <HeaderForm ticket={ticket}></HeaderForm>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <form>
                    <TabForm handle={handle} active="interactions"></TabForm>
                      <div id="messages" className="rcw-messages-container">
                        {
                          this.state.messages.map((item, key) => (
                            <Message index={key} content={item} onRemove={this.onConfirmRemoveItem.bind(this)} onUpdate={this.onUpdateItem}></Message>
                          ))
                        }
                        
                      </div>
                    
                      <div className="rcw-sender">
                        <button type="button" className={"rcw-cancel-edit" + (!this.state.edit ? " display-none" : "")}>
                          <img src={cancel_edit} className="rcw-cancel-icon" alt="cancel" onClick={this.onCancelEdit} />
                        </button>
                        <input ref={(input) => { this.inputMsg = input; }} type="text" className="rcw-new-message" name="message" placeholder="Digite uma nova mensagem..." autocomplete="off" value={this.state.value} onChange={this.onChangeValue} onKeyDown={this._handleKeyDown} />
                        <button type="button" className="rcw-send" onClick={this.onAddItem}>
                          <img src={send} className="rcw-send-icon" alt="send" />
                        </button>
                      </div>

                    <FooterForm url="admtickets"></FooterForm>
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Interactions;