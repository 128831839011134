import LoginPage from "views/Pages/LoginPage.jsx";
import AuthenticatorPage from "views/Pages/AuthenticatorPage.jsx";
import ValidationPage from "views/Pages/ValidationPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import LockScreenPage from "views/Pages/LockScreenPage.jsx";
import ResetPasswordAcc from "views/Pages/ResetPasswordAcc.jsx";
import NewPassword from "views/Pages/NewPassword.jsx";
import ResetMFAPage from "../views/Pages/ResetMFAPage";

var pagesRoutes = [
  {
    path: "/login",
    name: "Login Page",
    mini: "LP",
    component: LoginPage
  },
  {
    path: "/pages/register-page",
    name: "Register",
    mini: "RP",
    component: RegisterPage
  },
  {
    path: "/pages/lock-screen-page",
    name: "Lock Screen Page",
    mini: "LSP",
    component: LockScreenPage
  },
  {
    path: "/authenticator-page",
    name: "Authenticator Page",
    mini: "AP",
    component: AuthenticatorPage
  },
  {
    path: "/validation-page",
    name: "Validation Page",
    mini: "VP",
    component: ValidationPage
  },
  {
    path: "/reset-mfa-page",
    name: "Reset MFA Page",
    mini: "RMP",
    component: ResetMFAPage
  },
  {
    path: "/reset-password-page",
    name: "Reset Password Acc",
    mini: "RPP",
    component: ResetPasswordAcc
  },
  {
    path: "/new-password-page",
    name: "New Password",
    mini: "NPP",
    component: NewPassword
  },

];

export default pagesRoutes;
