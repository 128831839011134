import React, { Component } from "react";

export class Legend extends Component {

  render() {

    const values = this.props.value

    let legends = values !== undefined ? Object.keys(values).map((k) => {
      return (
        <div key={k} className="legend-option"><i className="fa fa-circle" style={{color : values[k].color}} /> {values[k].value}</div>
      )
    }, this) : [];

    return <div className="legend-box">{legends}</div>;
  }
}

export default Legend;