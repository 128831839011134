import React, { Component } from "react";

import { Grid, Row, Col } from "react-bootstrap";
import loading    from "assets/img/loading.gif";

import api from "services/api";

import 'moment/locale/pt-br.js';


class BIPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            biUrl : '',
            error: ''
        }
    }
    componentDidMount(){
        api.post("/list_of_val.php", { type : "POWERBI" }).then(
            res => {
                if(res.data === 'Validation Error') {
                    this.setState({ error: "Usuário e/ou senha inválida." });
                  } else{
                      this.setState({
                          loading: false,
                          biUrl : res.data.lov["01 - src"]
                      })
                  }
            }
        );
    
    }
    render(){
        return(
            
                this.state.loading ? 
                    <Row><img src = {loading} alt = "Carregando.."/></Row> :
                    <iframe src = {"https://app.powerbi.com/"+this.state.biUrl} 
                            width  = "100%" 
                            height ="100%" 
                            allowFullScreen = {true}
                    />
                
            
        );
    }
}

export default BIPage;
