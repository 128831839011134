import React, { Component } from "react";
import { notificationEvent } from '../../services/notifications'
import { NavLink } from "react-router-dom";

import circle_notification from 'assets/img/icons/severity/1.png';

import api from "services/api";

class TabForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificatioCount: this.getNotificationCounts()
    }
  }
  getCircle() {
    return (
      <div>
        <div className="number-interection">{this.state.notificatioCount}</div>
      </div>
    )
  }

  componentDidMount() {
    notificationEvent.addEventListener('notification received', this.notificationReceived)

  }

  componentDidUpdate(prevProps) {
    if (prevProps.handle !== this.props.handle) {
      this.setState({
        notificatioCount: this.getNotificationCounts()
      })
    }
  }

  getNotificationCounts = () => {
    const localNotification = JSON.parse(localStorage.getItem('notification'))
   
    let count = 0
    localNotification.forEach(local => {
      if (local.type === 'NEW_INTERACTION' && local.ticket_code === this.props.handle) {
        count += 1
      }
    })

    return count
  }

  notificationReceived = () => {
    const count = this.getNotificationCounts()

    if (count !== this.state.notificatioCount) {
      this.setState({
        notificatioCount: count
      })
    }
  }

  render() {
    return (
      <div>
        <ul className="nav nav-tabs">
          <li className={this.props.active === 'home' ? 'active' : ''}><NavLink style={{ color: '#9305F2' }} to={'../view/' + this.props.handle}>Geral</NavLink></li>

          {this.props.admin !== undefined && this.props.admin === true ?
            <li className={this.props.active === 'tr' ? 'active' : ''}><NavLink style={{ color: '#9305F2' }} to={'../tr/' + this.props.handle}>TR</NavLink></li>
            : ''}

          <li className={this.props.active === 'hist' ? 'active' : ''}><NavLink style={{ color: '#9305F2' }} to={'../hist/' + this.props.handle}>Histórico</NavLink></li>
          <li className={this.props.active === 'files' ? 'active' : ''}><NavLink style={{ color: '#9305F2' }} to={'../files/' + this.props.handle}>Anexos</NavLink></li>
          <li className={this.props.active === 'interactions' ? 'active' : ''}><NavLink style={{ color: '#9305F2' }} to={'../interactions/' + this.props.handle}>{this.state.notificatioCount > 0 ? this.getCircle() : ''}Interações</NavLink></li>
        </ul>

        {this.props.showButtons !== undefined && this.props.showButtons === true ?
          <div className="dvbuttons">
            {
              this.props.buttons !== undefined && this.props.buttons.length > 0 ?
                this.props.buttons.map((item, i) => (
                  <button key={i} type="button" className="btn-secondary"
                    onClick={this.props.buttonclick}
                    id={this.props.rowid}
                    value={item.value}>{item.name}</button>
                ))
                : ''
            }
          </div>
          : ''}

      </div>
    );
  }
}

export default TabForm;
