import React, { Component } from "react";

import Card from "components/Card/Card.jsx";
import { getToken } from "../../../services/auth";
import { Grid, Row, Col, ControlLabel, FormGroup, FormControl } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import TabForm from "../../../components/SharedForm/TabForm";
import ListOfVal from "../../../components/SharedForm/ListOfVal";
import matrix_img from "../../../assets/img/impact_urgency.png";

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import SweetAlert from "react-bootstrap-sweetalert";

import api from "services/api";

import loading    from "assets/img/loading.gif";

import Autosuggest from 'react-autosuggest';

registerPlugin(FilePondPluginFileValidateType);

const theme = {
  container: {
    position: 'relative'
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 39,
    width: "100%",
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'inherit',
    fontSize: 14,
    zIndex: 2
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 12px'
  },
  suggestionHighlighted: {
    backgroundColor: '#eee'
  }
};

// Use your imagination to render suggestions.
const renderSuggestion_responsibles = suggestion_resp => (
  <div>
    {suggestion_resp.name}
  </div>
);

const renderSuggestion_projects = suggestion_project => (
  <div>
    {suggestion_project.name}
  </div>
);

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading    : true,
      alert      : null,
      redirect   : false,
      error      : false,
      p_changed  : false,
      status_anterior: '',
      
      title_text        : '',

      rowid             : '',

      code              : '',
      title             : '',
 			status 			      : '',
      desc	            : '',
      priority			    : '',
      firstPriority     : '',
      changeReason      : '',

      requestor			    : '',
      requestor_email   : '',
      request_date      : '',

      orig_ticket       : '',
      effort            : '',
      type              : '',
      classification    : '',
      resolution        : '',

      responsible       : '',
      responsible_name  : '',
      project           : '',
      project_name      : '',

      ro_flg            : false,

      path              : '',
      filename          : '',
      file              : [],

      statuses          : [],

      form : {
        orig_ticket     : { value : '', required : false},
        effort          : { value : '', required : false},
        type            : { value : '', required : false},
        priority        : { value : '', required : true},
        changeReason    : { value : '', required : false},
        responsible     : { value : '', required : false},
        classification  : { value : '', required : false},
        project         : { value : '', required : false},
        resolution      : { value : '', required : false},
        attachment      : { value : [], required : false}
      },

      formErrors : {
        orig_ticket     : false,
        effort          : false,
        type            : false,
        priority        : false,
        changeReason    : false,
        responsible     : false,
        classification  : false,
        project         : false,
        resolution      : false,
        attachment      : false,
      },

      buttons : [],

      formMsg : false,

      // Suggest

      suggestions_responsibles  : [],
      suggestions_projects      : [],

      responsibles  : [],
      projects      : [],
      value : ''
    };
  }

  componentDidMount() {
    this.preLoadContent()
  }

  preLoadContent = async () => {
    try {
      const response = await api.post("/tickets.php", { 
        method  : 'preLoadContent_edit', 
        mode    : 'adm',
        code    : this.props.match.params.handle 
      });

      if (response.data === 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        api.post("/tickets.php", { 
          method  : 'getStatuses', 
        }).then((response)=>{
          if (response.data === 'Validation Error') {
            this.setState({ error: "Usuário e/ou senha inválida." });
          }
          else{
            this.setState({
              statuses : response.data.status_list
            });
          }
        });

        this.setState({
          title_text        : this.text_truncate(response.data.subject, 90, '...'),

          rowid             : response.data.rowid,

          code              : response.data.code,
          title             : response.data.subject,
          status 			      : response.data.status,
          desc		          : response.data.description,
          priority			    : response.data.priority,
          firstPriority     : response.data.priority,

          requestor			    : response.data.requestor,
          requestor_email   : response.data.requestor_email,
          request_date	    : response.data.request_date,

          orig_ticket       : response.data.orig_ticket,
          effort            : response.data.effort,
          type              : response.data.type,
          responsible       : response.data.responsible,
          responsible_name  : response.data.responsible_name,
          classification    : response.data.classification,
          project           : response.data.project,
          project_name      : response.data.project_name,
          resolution        : response.data.resolution,

          ro_flg            : response.data.ro_flg,

          path              : response.data.link,
          filename          : response.data.filename,

          file : response.data.link !== "" ? [{
            source  : response.data.link,
            options : {
              type: 'local',
              file: {
                name: response.data.filename,
                size: response.data.filesize
              }
            }
          }] : [],

          form : {
            orig_ticket     : { value : response.data.orig_ticket,    required : false},
            effort          : { value : response.data.effort,         required : false},
            type            : { value : response.data.type,           required : false},
            priority        : { value : response.data.priority,       required : true},
            changeReason    : { value : '',                           required : false},
            responsible     : { value : response.data.responsible,    required : false},
            classification  : { value : response.data.classification, required : false},
            project         : { value : response.data.project,        required : false},
            resolution      : { value : response.data.resolution,     required : false}
          },

          loading : false,
          buttons : response.data.buttons
        });
      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }
  }

  text_truncate = function(str, length, ending) {
    if (length === null) {
      length = 100;
    }
    if (ending === null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }

  handleTypeValidation = e => {
    e.preventDefault();

    let self = this
    let formMsg = false

    Object.keys(this.state.form).map(function (index) {
      self.setState(prevState => ({
          formErrors: {
            ...prevState.formErrors,
            [index] : self.state.form[index].value === "" && self.state.form[index].required ? true : false
          },
      }),);

      formMsg = self.state.form[index].value === "" && self.state.form[index].required ? true : formMsg
    })

    this.setState({
      formMsg : formMsg
    })

    if (!formMsg)
      this.handleSubmit(e)
  }

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    
    
    this.setState({
      [name] : value,
      
    }) 

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name] : { 
          ...prevState.form[name],
          value : value 
        }
      },

      formErrors: {
        ...prevState.formErrors,
        [name] : value === "" ? true : false
      },
    }));

    let fmsg = false;
    let self = this;

    setTimeout( function() {
      if (document.getElementsByClassName('has-error').length >= 1) {
        fmsg = true;
      }
  
      self.setState({
        formMsg : fmsg
      })
 
    }, 300)

  }
  updateTkStatus = (cardId, sourceLaneId, targetLaneId) => {
  
    
    if (sourceLaneId !== targetLaneId) {

      if (targetLaneId === 'Backlog') {
       
        this.setState({
          loading: true,
          alert: (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-240px" }}
              showCancel
              confirmBtnText="Sim"
              cancelBtnText="Não"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              title={<span className="spanAlert">O ticket foi refinado?</span>}
              onConfirm={() => this.handleUpdateStatus_approved(cardId, targetLaneId, 'Y')}
              onCancel={() => this.stopAlert(cardId, sourceLaneId, 'N')}
            >
            </SweetAlert>
          )
        })
      } else if ((sourceLaneId === 'Pendente Cliente' || sourceLaneId === 'Em Andamento' || sourceLaneId === 'Aguardando implantação') && targetLaneId === 'Finalizado') {
        
        this.setState({
          loading: true,
          alert: (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-240px" }}
              showCancel
              confirmBtnText="Sim"
              cancelBtnText="Não"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              title={<span className="spanAlert">Replicado em produção?</span>}
              onConfirm={() => this.handleUpdateStatus_approved(cardId, targetLaneId, 'Y')}
              onCancel={() => this.stopAlert(cardId, sourceLaneId, 'N')}
            >
            </SweetAlert>
          )
        })

      } else {
        this.handleUpdateStatus(cardId, targetLaneId);
      }

    }
  }

  stopAlert(cardId, sourceLaneId, value) {
   
    if(value === 'N'){
      this.setState({
        status: sourceLaneId
      });
    }
    this.setState({
      alert: null,
      loading: false
    });
  }

  handleStatusAnterior = (id, atual, novo) =>{
    
    this.setState({
      status_anterior: atual
    })
  }

  handleChangeStatus = e => {
    e.preventDefault();
    const { name, value } = e.target;
    //this.onConfirmUpdateStatus(e)
    this.updateTkStatus(this.state.rowid, this.state.status, value)
    this.handleStatusAnterior(this.state.rowid, this.state.status, value)
    
    

    this.setState({
      [name]: value
    })

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: {
          ...prevState.form[name],
          value: value
        }
      },

      formErrors: {
        ...prevState.formErrors,
        [name]: value === "" ? true : false
      },
    }));

    let fmsg = false;
    let self = this;

    setTimeout(function () {
      if (document.getElementsByClassName('has-error').length >= 1) {
        fmsg = true;
      }

      self.setState({
        formMsg: fmsg
      })

    }, 300)
  }


  componentDidUpdate(prevProps,prevState){
    
    if((prevState.priority !== '') &&(prevState.priority!== this.state.priority)){
      var changed = false;

      if(this.state.priority !== this.state.firstPriority){
        changed = true;
      }

      this.setState({
        p_changed : changed,
      });

      this.setState(prevState => ({
        form: {
          ...prevState.form,
          ['changeReason'] : { 
            ...prevState.form['changeReason'],
            required : changed 
          }
        }
        
      }));
    }
  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      loading : true
    });

    const { attachment, orig_ticket, effort, type, priority ,responsible, classification, project, resolution } = this.state.form;

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    const formData = new FormData();

    formData.append('method',       'update');
    formData.append('rowid',        this.state.rowid);

    formData.append('subject',      this.state.title);
    formData.append('priority',     priority.value);
    formData.append('description',  this.state.desc);

    formData.append('original_name',  orig_ticket.value);
    formData.append('effort',         effort.value);
    formData.append('type',           type.value);
    formData.append('responsible',    responsible.value);
    formData.append('classification', classification.value);
    formData.append('project',        project.value);
    formData.append('resolution',     resolution.value);

    if (attachment !== undefined && attachment.value[0] !== undefined) {
        formData.append('attachment', attachment.value[0]);
    }
    
    if(this.state.p_changed){

      var priorities = {
        '4 - Normal - Não há perda de serviço. Erro mínimo que não pausa as atividades.'  : '4 - Normal',

        '3 - Alta - Problema que causa baixa perda de serviço. O impacto gera um incoveniente que pode ser contornado de outras formas, mas que ainda precisa de uma resolução.': '3 - Alta',

        '2 - Muito Alta - Problema que causa perda grande de serviço, porém a operação pode continuar com restrições.'   : '2 - Muito Alta',

        '1 - Crítico - Problema que causa interrupção total do trabalho, não sendo possível realizar nenhuma outra atividade.' : '1 - Crítico'
      }

      api.post('/tickets.php', {
        method  : 'insert_interaction',
        code    : this.state.code,
        message : 'Prioridade alterada para: ' + priorities[this.state.priority] + '.  Motivo: ' + this.state.changeReason 
      });

    }
 
    api.post('/tickets.php', formData, config).then((result) => {

      result.data.status === 'ok' ?

      this.setState({
        loading : false,
        alert   : (
          <SweetAlert 
          style={{ display: "block", marginTop: "-240px" }}
          success 
          title="Obrigado!"
          onConfirm={() => this.successAlert()}>Ticket atualizado com sucesso!</SweetAlert>
        )
      }) :

      this.setState({
        loading : false,
        alert   : (
          <SweetAlert 
          style={{ display: "block", marginTop: "-240px" }}
          error 
          title="Erro ao atualizar chamado" 
          onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
        )
      });

    });
  }

  onConfirmUpdateStatus = e => {
    e.preventDefault();

    const { id, value } = e.target;

    let msg   = ''

    let parado            = false
    let pendenteAprovacao = false

    switch (value) {
      case 'Aberto':
        msg = <span className="spanAlert">Confirmar reabertura do ticket?</span>
        break;
      case 'Melhoria Pendente':
        msg = <span className="spanAlert">Confirmar melhoria?</span>
        break;
      case 'Pendente Informação':
        msg = <span className="spanAlert">Confirmar pendente informação?</span>
        break;
      case 'Atribuído':
        msg = <span className="spanAlert">Confirmar atribuição do ticket?</span>
        break;
      case 'Cancelado':
        msg = <span className="spanAlert">Tem certeza que deseja cancelar este ticket?</span>
        break;
      case 'Em desenvolvimento':
        msg = <span className="spanAlert">Tem certeza que deseja iniciar o desenvolvimento?</span>
        break;
      case 'Parado':
        parado = true
        break;
      case 'Em Homologação':
        msg = <span className="spanAlert">Confirma que este ticket necessita de Homologação antes de ir ao ambiente de Produção?</span>
        break;
      case 'Pendente aprovação':
        pendenteAprovacao = true
        break;
      case 'Pendente fornecedor':
        msg = <span className="spanAlert">Confirmar pendente fornecedor?</span>
        break;
      default:
        msg = <span className="spanAlert">Confirmar ação?</span>
        break;
    }

    if (parado) {

      this.setState({
        alert: (
          <SweetAlert 
                input
                style={{ display: "block", marginTop: "-240px" }}
                showCancel
                content="input"
                cancelBtnText="Cancelar"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={<span className="spanAlert">Informe o motivo para a paralização de atendimento ao ticket:</span>}
                onConfirm={e => this.handleUpdateStatus_stopped(id, value, e)}
                onCancel={()  => this.hideAlert()}
                validationMsg="É obrigatório justificar a paralisação."
          ></SweetAlert>
        )
      });

    } else if (pendenteAprovacao) {

      this.setState({
        alert: (
          <SweetAlert 
                warning
                style={{ display: "block", marginTop: "-240px" }}
                showCancel
                confirmBtnText="Sim"
                cancelBtnText="Não"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={<span className="spanAlert">Houve replicação neste ticket?</span>}
                onConfirm={() => this.handleUpdateStatus_approved(id, value, 'Y')}
                onCancel={()  => this.handleUpdateStatus_approved(id, value, 'N')}
          ></SweetAlert>
        )
      });

    } else {
      this.setState({
        alert: (
          <SweetAlert 
                warning
                style={{ display: "block", marginTop: "-240px" }}
                showCancel
                confirmBtnText="Sim"
                cancelBtnText="Não"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={msg}
                onConfirm={() => this.handleUpdateStatus(id, value)}
                onCancel={() => this.hideAlert()}
          >Esta ação não poderá ser desfeita!</SweetAlert>
        )
      });
    }
  }

  handleUpdateStatus(id, value) {

    this.setState({
      loading : true,
      alert   : null
    });

    api.post("/tickets.php", { 
      method    : 'update_status', 
      rowid     : id,
      status    : value
    }).then((response) => {
      if (response.data === 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        response.data.status === 'ok' ?

        this.setState({
          loading   : false,
          status    : value,
          
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            success 
            title="Obrigado!"
            onConfirm={() => this.hideAlert()}>Status atualizado com sucesso!</SweetAlert>
          )
        }) :

        this.setState({
          loading : false,
          status: this.state.status_anterior,
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px", fontSize: "12px" }}
            warning 
            title=""
            onConfirm={() => this.hideAlert()}>{response.data.msg}</SweetAlert>
          )
        });

      }

    })
  }

  handleUpdateStatus_approved(id, value, replicated) {

    this.setState({
      loading : true,
      alert   : null
    });

    api.post("/tickets.php", { 
      method      : 'update_status', 
      rowid       : id,
      status      : value,
      replicated  : replicated
    }).then((response) => {
      if (response.data === 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        response.data.status === 'ok' ?

        this.setState({
          loading   : false,
          status    : value,

          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            success 
            title="Obrigado!"
            onConfirm={() => this.hideAlert()}>Status atualizado com sucesso!</SweetAlert>
          )
        }) :

        this.setState({
          loading : false,
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px", fontSize: "12px" }}
            warning 
            title=""
            onConfirm={() => this.hideAlert()}>{response.data.msg}</SweetAlert>
          )
        });
      }
    })
  }

  handleUpdateStatus_stopped(id, value, comments) {
    this.setState({
      loading : true,
      alert   : null
    });

    api.post("/tickets.php", { 
      method    : 'update_status', 
      rowid     : id,
      status    : value,
      comments  : comments
    }).then((response) => {
      if (response.data === 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        response.data.status === 'ok' ?

        this.setState({
          loading   : false,
          status    : value,

          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            success 
            title="Obrigado!"
            onConfirm={() => this.hideAlert()}>Status atualizado com sucesso!</SweetAlert>
          )
        }) :

        this.setState({
          loading : false,
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px", fontSize: "12px" }}
            warning 
            title=""
            onConfirm={() => this.hideAlert()}>{response.data.msg}</SweetAlert>
          )
        });
      }
    })
  }

  successAlert() {
    this.setState({
      alert : null
      //redirect : true
    });
  }

  hideAlert() {
    this.setState({
      alert : null
    });
  }

  // Suggest

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions_responsibles(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    api.post("/pickapplet.php", { 
      method  : 'responsibles', 
      value   : value 
    }).then((response) => {

      const responsibles = response.data

      this.setState({
        suggestions_responsibles: inputLength < 3 ? [] : responsibles.filter(responsible =>
          (responsible['name'].toLowerCase().indexOf(inputValue) > -1)
        )
      });
    })
  };

  getSuggestions_projects(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    api.post("/pickapplet.php", { 
      method  : 'projects', 
      code    : this.props.match.params.handle 
    }).then((response) => {

      const projects = response.data

      this.setState({
        suggestions_projects: inputLength === 0 ? [] : projects.filter(project =>
          (project['name'].toLowerCase().indexOf(inputValue) > -1)
        )
      });
    })
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue_responsibles(suggestion) {
    return suggestion.name;
  }

  getSuggestionValue_projects(suggestion) {
    return suggestion.name;
  }

  onSuggestionSelected_responsibles = (event, { suggestion }) => {
    this.setState(prevState => ({
      responsible       : suggestion.rowid,
      responsible_name  : suggestion.name,
      form: {
        ...prevState.form,
        responsible : { 
          ...prevState.form.responsible,
          value : suggestion.rowid 
        }
      }
    }));
  };

  onSuggestionSelected_projects = (event, { suggestion }) => {
    this.setState(prevState => ({
      project       : suggestion.rowid,
      project_name  : suggestion.name,
      form: {
        ...prevState.form,
        project : { 
          ...prevState.form.project,
          value : suggestion.rowid 
        }
      }
    }));
  };

  onChangeSuggest_responsibles = (event, { newValue }) => {
    this.setState({
      responsible       : '',
      responsible_name  : newValue
    });
  };

  onChangeSuggest_projects = (event, { newValue }) => {
    this.setState({
      project       : '',
      project_name  : newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested_responsibles = ({ value }) => {
    this.getSuggestions_responsibles(value)
  };

  onSuggestionsFetchRequested_projects = ({ value }) => {
    this.getSuggestions_projects(value)
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested_responsibles = () => {
    this.setState({
      suggestions_responsibles: []
    });
  };

  onSuggestionsClearRequested_projects = () => {
    this.setState({
      suggestions_projects: []
    });
  };

  onSuggestionsBlur_responsibles = () => {
    if (this.state.responsible === '') {
      this.setState({
        responsible_name: ''
      });
    }
  };

  onSuggestionsBlur_projects = () => {
    if (this.state.project === '') {
      this.setState({
        project_name: ''
      });
    }
  };

  toggleModal (e){
    this.setState({
      alert : (
        <SweetAlert style={{ display: "block", marginTop: "-240px", width : matrix_img.offsetWidth }} onConfirm={() => this.hideAlert()}>
          <img src= {matrix_img}  width={matrix_img.offsetWidth} height={matrix_img.offsetHeight}/>
        </SweetAlert> 
      )
    })
    

          
  }

  render() {
    
	  let ticket = {
      'code'      : this.state.code,
      'title'     : this.state.title_text,
      'status'    : this.state.status,
      'severity'  : this.state.priority
    }  
    
    const { responsible_name, project_name, suggestions_responsibles, suggestions_projects, request_date } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps_responsibles = {
      className   : 'form-control',
      value       : responsible_name,
      onChange    : this.onChangeSuggest_responsibles,
      onBlur      : this.onSuggestionsBlur_responsibles
    };

    const inputProps_projects = {
      className   : 'form-control',
      value       : project_name,
      onChange    : this.onChangeSuggest_projects,
      onBlur      : this.onSuggestionsBlur_projects
    };

    const status_lst = this.state.statuses

    return (
      this.state.redirect ? 
      <Redirect to="/admtickets" /> :
      
      <div className="main-content">
        {this.state.alert}
        <HeaderForm ticket={ticket} status_lst = {status_lst}></HeaderForm>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <div className={this.state.loading ? "loading-form" : "fadeOut loading-form" }>
                      <img src={loading} alt="Carregando.." />
                    </div>
                    <form className={this.state.loading ? "opacity form" : "fadeIn form" }>
                      <TabForm 
                        handle={this.state.code} 
                        active="home" 
                        showButtons={true} 
                        buttons={this.state.buttons[this.state.status] !== undefined ? this.state.buttons[this.state.status] : []}
                        rowid={this.state.rowid}
                        buttonclick={this.onConfirmUpdateStatus}
                        admin={true}
                      >
                      </TabForm>
                      {this.state.loading ? '' :
                      <div>
                        <div className="row">
                            <Col sm={2}>
                                <FormGroup>
                                    <ControlLabel>Data de solicitação</ControlLabel>
                                    <FormControl type="text" disabled placeholder=""  value={request_date.replace(/(\d*)-(\d*)-(\d*).*/, '$3/$2/$1')} />
                                </FormGroup>
                            </Col>
                            <Col sm={3}>
                                <FormGroup>
                                    <ControlLabel>Solicitante</ControlLabel>
                                    <FormControl type="text" disabled placeholder="" value={this.state.requestor}   />
                                </FormGroup>
                            </Col>
                            <Col sm={4}>
                                <FormGroup>
                                    <ControlLabel>E-mail</ControlLabel>
                                    <FormControl type="text" disabled placeholder="" value={this.state.requestor_email} />
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup>
                                    <ControlLabel>Ticket Original</ControlLabel>
                                    <FormControl 
                                      name="orig_ticket" 
                                      type="text" 
                                      placeholder="" 
                                      ref="orig_ticket" 
                                      value={this.state.orig_ticket} 
                                      onChange={this.handleChange} 
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={1}>
                              <FormGroup>
                                  <ControlLabel>Esforço</ControlLabel>
                                  <FormControl 
                                    name="effort" 
                                    type="number" 
                                    placeholder="" 
                                    ref="effort" 
                                    value={this.state.effort} 
                                    onChange={this.handleChange}
                                  />
                              </FormGroup>
                            </Col>
                        </div>

                        <div className="row">
                          <Col sm={2}>
                            <FormGroup>
                              <ControlLabel>Tipo</ControlLabel>
                              <ListOfVal 
                                name="type" 
                                reference="type" 
                                type="ADJUSTMENT_TYPE" 
                                onChange={this.handleChange} 
                                value={this.state.type} 
                              />
                            </FormGroup>
                          </Col>

                          <Col sm={3}>
                            <FormGroup>
                              <ControlLabel>Responsável</ControlLabel>
                              <Autosuggest
                                suggestions={suggestions_responsibles}
                                onSuggestionSelected={this.onSuggestionSelected_responsibles}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested_responsibles}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested_responsibles}
                                getSuggestionValue={this.getSuggestionValue_responsibles}
                                renderSuggestion={renderSuggestion_responsibles}
                                inputProps={inputProps_responsibles}
                                theme={theme}
                              />
                              {/*<FormControl 
                                name="responsible" 
                                type="text" 
                                placeholder="" 
                                ref="responsible" 
                                value={this.state.responsible} 
                                onChange={this.handleChange}
                              />*/}
                            </FormGroup>
                          </Col>

                          <Col sm={3}>
                            <FormGroup>
                                <ControlLabel>Classificação</ControlLabel>
                                <ListOfVal 
                                  name="classification" 
                                  reference="classification" 
                                  type="ADM_CLASS" 
                                  onChange={this.handleChange} 
                                  value={this.state.classification} 
                                />
                            </FormGroup>
                          </Col>

                          <Col sm={2}>
                              <FormGroup>
                                  <ControlLabel>Projeto</ControlLabel>
                                  <Autosuggest
                                    suggestions={suggestions_projects}
                                    onSuggestionSelected={this.onSuggestionSelected_projects}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested_projects}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested_projects}
                                    getSuggestionValue={this.getSuggestionValue_projects}
                                    renderSuggestion={renderSuggestion_projects}
                                    inputProps={inputProps_projects}
                                    theme={theme}
                                  />
                                  {/*
                                  <FormControl 
                                    name="project" 
                                    type="text" 
                                    placeholder="" 
                                    ref="project" 
                                    value={this.state.project} 
                                    onChange={this.handleChange}
                                  />
                                  */}
                              </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup>
                                  <ControlLabel>Status</ControlLabel>
                                  <FormControl
                                    componentClass="select"
                                    name="status"
                                    reference="status"
                                    onChange={this.handleChangeStatus}
                                    value={this.state.status}>
                                    {status_lst.map((list, index) => (
                                      <option
                                        key={index}
                                        value={list}>{list}
                                      </option>
                                    ))}
                                  </FormControl>

                                </FormGroup>
                              </Col>
                        </div>

                        <div className="row">
                                                  
                        {this.state.p_changed ?
                          <Col sm={5}>
                            <FormGroup className={this.state.formErrors.priority ? "has-error" : ""}>
                                <ControlLabel>Severidade</ControlLabel>
                                <ListOfVal 
                                  name="priority" 
                                  reference="priority" 
                                  type="ADJUST_PRIORITY" 
                                  onChange={this.handleChange} 
                                  value={this.state.priority} 
                                />
                            </FormGroup>
                          </Col>
                          : 
                          <Col sm={11}>
                            <FormGroup className={this.state.formErrors.priority ? "has-error" : ""}>
                                <ControlLabel>Severidade</ControlLabel>
                                <ListOfVal 
                                  name="priority" 
                                  reference="priority" 
                                  type="ADJUST_PRIORITY" 
                                  onChange={this.handleChange} 
                                  value={this.state.priority} 
                                />
                            </FormGroup>
                            
                          </Col> 
                          }

                          {!this.state.p_changed ? 
                            <i className = {"glyphicon glyphicon-question-sign"} style = {{marginTop : '45px'}} onClick= {e => this.toggleModal(e)}/>
                            : ''
                          }

                          {this.state.p_changed ?
                          <Col sm={7}>
                          <FormGroup className={this.state.formErrors.changeReason ? "has-error" : ""}>
                            <ControlLabel>Razão da mudança de Prioridade</ControlLabel>
                            <FormControl
                              name="changeReason" 
                              type="text" 
                              ref="changeReason"
                              value={this.state.changeReason} 
                              onChange={this.handleChange} 
                            />
                          </FormGroup>
                          </Col> : ''
                        }
                        </div>

                        <div className="row">
                          <Col sm={12}>
                            <FormGroup>
                                <ControlLabel>Descrição</ControlLabel>
                                <FormControl 
                                  name="desc"
                                  componentClass="textarea" 
                                  rows="4" 
                                  cols="80" 
                                  placeholder="Descreva aqui os detalhes do chamado" ref="desc" 
                                  value={this.state.desc} 
                                  readOnly={true}
                                />
                            </FormGroup>
                          </Col>
                          </div>

                        <div className="row">
                          <Col sm={12}>
                                <FormGroup>
                                    <ControlLabel>Resolução</ControlLabel>
                                    <FormControl 
                                      name="resolution"
                                      componentClass="textarea" 
                                      rows="4" 
                                      cols="80" 
                                      placeholder="Problema, Causa e Solução." 
                                      onChange={this.handleChange}
                                      ref="resolution" 
                                      value={this.state.resolution} 
                                    />
                                </FormGroup>
                          </Col>
                        </div>

                          <div className="row">
                            <Col sm={12}>
                                <FormGroup>
                                    <ControlLabel>Anexo</ControlLabel>
                                    {this.state.path !== '' ?
                                      <div className={this.state.loading ? "fadeOut link-anexo" : "fadeIn link-anexo" }>
                                        <a href={this.state.path + '&token='+getToken()} download={this.state.filename}>
                                          <span className="download-icon" title="Baixar arquivo"></span>
                                        </a>
                                      </div>
                                    : ''}

                                    <FilePond 
                                      labelIdle="Arraste e solte ou <span class='filepond--label-action'>procure</span> um arquivo"
                                      labelInvalidField="Arquivo inválido"
                                      labelFileWaitingForSize="Aguardando.."
                                      labelFileSizeNotAvailable="Tamanho não permitido"
                                      labelFileLoading=""
                                      labelFileLoadError="Erro durante o carregamento"
                                      labelFileProcessing="Carregando.."
                                      labelFileProcessingComplete="Upload completo"
                                      labelFileProcessingAborted="Upload cancelado"
                                      labelFileProcessingError="Erro durante o upload"
                                      labelFileProcessingRevertError="Erro ao reverter"
                                      labelFileRemoveError="Erro ao remover"
                                      labelTapToCancel="Cancelar"
                                      labelTapToRetry="Tentar novamente"
                                      labelTapToUndo="Desfazer"
                                      labelButtonRemoveItem="Remover"
                                      labelButtonAbortItemLoad="Abortar"
                                      labelButtonRetryItemLoad="Tentar novamente"
                                      labelButtonAbortItemProcessing="Cancelar"
                                      labelButtonUndoItemProcessing="Desfazer"
                                      labelButtonRetryItemProcessing="Tentar novamente"
                                      labelButtonProcessItem="Upload"
                                      files={this.state.file}
                                      instantUpload={false}
                                      ref={ref => this.pond = ref}
                                      allowMultiple={false}
                                      labelButtonDownloadItem="Baixar arquivo"
                                      onupdatefiles={(fileItems) => {
                                        this.setState(prevState => ({
                                          file : fileItems.map(fileItem => fileItem.file),
                                          form: {
                                            ...prevState.form,
                                            attachment : {
                                              ...prevState.form.attachment,
                                              value : fileItems.map(fileItem => fileItem.file)
                                            }
                                          }
                                        }));
                                      }}
                                      
                                      allowFileTypeValidation={true}
                                      acceptedFileTypes={["image/*", "video/*", "application/pdf", "application/zip","application/vnd.rar","application/x-rar-compressed","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/x-zip-compressed","application/x-excel","application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document"]}
                                      fileValidateTypeLabelExpectedTypes="Tipos de arquivos suportados são: imagem, pdf, video, zip e docx/doc "
                                      labelFileTypeNotAllowed="Tipo de arquivo não suportado "
                                      onaddfile={(error, source, test) => {
                                          const attachmentValue = !error ? [source.file] : []   
                                          this.setState(prevState => ({
                                            file: [source.file],
                                            form: {
                                              ...prevState.form,
                                              attachment : {
                                                ...prevState.form.attachment,
                                                value : attachmentValue
                                              }
                                            },
                                          }));
                                        }
                                      }
                                    >
                                  </FilePond>
                                </FormGroup>
                            </Col>
                          </div>
                        </div>}
                        {this.state.loading ? '' : <FooterForm /*url="admtickets"*/ url={sessionStorage.getItem('previous-page')} disabled={false} click={this.handleTypeValidation}></FooterForm>}
                    </form>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default View;
