import React, { Component } from "react";

import Card from "components/Card/Card.jsx";

import { Grid, Row, Col } from "react-bootstrap";

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import TabForm from "../../../components/SharedForm/TabForm";

import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

registerPlugin(FilePondPluginImagePreview);

class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      files: [
        {
          source: 'documento_replicação.docx',
          options: {
              type: 'local'
          }
        },
        {
          source: 'script_testes.xls',
          options: {
              type: 'local'
          }
        },
        {
          source: 'evidencia.png',
          options: {
              type: 'local'
          }
        },
        {
          source: 'cenario.docx',
          options: {
              type: 'local'
          }
        }
      ]
    };
  }

  handleInit() {
    console.log('FilePond instance has initialised', this.pond);
  }

  render() {
    let handle = this.props.match.params.handle;

    let ticket = {
      'code'      : this.props.match.params.handle,
      'title'     : 'Erro ao exportar dados',
      'status'    : 'Reprovado',
      'severity'  : '1'
    }

    return (
      <div className="main-content">
        <HeaderForm ticket={ticket}></HeaderForm>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <form>
                    <TabForm handle={handle} active="files"></TabForm>
                    <div className="dvUpload">
                      <div className="boxUpload">
                        <FilePond 
                          labelIdle="Arraste e solte ou <span class='filepond--label-action'>procure</span> seus arquivos"
                          labelInvalidField="Arquivo inválido"
                          labelFileWaitingForSize="Aguardando.."
                          labelFileSizeNotAvailable="Tamanho não permitido"
                          labelFileLoading="Carregando.."
                          labelFileLoadError="Erro durante o carregamento"
                          labelFileProcessing="Carregando.."
                          labelFileProcessingComplete="Upload completo"
                          labelFileProcessingAborted="Upload cancelado"
                          labelFileProcessingError="Erro durante o upload"
                          labelFileProcessingRevertError="Erro ao reverter"
                          labelFileRemoveError="Erro ao remover"
                          labelTapToCancel="Cancelar"
                          labelTapToRetry="Tentar novamente"
                          labelTapToUndo="Desfazer"
                          labelButtonRemoveItem="Remover"
                          labelButtonAbortItemLoad="Abortar"
                          labelButtonRetryItemLoad="Tentar novamente"
                          labelButtonAbortItemProcessing="Cancelar"
                          labelButtonUndoItemProcessing="Desfazer"
                          labelButtonRetryItemProcessing="Tentar novamente"
                          labelButtonProcessItem="Upload"
                          ref={ref => this.pond = ref}
                          files={this.state.files}
                          allowMultiple={true}
                          /*maxFiles={3}*/
                          server="/api"
                          oninit={() => this.handleInit() }
                          onupdatefiles={(fileItems) => {
                              // Set current file objects to this.state
                              this.setState({
                                  files: fileItems.map(fileItem => fileItem.file)
                              });
                          }}>
                        </FilePond>
                      </div>
                    </div>
                    <FooterForm url="admtickets"></FooterForm>
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Files;