import React, { Component } from "react";
import { getToken } from "../../../services/auth";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { Grid, Row, Col, ControlLabel, FormGroup, FormControl } from "react-bootstrap";

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import TabForm from "../../../components/SharedForm/TabForm";

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond/dist/filepond.min.css';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

import { Redirect } from "react-router-dom";

import api      from "services/api";
import loading  from "assets/img/loading.gif";

import SweetAlert from "react-bootstrap-sweetalert";

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImagePreview);

class Files extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adm       : (window.location.href.indexOf("adm") > -1 ? true : false),
      url       : (window.location.href.indexOf("adm") > -1 ? 'admtickets' : 'tickets'),

      loading   : true,
      redirect  : false,
      error     : false,

      rowid           : '',
      code            : '',
      title_text      : '',
 			status 			    : '',
      priority			  : '',

      name        : '',
      comments    : '',
      statuses    : [],

      form : {
        name        : { value : '', required : false},
        comments    : { value : '', required : false},
        attachment  : { value : [], required : true}
      },

      formErrors : {
        attachment : false
      },

      formMsg : false,

      // Set initial files, type 'local' means this is a file
      // that has already been uploaded to the server (see docs)
      files: [],

      alert       : null,
      form_view   : false
    };
  }

  
  componentDidMount() {
    this.preLoadContent()
  }

  preLoadContent = async () => {
    try {
      const response = await api.post("/tickets.php", { method : 'files', code : this.props.match.params.handle });

      if (response.data == 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        api.post("/tickets.php", { 
          method  : 'getStatuses', 
        }).then((response)=>{
          if (response.data === 'Validation Error') {
            this.setState({ error: "Usuário e/ou senha inválida." });
          }
          else{
            this.setState({
              statuses : response.data.status_list
            });
          }
        });

        this.setState({

          rowid           : response.data.rowid,

          code            : response.data.code,
          title_text      : this.text_truncate(response.data.subject, 90, '...'),
          status 			    : response.data.status,
          priority			  : response.data.priority,

          files : response.data.files.map((item) => {
            return {
              path    : item.link+'&token='+getToken(),
              source  : item.link,
              options : {
                type: 'local',
                file: {
                  id  : item.id,
                  name: item.last_updated_by + ' - ' + item.name,
                  size: item.filesize
                }
              }
            }
          }),

          loading   : false,
          form_view : false
        });
      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }
  }

  text_truncate = function(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  handleInit() {
    console.log('FilePond instance has initialised', this.pond);
  }

  handleTypeValidation = e => {
    e.preventDefault();

    const { attachment } = this.state.form;

    if (attachment.value[0] !== undefined) {
      this.handleSubmit(e)
    } else {
      this.setState({
        formErrors : {
          attachment : true
        },

        formMsg : true

      })
    }

  };

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name] : { 
          ...prevState.form[name],
          value : value 
        }
      }
    }));
  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      loading : true
    });

    const { name, comments, attachment } = this.state.form;

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    const formData = new FormData();

    formData.append('method',       'insert_file');
    formData.append('category_id',  this.state.rowid);

    if (name.value !== undefined)
      formData.append('name', name.value);

    if (comments.value !== undefined)
      formData.append('comments', comments.value);

    formData.append('attachment', attachment.value[0]);

    api.post('/tickets.php', formData, config).then((result) => {

      result.data.status === 'ok' ?

      this.preLoadContent() :

      this.setState({
        loading   : false,
        alert     : (
          <SweetAlert 
          style={{ display: "block", marginTop: "-240px" }}
          error 
          title="Erro ao anexar arquivo" 
          onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
        )
      });

    });
  }

  onConfirmRemoveItem(id) {

    this.setState({
      alert: (
        <SweetAlert 
              warning
              style={{ display: "block", marginTop: "-240px" }}
              showCancel
              confirmBtnText="Sim"
              cancelBtnText="Não"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={<span className="spanAlert">Tem certeza que deseja remover este anexo?</span>}
              onConfirm={() => this.onRemoveItem(id)}
              onCancel={() => this.hideAlert()}
        >Esta ação não poderá ser desfeita!</SweetAlert>
      )
    });
  }

  onRemoveItem(id) {
    api.post('/tickets.php', {
      method  : 'remove_file',
      rowid   : id
    }).then((result) => {

      if (result.data.status === 'ok') {

        this.setState(prevState => ({
          files : prevState.files.filter(el => el.options.file.id != id ),
          alert : null
        }));

      } else {
        this.setState({
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            error 
            title="Erro ao remover anexo" 
            onConfirm={() => this.hideAlert(id)}>Por favor, tente novamente.</SweetAlert>
          )
        });
      }

    });
  };

  onClickForm = e => {
    e.preventDefault();
    let self = this;

    this.setState({
      form_view: self.state.form_view === true ? false : true,

      form : {
        name        : { value : '', required : false},
        comments    : { value : '', required : false},
        attachment  : { value : [], required : true}
      },

      formErrors : {
        attachment : false
      },

      formMsg : false

    });
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  render() {
    let handle = this.props.match.params.handle;

    let ticket = {
      'code'      : this.state.code,
      'title'     : this.state.title_text,
      'status'    : this.state.status,
      'severity'  : this.state.priority
    }
    const status_lst = this.state.statuses;

    return (
      this.state.redirect ? 
      <Redirect to="/tickets" /> :
      <div className="main-content">
        {this.state.alert}
        <HeaderForm ticket={ticket} status_lst = {status_lst}></HeaderForm>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <div className={this.state.loading ? "loading-form" : "fadeOut loading-form" }>
                      <img src={loading} alt="Carregando.." />
                    </div>
                    <form className={this.state.loading ? "opacity form" : "fadeIn form" }>
                      <TabForm handle={handle} active="files" admin={this.state.adm}></TabForm>

                      {this.state.loading ? '' : (
                        this.state.form_view ? (
                          <div>
                            <div className="form-upload-files">
                              <div className={this.state.formMsg ? "error-msg fadeIn" : "hide" }>O campo anexo é de preenchimento obrigatório</div>
                              <div className="row">
                                  <Col sm={12}>
                                    <FormGroup>
                                      <ControlLabel>Nome</ControlLabel>
                                      <FormControl
                                        autoFocus
                                        name="name" 
                                        type="text" 
                                        placeholder="Digite o nome do arquivo" 
                                        ref="name"
                                        defaultValue={this.state.name} 
                                        onChange={this.handleChange} 
                                      />
                                    </FormGroup>
                                  </Col>
                              </div>

                              <div className="row">
                                  <Col sm={12}>
                                    <FormGroup>
                                      <ControlLabel>Comentários</ControlLabel>
                                      <FormControl
                                        name="comments" 
                                        type="text" 
                                        placeholder="Digite os comentários do arquivo" 
                                        ref="comments"
                                        defaultValue={this.state.comments} 
                                        onChange={this.handleChange} 
                                      />
                                    </FormGroup>
                                  </Col>
                              </div>

                              <div className="row">
                                <Col sm={12}>
                                  <FormGroup className={this.state.formErrors.attachment ? "has-error" : ""}>
                                    <ControlLabel>Anexo</ControlLabel>
                                    <FilePond 
                                      labelIdle="Arraste e solte ou <span class='filepond--label-action'>procure</span> um arquivo"
                                      labelInvalidField="Arquivo inválido"
                                      labelFileWaitingForSize="Aguardando.."
                                      labelFileSizeNotAvailable="Tamanho não permitido"
                                      labelFileLoading="Carregando.."
                                      labelFileLoadError="Erro durante o carregamento"
                                      labelFileProcessing="Carregando.."
                                      labelFileProcessingComplete="Upload completo"
                                      labelFileProcessingAborted="Upload cancelado"
                                      labelFileProcessingError="Erro durante o upload"
                                      labelFileProcessingRevertError="Erro ao reverter"
                                      labelFileRemoveError="Erro ao remover"
                                      labelTapToCancel="Cancelar"
                                      labelTapToRetry="Tentar novamente"
                                      labelTapToUndo="Desfazer"
                                      labelButtonRemoveItem="Remover"
                                      labelButtonAbortItemLoad="Abortar"
                                      labelButtonRetryItemLoad="Tentar novamente"
                                      labelButtonAbortItemProcessing="Cancelar"
                                      labelButtonUndoItemProcessing="Desfazer"
                                      labelButtonRetryItemProcessing="Tentar novamente"
                                      labelButtonProcessItem="Upload"
                                      instantUpload={false}
                                      ref={ref => this.pond = ref}
                                      allowMultiple={false}
                                      // onupdatefiles={(fileItems) => {
                                      //   this.setState(prevState => ({
                                      //     form: {
                                      //       ...prevState.form,
                                      //       attachment : {
                                      //         ...prevState.form.attachment,
                                      //         value : fileItems.map(fileItem => fileItem.file)
                                      //       }
                                      //     },
                                      //     formMsg : false
                                      //   }));
                                      // }}
                                      allowFileTypeValidation={true}
                                      acceptedFileTypes={["image/*", "video/*", "application/pdf", "application/zip","application/vnd.rar","application/x-rar-compressed","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/x-zip-compressed","application/x-excel"]}
                                      fileValidateTypeLabelExpectedTypes="Tipos de arquivos suportados são: imagem, pdf, video, zip e rar "
                                      labelFileTypeNotAllowed="Tipo de arquivo não suportado "
                                      onaddfile={(error, source, test) => {
                                          const attachmentValue = !error ? [source.file] : []   
                                          this.setState(prevState => ({
                                            form: {
                                              ...prevState.form,
                                              attachment : {
                                                ...prevState.form.attachment,
                                                value : attachmentValue
                                              }
                                            },
                                            formMsg : false,
                                          }));
                                        }
                                      }
                                      >
                                    </FilePond>
                                  </FormGroup>
                                </Col>
                              </div>
                              <button className="btnResetPassword" type="submit" bsStyle="info" fill onClick={this.handleTypeValidation}>Fazer upload</button>
                              <button className="btnResetPassword" onClick={this.onClickForm} bsStyle="info" fill>Arquivos carregados</button>
                            </div>
                          </div>
                        ) : (
                        <div>
                          <div className="dvUpload">
                            <div className="headerListFiles">
                              <span class="labelIdle">
                                {this.state.files.length > 0 ? 'Arquivos carregados' : 'Nenhum arquivo carregado'}
                              </span>
                              <span class="labelIdle-button">
                                <button style={{fontSize:14}} onClick={this.onClickForm} bsStyle="info" simple type="button" bsSize="xs" className="btnResetPassword">Novo arquivo</button>
                              </span>
                            </div>

                            <div className="boxUpload">
                              <div className="box-links">
                                {
                                  this.state.files.map((item,i) => {
                                    return (
                                      item.path !== '' ?
                                        <div key={i} className={this.state.loading ? "fadeOut links-anexo" : "fadeIn links-anexo" }>
                                          <a href={item.path} download={item.filename} >
                                            <span className="download-icon" title="Baixar arquivo"></span>
                                          </a>
                                        </div>
                                      : ''
                                    )
                                  })
                                }
                              </div>

                              <FilePond 
                                labelIdle=""
                                labelInvalidField="Arquivo inválido"
                                labelFileWaitingForSize="Aguardando.."
                                labelFileSizeNotAvailable="Tamanho não permitido"
                                labelFileLoading="Carregando.."
                                labelFileLoadError="Erro durante o carregamento"
                                labelFileProcessing="Carregando.."
                                labelFileProcessingComplete="Upload completo"
                                labelFileProcessingAborted="Upload cancelado"
                                labelFileProcessingError="Erro durante o upload"
                                labelFileProcessingRevertError="Erro ao reverter"
                                labelFileRemoveError="Erro ao remover"
                                labelTapToCancel="Cancelar"
                                labelTapToRetry="Tentar novamente"
                                labelTapToUndo="Desfazer"
                                labelButtonRemoveItem="Remover"
                                labelButtonAbortItemLoad="Abortar"
                                labelButtonRetryItemLoad="Tentar novamente"
                                labelButtonAbortItemProcessing="Cancelar"
                                labelButtonUndoItemProcessing="Desfazer"
                                labelButtonRetryItemProcessing="Tentar novamente"
                                labelButtonProcessItem="Upload"
                                ref={ref => this.pond = ref}
                                files={this.state.files}
                                instantUpload={false}
                                itemInsertLocation='after'
                                allowBrowse={false}	
                                allowMultiple={true}
                                labelButtonDownloadItem="Baixar arquivo"
                                beforeRemoveFile={(item) => {
                                  this.onConfirmRemoveItem(item.file.id)
                                  return false;
                                }}
                              >
                              </FilePond>
                            </div>
                          </div>
                          <FooterForm url={this.state.url}></FooterForm>
                        </div>))}
                    </form>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Files;