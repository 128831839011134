import React, { Component } from "react";

import { Grid, Form } from "react-bootstrap";
import { login, logout } from "services/auth";

import Card from "components/Card/Card.jsx";
/* import logoLogin  from "assets/img/accLogoWhite.png"; */
import logoLogin from "assets/img/logoAccenture.svg";
import api from "services/api";
import SweetAlert from "react-bootstrap-sweetalert";
import "assets/login/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "assets/login/fonts/iconic/css/material-design-iconic-font.min.css";
import "assets/login/vendor/animate/animate.css";
import "assets/login/vendor/css-hamburgers/hamburgers.min.css";
import "assets/login/vendor/animsition/css/animsition.min.css";
import "assets/login/vendor/select2/select2.min.css";
import "assets/login/vendor/daterangepicker/daterangepicker.css";
import "assets/login/css/util.css";
import "assets/login/css/main.css";
import "assets/mfa/css/main.css";

import loading_icon from "assets/img/loading.gif";

class AuthenticatorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      loading: false,
      alertSecretCode: false,
      alertResetCode: false,
      inputCode: ""
    };
    this.data = {
      mfa: props.location.state.mfaSecretData,
      otpString: props.location.state.otpString,
      userName: props.location.state.username
    };
  }


  HandleInputChange = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({inputCode: e.target.value})
    }
  }

  HandleValidate = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      const otpString = this.data.otpString
      const username = this.data.userName
      const inputCode = this.state.inputCode
      const response = await api.post("/mfa_validate.php", { username, inputCode, otpString });
      const remember = false
      if (response.data == 'Validation Error') {
        this.setState({ error: "Código inválido.", loading: false });
      } else if (response.data == 'User Blocked') {
        this.setState({ error: "Usuário bloqueado. Por favor, entre em contato com a administração.", loading : false });
      } else if (response.data.message == 'Successful login.') {
        this.setState({ alertResetCode: true })
        login(
          response.data.jwt,
          remember,
          username,
          response.data.posid,
          response.data.name,
          response.data.company,
          response.data.gender,
          response.data.resp
        )
        this.setState({ loading: false });
      } else if (response.data == 'Timeout Error') {
        logout()
      }
      else {
        this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
      }

    } catch (error) {
      console.log(error)
    }

  }

  getLogo() {
    return (
      <div>{/* <img src={logoLogin} width={100} height={100} alt="Login" /> */}<div className="title-portal">Accenture <span className="songTitle">Song</span></div></div>
    )
  }
  getQrCode() {
    const src = `data:image/png;base64,${this.data.mfa.qrCode}`
    return (
      <img src={src}></img>
    )
  }
  componentDidMount() {
    sessionStorage.clear()

    if (localStorage.getItem('remember')) {
      this.setState({ username: localStorage.getItem('remember'), remember: true });
    }

    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  
  render() {
    return (
      <Grid>
        <Card
          textCenter
          title={this.getLogo()}
          customClass="wrap-login100 less-vertical-padding"
          content={
            <div>
              <Form onSubmit={this.HandleValidate} className="login100-form validate-form">
                <div className="lbl-form-login text-justify">
                  <label className="lbl-form-login">Autenticação de duas etapas necessária. Para realizar o cadastro, abra um app de autenticador, como o Google Authenticator ou Microsoft Authenticator e escaneie o código abaixo.</label>
                </div>

                <div class="qrCode-container">
                  {this.getQrCode()}
                </div>

                <div className="lbl-form-login text-center">
                  <label className="lbl-form-login">Problemas com o seu QR CODE?&nbsp;<span class="lbl-form-login-btn-link" onClick={() => this.setState({ alertSecretCode: true })}> Clique aqui</span>
                  </label>
                </div>

                <div className="lbl-form-login text-center">
                  <label className="lbl-form-login">Insira abaixo o código exibido no seu aplicativo.</label>
                </div>

                <div className="mfa-code-container">
                  <input onChange={this.HandleInputChange} value={this.state.inputCode} className="mfa-code-container2" type="text" maxLength="6" size="6" min="0" max="9" pattern="[0-9]{6}" />
                  <span className="focus-mfa-input100"></span>
                </div>

                <div className="login-error">{this.state.error}</div>

                <div className="container-login100-form-btn">
                  <button type="submit" className="btnLogin m-t-10">{this.state.loading ? <img src={loading_icon} className="loading-login" /> : ''} Login</button>
                </div>
                {this.state.alertSecretCode && <SweetAlert
                  title='Aviso'
                  warning
                  style={{ display: "block", marginTop: "-240px" }}
                  onConfirm={() => { this.setState({ alertSecretCode: false }) }}
                >Para realizar o cadastro manual, abra o aplicativo de autenticação, e crie um novo cadastro utilizando a chave a seguir: <span style={{color: "#000", fontWeight: "bold" }}>{this.data.mfa.secret}</span> </SweetAlert>}
                {this.state.alertResetCode && <SweetAlert
                  title='Aviso'
                  warning
                  style={{ display: "block", marginTop: "-240px" }}
                  onConfirm={() => {this.props.history.push("/dashboard") }}
                >Atenção! Salve esta chave de recuperação a seguir. Ela não será exibida novamente, e será necessária para recuperar sua conta caso perca acesso ao aplicativo de autenticação. <span style={{color: "#000", fontWeight: "bold"}}>{this.data.mfa.resetCode}</span>
                </SweetAlert>}
              </Form>
            </div>
          }
          ftTextCenter
        />
      </Grid>
    );
  }
}

export default AuthenticatorPage;
