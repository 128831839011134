import React, { Component } from "react";

export class StatsCard extends Component {
  render() {
    return (
      <div className="card card-stats">
        <div className="contentCard">
          <div>
            <div className={(this.props.statsTextClass ? " " + this.props.statsTextClass : "")}>
              <div className="numbersCard">
                <span className="inconCard">{this.props.bigIcon}</span>
                <p className="titleCard">{this.props.statsText}</p>
              </div>
              <div className="timeText">
                {/* {this.props.statsIcon} {this.props.statsIconText} */}
                <p className="numberInCard">{this.props.statsValue}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          {/* <hr /> */}
          <div className="statsCard">
            <br />
            {/* {this.props.footerText} */}
          </div>
        </div>
      </div>
    );
  }
}

export default StatsCard;
