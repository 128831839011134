import React, { Component } from "react";

import jsPDF from "jspdf";
import "jspdf-autotable";

import { Grid, Col, Row } from "react-bootstrap";

import ReactTable from "react-table";
import Card       from "components/Card/Card.jsx";
import StatsCard  from "components/Card/StatsCard.jsx";
import Button     from "components/CustomButton/CustomButton.jsx";

import api from "services/api";
import loading    from "assets/img/loading.gif";

// react component that creates a dropdown menu for selection
import Select from "react-select";
import "react-select/dist/react-select.css";

// logo for sidebar
import logo from "assets/img/boardLogoAccenture.png";

class Report extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading         : true,
      loading_report  : true,

      admin   : false,
      error   : false,

      orgs        : [],
      period      : [],

      defaultorg      : '',

      selectedorg     : localStorage.getItem('org_report')     ? localStorage.getItem('org_report') : '',
      selectedperiod  : localStorage.getItem('period_report')  ? {value : localStorage.getItem('period_report')} : {value : this.getDefaultFilterDate()},

      selectedorg_label : '',

      hours       : '',
      consumed    : '',
      over_time   : '',
      improvement : '',
      support     : '',
      solicitation: '',

      pages       : 0,
      filtered    : [],

      all_rows    : [],
      rows        : []
    }
  }

  componentDidMount() {
    this.preLoadContent()
  }

    preLoadContent = async () => {

    try {
      const response = await api.post("/report.php", { 
        method  : 'preLoadContent'
      });

      if (response.data == 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        this.setState({
          loading         : false,

          admin       : response.data.admin,

          orgs        : response.data.orgs,
          defaultorg  : response.data.default_value,

          selectedorg     : response.data.admin && localStorage.getItem('org_report')     ? localStorage.getItem('org_report')              : response.data.default_value,
          selectedperiod  : response.data.admin && localStorage.getItem('period_report')  ? {value : localStorage.getItem('period_report')} : {value : this.getDefaultFilterDate()},

          selectedorg_label : response.data.default_value_label,

          filtered    : [
            {
              'id'    : 'org',
              'value' : response.data.admin && localStorage.getItem('org_report') ? localStorage.getItem('org_report') : response.data.default_value,
            },
            {
              'id'    : 'date',
              'value' : response.data.admin && localStorage.getItem('period_report')  ? localStorage.getItem('period_report') : this.getDefaultFilterDate()
            }
          ],

          period  : response.data.period,
          pages   : response.data.pages
        });
      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }
  }

  getReport = async (page = false, pageSize = false, sorted = [], filtered = []) => {
    this.setState({ 
      rows : []
    })

    try {
      const response = await api.post("/report.php", { 
        method    : 'get',

        page      : page,
        pageSize  : pageSize,
        sorted    : sorted,
        filtered  : filtered
      });

      this.setState({ 

        hours       : response.data.hours,
        consumed    : response.data.consumed,
        over_time   : response.data.over_time,
        improvement : response.data.improvement,
        support     : response.data.support,
        solicitation: response.data.solicitation,

        rows : 
        response.data.rows.map((row) => {
          return {
            code            : row['Name'],
            subject         : row['Subject'],
            created         : row['Created'],
            type            : row['Type'],
            effort          : row['Effort'],
            consumed_hours  : row['Hours'],
            over_time       : row['Over_time'],
            status          : row['Status']
          };
        }),

        loading_report  : false,

        pages   : response.data.pages
      });

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }
  }

  getDate() {
    var d = new Date();
    var m = d.getMonth() + 1;

    if (m < 10)
      return '0' + m + ' / ' + d.getFullYear();

    return m + ' / ' + d.getFullYear();
  }

  getDefaultFilterDate() {
    var d = new Date();
    var m = d.getMonth() + 1;

    if (m < 10)
      return  d.getFullYear() + '-0' + m;
    else if (m == 13)
      return  (d.getFullYear() + 1) + '-01';

    return d.getFullYear() + '-' + m;
  }

  formatDate(date) {
    if (date == '' || date == 'all')
      return this.getDate()

    var d = new Date(date);
    var m = d.getMonth() + 2;

    if (m < 10)
      return  '0' + m + '/' + d.getFullYear();
    else if (m == 13)
      return  '01/' + (d.getFullYear() + 1);

    return m + '/' + d.getFullYear();
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });

    this.table.fireFetchData();
  };

  convertImgToBase64(img, outputFormat){
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");

    // clear canvas
      canvas.width = img.width;
      canvas.height = img.height;

      // draw image
      ctx.drawImage(img, 0, 0);

      // get data url of output format or defaults to jpeg if not set
      return canvas.toDataURL("image/" + (outputFormat || "jpeg"));
  }

  exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    var img = new Image();

    img.crossOrigin = "";  
    img.src = logo;
    

    const title = 'Accenture' /* this.state.selectedorg_label */ + ' (' + this.formatDate(this.state.selectedperiod.value) + ')';

    const headers_h = [["Horas contratadas", "Horas consumidas", "Melhoria", "Suporte","Solicitação"]];

    const data_h = [[this.state.hours, this.state.consumed, this.state.improvement, this.state.support, this.state.solicitation]];

    let content_h = {
      startY: 100,
      head: headers_h,
      body: data_h,
      styles: { fontSize: 8 },
      headStyles:{fillColor:[192, 67, 192]},
      columnStyles: {
        0: {columnWidth: 150},
        1: {columnWidth: 150},
        2: {columnWidth: 150},
        3: {columnWidth: 150},
        4: {columnWidth: 145}
      }
    };

    const headers = [["Código", "Incidente/Melhoria", "Criado por", "Tipo", "Estimativa", "Horas", "Hr.extras" ,"Status"]];

    api.post('/report.php', {
      method    : 'getall',
      filtered  : this.state.filtered
    }).then((response) => {

      this.setState({ 
        all_rows : 
        response.data.rows.map((row) => {
          return {
            code            : row['Name'],
            subject         : row['Subject'],
            created         : row['Created'],
            type            : row['Type'],
            effort          : row['Effort'],
            consumed_hours  : row['Hours'],
            over_time       : row['Over_time'],
            status          : row['Status']
          };
        })
      });

      const data = this.state.all_rows.map(row => [row.code, row.subject,
        /*(row.subject.length > 40 ? row.subject.substring(0, 40) + '...' : row.subject.substring(0, 40)), */
        row.created, row.type, row.effort, row.consumed_hours, row.over_time, row.status]);

      let content = {
        startY: 150,
        head: headers,
        body: data,
        styles: { fontSize: 8},
        headStyles:{fillColor:[192, 67, 192]},
        columnStyles: {
          0: {columnWidth: 100},
          1: {columnWidth: 210},
          2: {columnWidth: 160},
          3: {columnWidth: 50},
          4: {columnWidth: 60},
          5: {columnWidth: 40},
          6: {columnWidth: 45},
          7: {columnWidth: 80},
        }
      };

      doc.text(title, 400, 65);
      doc.addImage(img, 'PNG', 40, 40, 90, 50);
      doc.autoTable(content_h);
      doc.autoTable(content);

      doc.save(title + ".pdf")

    })
  }

  render() {
    return (
      <div className="main-content">

        <div className={this.state.loading ? "loading-form" : "fadeOut loading-form" }>
          <img src={loading} alt="Carregando.." />
        </div>

        {this.state.loading ? '' :
          <div>
            <Grid fluid>
              <Row>
                <Col md={8}>
                  <div className="dashboard-filter">
                    <h2 style={{fontSize:'3rem', fontFamily:'Graphik Regular, Arial, Sans-Serif'}}>Organização</h2>
                    <Select
                      placeholder="Selecione uma organização"
                      name="org"
                      disabled={this.state.admin === true ? false : true}
                      value={this.state.selectedorg}
                      options={this.state.orgs}
                      onChange={e => {
                        localStorage.setItem('org_report', e !== null ? e.value : '')

                        this.setState({
                          selectedorg       : e !== null ? e.value : '',
                          selectedorg_label : e !== null ? e.label : '',
                          loading_report    : e !== null ? true : this.state.loading_report
                        })

                        if (e !== null)
                          this.onFilteredChangeCustom(e.value, 'org');
                      }}
                    />
                  </div>
                </Col>

                <Col md={4}>
                  <div className="dashboard-filter">
                    <h2 style={{fontSize:'3rem', fontFamily:'Graphik Regular, Arial, Sans-Serif'}}>Período</h2>
                    <Select
                      placeholder="Selecione o período"
                      name="period"
                      value={this.state.selectedperiod.value}
                      options={this.state.period}
                      onChange={e => {
                        localStorage.setItem('period_report', e !== null ? e.value : '')

                        this.setState({
                          selectedperiod  : { value : e !== null ? e.value : '' },
                          loading_report  : e !== null ? true : this.state.loading_report
                        })

                        if (e !== null)
                            this.onFilteredChangeCustom(e.value, 'date');
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Grid>

            <Grid fluid>
              <Row style={{display: "flex", justifyContent: "center", marginTop: 30}}>
                <Col lg={3} sm={6}>
                  <StatsCard
                    bigIcon={<i style={{background:'#9305F2', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', fontWeight:'bold'}} className="pe-7s-note2 text-warning" />}
                    statsText={<div className="stats-card">Contratadas</div>}
                    statsValue={!this.state.loading_report ? this.state.hours : <img className="loading-info" src={loading} alt="Carregando.." />}
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText={this.getDate()}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <StatsCard
                    bigIcon={<i style={{background:'#9305F2', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', fontWeight:'bold'}} className="pe-7s-wristwatch text-info" />}
                    statsText={<div className="stats-card">Consumidas</div>}
                    statsValue={!this.state.loading_report ? this.state.consumed : <img className="loading-info" src={loading} alt="Carregando.." />}
                    footerText = {"Horas extras: " + this.state.over_time}
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText={this.formatDate(this.state.selectedperiod.value)}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <StatsCard
                    bigIcon={<i style={{background:'#9305F2', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', fontWeight:'bold'}} className="pe-7s-magic-wand text-success" />}
                    statsText={<div className="stats-card">Melhoria</div>}
                    statsValue={!this.state.loading_report ? this.state.improvement : <img className="loading-info" src={loading} alt="Carregando.." />}
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText={this.formatDate(this.state.selectedperiod.value)}
                  />
                </Col>
                <Col lg={3} sm={6}>
                  <StatsCard
                    bigIcon={<i style={{background:'#9305F2', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', fontWeight:'bold'}} className="pe-7s-tools text-danger" />}
                    statsText={<div className="stats-card">Suporte</div>}
                    statsValue={!this.state.loading_report ? this.state.support : <img className="loading-info" src={loading} alt="Carregando.." />}
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText={this.formatDate(this.state.selectedperiod.value)}
                    statsTextClass="padding-left-zero"
                  />
                </Col>

                <Col lg={3} sm={6}>
                  <StatsCard
                    bigIcon={<i style={{background:'#9305F2', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent', fontWeight:'bold'}} className="pe-7s-headphones text-muted" />}
                    statsText={<div className="stats-card">Solicitação</div>}
                    statsValue={!this.state.loading_report ? this.state.solicitation : <img className="loading-info" src={loading} alt="Carregando.." />}
                    statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText={this.formatDate(this.state.selectedperiod.value)}
                    statsTextClass="padding-left-zero"
                  />
                </Col>
              </Row>

              <Card
                minheight={true}
                title={
                  <div>
                    <span>Report de horas</span>
                    {this.state.rows.length > 0 ?
                    <div className="table-buttons">
                      <button className="btnResetPassword" type="button" bsSize="xs" onClick={() => this.exportPDF()}>Exportar</button>
                    </div>
                    : ''}
                  </div>
                }
                content={
                  <ReactTable
                    ref={(instance) => { this.table = instance; }}
                    data={this.state.rows}
                    filtered={this.state.filtered}
                    className="table-report -striped -highlight"
                    getTrProps={(state, rowInfo, column, instance) => ({
                      onDoubleClick: e => window.location.href = 'admtickets/view/' +rowInfo.original.code
                    })}
    
                    columns={[
                      {
                        Header: "Código",
                        accessor: "code",
                        filterable: false
                      },
                      {
                        Header: "Incidente/Melhoria",
                        accessor: "subject",
                        filterable: false
                      },
                      {
                        Header: "Criado por",
                        accessor: "created",
                        filterable: false
                      },
                      {
                        Header: "Tipo",
                        accessor: "type",
                        filterable: false
                      },
                      {
                        Header: "Estimativa",
                        accessor: "effort",
                        filterable: false
                      },
                      {
                        Header: "Horas",
                        accessor: "consumed_hours",
                        filterable: false
                      },
                      {
                        Header: "Hr.extras",
                        accessor: "over_time",
                        filterable: false
                      },
                      {
                        Header: () => (<div className="left">Status</div>),
                        accessor: "status",
                        filterable: false
                      }
                    ]}
                    //resized={[
                    //  {"id": "code",            "value": 90},
                    //  {"id": "subject",         "value": 300},
                    //  {"id": "created",         "value": 150},
                    //  {"id": "type",            "value": 95},
                    //  {"id": "effort",          "value": 95},
                    //  {"id": "consumed_hours",  "value": 70},
                    //  {"id": "over_time",       "value": 100},
                    //  {"id": "status",          "value": 140}
                    //]}
                    sortable={false}
                    onResizedChange={(newResized, event) => {}}
                    pages={this.state.pages}
                    minRows={10}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom
                    previousText="Anterior"
                    nextText="Próxima"
                    loadingText={<img src={loading} className="loading" alt="Carregando.." />}
                    noDataText={this.state.loading_report ? "" : (this.state.error ? this.state.error : "Sem registros encontrados")}
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    loading={this.state.loading_report}
                    manual // this would indicate that server side pagination has been enabled 
                    onFetchData={(state, instance) => {
                      this.setState({
                        loading_report: true
                      });

                      this.getReport(state.page, state.pageSize, state.sorted, state.filtered)
                    }}
                  />
                }
              />
            </Grid>
          </div>
        }
      </div>
    );
  }
}

export default Report;