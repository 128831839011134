import React, { Component } from "react";

import { Grid, Form } from "react-bootstrap";
import { login } from "services/auth";

import Card from "components/Card/Card.jsx";
import logoLogin  from "assets/img/logoAccenture.svg";
import api from "services/api";

import "assets/login/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "assets/login/fonts/iconic/css/material-design-iconic-font.min.css";
import "assets/login/vendor/animate/animate.css";
import "assets/login/vendor/css-hamburgers/hamburgers.min.css";
import "assets/login/vendor/animsition/css/animsition.min.css";
import "assets/login/vendor/select2/select2.min.css";
import "assets/login/vendor/daterangepicker/daterangepicker.css";
import "assets/login/css/util.css";
import "assets/login/css/main.css";
import "assets/css/styleResetPassword.css";

import bgImage from "assets/img/accentureLoginNew.png";

import loading_icon from "assets/img/loading.gif";

class ResetPasswordAcc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cardHidden: true,

            password: "",
            confirm_password: "",
            message: "",
            token: null,
            sended: false,

            loading: false,
            remember: false
        };

        sessionStorage.clear()
    }

    getLogo() {
        return (
          <div>{/* <img src={logoLogin} width={100} height={100} alt="Login" /> */}<div className="title-portal">Accenture <span className="songTitle">Song</span></div></div>
        )
      }

    handleResetPassword = async e => {
        e.preventDefault();

        const password = this.state.password;
        const confirm_password = this.state.confirm_password;
        const url = new URL(window.location.href);
        const token = url.searchParams.get('token');

        try {
            const response = await api.post("/resetPassword.php", { password, confirm_password, token });
    
            if (response.data.status == true) {
                this.setState({ message: "Sua senha foi alterada com sucesso." });
            } 
            else if(response.data.message == "As senhas informadas não coincidem"){
                this.setState({ message: "As senhas informadas não coincidem" });
            }
            else if(response.data.message == "A nova senha deve ter no mínimo 8 caracteres!"){
                this.setState({ message: "A nova senha deve ter no mínimo 8 caracteres!" });
            }
            else if (response.data.message == "Token Expirado") {
                this.setState({ message: "Token Expirado." });
            } 
            else {
                this.setState({message: "Erro. Sua senha não foi alterada."})
            }
    
        } finally {
    
        }
      
    }

    handelEmail = async e => {
        e.preventDefault();

        const url = new URL(window.location.href);
        const username = this.state.username;
        const email = this.state.email;

        try {
            const response = await api.post("/resetPassword.php", { 'username': username, 'email': email, 'url': url });
            if(response.data.status == true) {
                this.setState({message: "Um e-mail de recuperação foi enviado."});
                this.setState({sended: true});
            }
            else {
                this.setState({message: "E-mail incorreto."});
            }
        } finally {

        }
    }

    render() {

        const url = new URL(window.location.href);
        const token = url.searchParams.get('token');
        if (token == null) {
            return (
                <div className="wrapper wrapper-full-page">
                    <div className={"full-page"} data-image={bgImage}>
                        <div className="content">
                            <Grid>
                                <Card
                                    hidden={this.state.cardHidden}
                                    textCenter
                                    title={this.getLogo()}
                                    customClass="wrap-login100"
                                    content={
                                        <div>
                                            <p className="title-portal" id="reset">Insira um e-mail:</p>
                                            <Form onSubmit={this.handelEmail} className="login100-form validate-form">

                                                {/* <div className="wrap-input100 validate-input">
                                                    <input className="input100" type="text" name="username" placeholder="Usuário" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={e => this.setState({ username: e.target.value, error: "" })} value={this.state.username} />
                                                    <span className="focus-input100" data-placeholder="&#xf207;"></span>
                                                </div> */}

                                                <div className="wrap-input100 validate-input">
                                                    <input className="input100" type="text" name="e-mail" placeholder="E-mail" onChange={e => this.setState({ email: e.target.value, error: "" })} value={this.state.email} />
                                                    <span className="focus-input100" data-placeholder="&#xf15a;"></span>
                                                </div>

                                                <div className="message">{this.state.message}</div>

                                                <div className="container-login100-form-btn">
                                                    <button type="submit" className="login100-form-btn" disabled={this.state.sended}>Enviar</button>
                                                </div>


                                            </Form>
                                        </div>
                                    }
                                    ftTextCenter
                                />
                            </Grid>

                        </div>
                        <div className="full-page-background"
                            style={{ backgroundImage: "url(" + bgImage + ")" }}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <Grid>
                                <Card
                                    hidden={this.state.cardHidden}
                                    textCenter
                                    title={this.getLogo()}
                                    customClass="wrap-login100"
                                    content={
                                        <div>
                                            <p className="title-portal" id="reset">Redefina sua senha:</p>
                                            <Form onSubmit={this.handleResetPassword} className="login100-form validate-form">

                                                <div className="wrap-input100 validate-input">
                                                    <input className="input100" type="text" name="password" placeholder="Nova senha" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={e => this.setState({ password: e.target.value, error: "" })} value={this.state.password} />
                                                    <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                                </div>

                                                <div className="wrap-input100 validate-input">
                                                    <input className="input100" type="text" name="confirm-password" placeholder="Confirme a senha" onChange={e => this.setState({ confirm_password: e.target.value, error: "" })} value={this.state.confirm_password} />
                                                    <span className="focus-input100" data-placeholder="&#xf191;"></span>
                                                </div>

                                                <div className="message">{this.state.message}</div>

                                                <div className="login-error">{this.state.error}</div>

                                                <div className="container-login100-form-btn">
                                                    <button type="submit" className="btnLogin">{this.state.loading ? <img src={loading_icon} className="loading-login" /> : ''} Login</button>
                                                </div>


                                            </Form>
                                        </div>
                                    }
                                    ftTextCenter
                                />
                            </Grid>

            );
        }
    }
}

export default ResetPasswordAcc;