import React, { Component } from "react";
import { Grid, Row, Col, ControlLabel, FormGroup, FormControl } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import UserCard from "components/Card/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

import api from "services/api";

import loading from "assets/img/loading.gif";

import avatar from "assets/img/default-avatar-2.png";
import avatar2 from "assets/img/default-avatar.png";
import avatar3 from "assets/img/default-avatar-un.png";

import Select from "react-select";
import "react-select/dist/react-select.css";

import UserTabs from "../../components/SharedForm/UserTabs";

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading     : true,
      openAvatar  : true,
      alert       : null,

      avatar    : avatar3,

      rowid     : '',
      company   : '',
      username  : '',
      email     : '',

      name        : '',
      name_orig   : '',

      address     : '',
      complement  : '',
      city        : '',
      state       : '',
      country     : '',

      form : {
        name        : { value : '', required : true},
        address     : { value : '', required : false},
        complement  : { value : '', required : false},
        city        : { value : '', required : false},
        state       : { value : '', required : false},
        country     : { value : '', required : false}
      },

      formErrors : {
        name : false
      },

      selectedpostn : '',
      postns        : []

    };
  }

  
  componentDidMount() {
    this.preLoadContent()
  }

  preLoadContent = async () => {
    try {
      const response = await api.post("/user.php", { 
        method    : 'preLoadContent', 
        username  : sessionStorage.getItem('username')
      });

      if (response.data == 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        this.setState({
          avatar    : (response.data.gender == 'Feminino' ? avatar : (response.data.gender == 'Masculino' ? avatar2 : avatar3)),

          rowid     : response.data.rowid,
          company   : response.data.company,
          username  : response.data.username,
          email     : response.data.email,

          name        : response.data.name,
          name_orig   : response.data.name,

          address     : response.data.address,
          complement  : response.data.complement,
          city        : response.data.city,
          state       : response.data.state,
          country     : response.data.country,

          form : {
            name        : { value : response.data.name,       required : true},
            address     : { value : response.data.address,    required : false},
            complement  : { value : response.data.complement, required : false},
            city        : { value : response.data.city,       required : false},
            state       : { value : response.data.state,      required : false},
            country     : { value : response.data.country,    required : false}
          },

          selectedpostn : (sessionStorage.getItem('postn') !== '' ? sessionStorage.getItem('postn') : response.data.selectedpostn),
          postns        : response.data.postns,

          loading : false
        });
      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }
  }

  
  handleTypeValidation = e => {
    e.preventDefault();

    let self = this
    let formMsg = false

    Object.keys(this.state.form).map(function (index) {
      self.setState(prevState => ({
          formErrors: {
            ...prevState.formErrors,
            [index] : self.state.form[index].value === "" && self.state.form[index].required ? true : false
          },
      }));

      formMsg = self.state.form[index].value === "" && self.state.form[index].required ? true : formMsg
    })

    this.setState({
      formMsg : formMsg
    })

    if (!formMsg)
      this.handleSubmit(e)
  }

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name] : value
    })

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name] : { 
          ...prevState.form[name],
          value : value 
        }
      },

      formErrors: {
        ...prevState.formErrors,
        [name] : value === "" ? true : false
      },
    }));

    let fmsg = false;
    let self = this;

    setTimeout( function() {
      if (document.getElementsByClassName('has-error').length >= 1) {
        fmsg = true;
      }
  
      self.setState({
        formMsg : fmsg
      })
 
    }, 300)

  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      loading : true
    });

    const { name, address, complement, city, state, country } = this.state.form;

    const formData = new FormData();

    formData.append('method',     'update');
    formData.append('rowid',      this.state.rowid);
    formData.append('name',       name.value);
    formData.append('address',    address.value);
    formData.append('complement', complement.value);
    formData.append('city',       city.value);
    formData.append('state',      state.value);
    formData.append('country',    country.value);

    api.post('/user.php', formData).then((result) => {

      result.data.status === 'ok' ?

      this.setState({
        loading   : false,
        name_orig : name.value,

        alert   : (
          <SweetAlert 
          style={{ display: "block", marginTop: "-240px" }}
          success 
          title="Obrigado!"
          onConfirm={() => this.hideAlert()}>Dados atualizados com sucesso!</SweetAlert>
        )
      }) :

      this.setState({
        loading : false,
        alert   : (
          <SweetAlert 
          style={{ display: "block", marginTop: "-240px" }}
          error 
          title="Erro ao atualizar dados" 
          onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
        )
      });

    });
  }

  hideAlert() {
    this.setState({
      alert : null
    });
  }

  render() {
    return (
      <div className="main-content">
        {this.state.alert}
        <Grid fluid>
          <Row>
            <Col md={8}>
              <Card
                title="Editar Perfil"
                content={
                  <div>
                    <div className={this.state.loading ? "loading-form" : "fadeOut loading-form" }>
                      <img src={loading} alt="Carregando.." />
                    </div>
                    <form className={this.state.loading ? "opacity form-user" : "fadeIn form-user" }>
                      <UserTabs></UserTabs>
                      {this.state.loading ? '' :
                      <div>
                        <div className="row">
                            <Col sm={4}>
                              <FormGroup>
                                <ControlLabel>Empresa</ControlLabel>
                                <FormControl
                                  name="company" 
                                  type="text" 
                                  placeholder="Empresa" 
                                  ref="company" 
                                  value={this.state.company} 
                                  readOnly={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={3}>
                              <FormGroup>
                                <ControlLabel>Usuário</ControlLabel>
                                <FormControl
                                  name="username" 
                                  type="text" 
                                  placeholder="Usuário" 
                                  ref="username" 
                                  value={this.state.username} 
                                  readOnly={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={5}>
                              <FormGroup>
                                <ControlLabel>E-mail</ControlLabel>
                                <FormControl
                                  name="email" 
                                  type="text" 
                                  placeholder="E-mail" 
                                  ref="email" 
                                  value={this.state.email} 
                                  readOnly={true}
                                />
                              </FormGroup>
                            </Col>
                        </div>
                        <div className="row">
                            <Col sm={12}>
                              <FormGroup className={this.state.formErrors.name ? "has-error" : ""}>
                                <ControlLabel>Nome <span className="required">*</span></ControlLabel>
                                <FormControl
                                  name="name" 
                                  type="text" 
                                  placeholder="Nome" 
                                  ref="name" 
                                  value={this.state.name} 
                                  onChange={this.handleChange} 
                                  maxLength={50}
                                />
                              </FormGroup>
                            </Col>
                        </div>
                        <div className="row">
                            <Col sm={8}>
                              <FormGroup>
                                <ControlLabel>Endereço</ControlLabel>
                                <FormControl
                                  name="address" 
                                  type="text" 
                                  placeholder="Endereço" 
                                  ref="address" 
                                  value={this.state.address} 
                                  onChange={this.handleChange} 
                                  maxLength={85}
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={4}>
                              <FormGroup>
                                <ControlLabel>Complemento</ControlLabel>
                                <FormControl
                                  name="complement" 
                                  type="text" 
                                  placeholder="Complemento" 
                                  ref="complement" 
                                  value={this.state.complement} 
                                  onChange={this.handleChange} 
                                  maxLength={15}
                                />
                              </FormGroup>
                            </Col>
                        </div>
                        <div className="row">
                            <Col sm={6}>
                              <FormGroup>
                                <ControlLabel>Cidade</ControlLabel>
                                <FormControl
                                  name="city" 
                                  type="text" 
                                  placeholder="Cidade" 
                                  ref="city" 
                                  value={this.state.city} 
                                  onChange={this.handleChange}
                                  maxLength={20} 
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={2}>
                              <FormGroup>
                                <ControlLabel>Estado</ControlLabel>
                                <FormControl
                                  name="state" 
                                  type="text" 
                                  placeholder="Estado" 
                                  ref="state" 
                                  value={this.state.state} 
                                  onChange={this.handleChange}
                                  maxLength={2} 
                                />
                              </FormGroup>
                            </Col>
                            <Col sm={4}>
                              <FormGroup>
                                <ControlLabel>País</ControlLabel>
                                <FormControl
                                  name="country" 
                                  type="text" 
                                  placeholder="País" 
                                  ref="country" 
                                  value={this.state.country}
                                  onChange={this.handleChange}
                                  maxLength={20} 
                                />
                              </FormGroup>
                            </Col>
                        </div>
                        <div className="row">
                          <button id="update-user" className="btnResetPassword" pullRight fill type="button" onClick={this.handleTypeValidation}>Atualizar Perfil</button>
                        </div>
                      </div>}
                    </form>
                  </div>
                }
              />
            </Col>
            {this.state.loading ? '' : 
            <Col md={4}>
              <UserCard
                bgImage={require('../../assets/img/accUserBackground.jpg')}
                avatar={this.state.avatar}
                name={this.state.name_orig}
                userName={this.state.username}
                description={
                  <div className="select-postn">
                    <Select
                      placeholder="Selecione uma posição"
                      name="postn"
                      value={this.state.selectedpostn}
                      options={this.state.postns}
                      onChange={e => {
                        this.setState({
                          selectedpostn : e !== null ? e.value : ''
                        })

                        if (e !== null) {
                          sessionStorage.setItem('postn', e.value)
                        }

                      }}
                    />
                  </div>
                }
                /*socials={
                  <div>
                    <Button simple>
                      <i className="fa fa-facebook-square" />
                    </Button>
                    <Button simple>
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button simple>
                      <i className="fa fa-google-plus-square" />
                    </Button>
                  </div>
                }*/
              />
            </Col>}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default UserPage;
