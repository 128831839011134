import React, { Component } from "react";
import { notificationEvent, setNotificationInterval, getNotification } from '../../services/notifications';
import {

  Navbar,
  Nav,
  NavDropdown,
  MenuItem,
  FormGroup,
  InputGroup,
} from "react-bootstrap";


import { Redirect } from "react-router-dom";

import Autosuggest from 'react-autosuggest';

import { logout } from "services/auth";

import api from "services/api";

import notification from 'assets/img/icons/notification.png';

import circle_notification from 'assets/img/icons/severity/1.png';

const theme = {
  container: {
    position: 'relative'
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    width: "500px",
    backgroundColor: '#fff',
    fontFamily: 'inherit',
    fontSize: 14,
    zIndex: 4,
    overflow: 'auto',
    maxHeight: '500px',
    top: '39px',
    boxShadow: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 12px'
  },
  suggestionHighlighted: {
    backgroundColor: '#eee'
  }
};

// Use your imagination to render suggestions.
/*

const renderSuggestion = suggestion => (
  <a href={'/tickets/view/' + suggestion.code}>
    <div>
      <span className="suggest-ticket">({suggestion.code})</span> 
      <span className="suggest-ticket-name">{suggestion.subject}</span>
    </div>
  </a>
);

*/

const renderSuggestion = suggestion => (
  <div>
    <span className="suggest-ticket">({suggestion.code})</span>
    <span className="suggest-ticket-name">{suggestion.subject}</span>
  </div>
);

class HeaderLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: false,
      url: '',
      code: '',
      notification: [],
      suggestions: [],
      value: '',
      isOpen: false,
      isOpenNotification: false
    };
  }

  redirect_ticket() {
    alert('alou')
  }

  sessionClear() {
    logout()
  }

  // Suggest

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    api.post("/pickapplet.php", {
      method: 'tickets',
      value: value
    }).then((response) => {

      const tickets = response.data

      this.setState({
        suggestions: inputLength < 10 ? [] : tickets
      });
    })
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue(suggestion) {
    return suggestion.name;
  }

  onSuggestionSelected = (event, { suggestion }) => {
    this.setState({
      redirect: true,
      url: '/tickets/view/' + suggestion.code,
      code: suggestion.code
    });
  };

  onChangeSuggest = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.getSuggestions(value)
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  componentDidMount() {
    this.getBoard();
    notificationEvent.addEventListener('notification received', this.notificationReceived)
    getNotification(false)
    setNotificationInterval()


  }
  getBoard = async () => {

    try {
      await api.post("/board.php", {
        method: 'getAvStatus',
        resp: this.state.resp,
        filtered: this.state.filtered,
        squads: this.state.marked_squads,
        limit: this.state.selected_limit,
      }).then((response_status) => {
        if (response_status.data === 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida." });
        }
        else {
          this.setState({
            loading: false,
            lanes:
              response_status.data.av_status !== undefined ?
                response_status.data.av_status.lanes.map((row) => {
                  return {
                    id: row['id'],
                    title: row['title'],
                    cards: row['cards']
                  }
                }) : []
            //admin : response_status.data.adm
          });
        }
      });

    }
    catch (err) {
      console.log(err);
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
    }

  }

  notificationReceived = () => {
    const localNotification = localStorage.getItem('notification')
    if (localNotification !== JSON.stringify(this.state.notification)) {
      this.setState({
        notification: JSON.parse(localNotification)
      })
    }
  }

  render() {
    const { value, suggestions } = this.state;


    const inputProps = {
      className: "search-form form-control",
      placeholder: "Pesquisar tickets...",
      value,
      onChange: this.onChangeSuggest
    };

    const closePopUp = () =>{
      if(this.state.isOpenNotification){
        this.setState({isOpenNotification: false})
      }
    }

    return (
      this.state.redirect ? (
        <Redirect push to={this.state.url} />
      ) :

        <div /* onBlur={closePopUp} */>
          {((window.location.href.split('/').length - 1) <= 4) ?

            <Navbar.Form pullLeft className="navbar-search-form">
              <FormGroup>
                <InputGroup>
                  <InputGroup.Addon>
                    <i className="fa fa-search" />
                  </InputGroup.Addon>
                  <Autosuggest
                    suggestions={suggestions}
                    onSuggestionSelected={this.onSuggestionSelected}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={renderSuggestion}
                    inputProps={inputProps}
                    theme={theme}
                  />
                  {/*<FormControl type="text" placeholder="Pesquisar..." className="search-form" />*/}
                </InputGroup>
              </FormGroup>
            </Navbar.Form> : ''}



          <Nav pullRight>

            {/*
          <NavDropdown
            eventKey={2}
            title={
              <div>
                <i className="fa fa-gavel" />
                <p className="hidden-md hidden-lg">
                  Ações
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-1"
          >
            <MenuItem eventKey={2.1}>Create New Post</MenuItem>
            <MenuItem eventKey={2.2}>Manage Something</MenuItem>
            <MenuItem eventKey={2.3}>Do Nothing</MenuItem>
            <MenuItem eventKey={2.4}>Submit to live</MenuItem>
            <MenuItem divider />
            <MenuItem eventKey={2.5}>Another action</MenuItem>
          </NavDropdown>
          */}
            {/* 
          <NavDropdown
            eventKey={3}
            title={
              <div>
                <i className="fa fa-bell-o" />
                <span className="notification">5</span>
                <p className="hidden-md hidden-lg">
                  Notificações
                  <b className="caret" />
                </p>
              </div>
            }
            noCaret
            id="basic-nav-dropdown-2"
          >
            <MenuItem eventKey={3.1}>Notificação 1</MenuItem>
            <MenuItem eventKey={3.2}>Notificação 2</MenuItem>
            <MenuItem eventKey={3.3}>Notificação 3</MenuItem>
            <MenuItem eventKey={3.4}>Notificação 4</MenuItem>
            <MenuItem eventKey={3.5}>Outra notificação</MenuItem>
          </NavDropdown>
          */}
            <NavDropdown
              open={this.state.isOpenNotification}
              onClick={() => this.setState({ isOpenNotification: !this.state.isOpenNotification })}
              eventKey={3}
              title={
                <div>
                  <div>{
                    this.state.notification.length === 0 ? '': 
                    <div className="number-notification">{this.state.notification.length}</div> 
                    }
                    <img className="icon-notification" src={notification} width={20} height={20}/>
                  </div>
                </div>
              }
              noCaret
              id="basic-nav-dropdown-2"
              bsClass="dropdown-with-icons dropdown" className="scroll-notification">
                {this.state.notification.map((notification) => (
                  <MenuItem onClick={()=>window.location.href = '/tickets/view/' + notification.ticket_code} className="notification-dropdown" key={notification.id}>
                    <span className="notification-dropdown-title">{notification.ticket_code}</span><br />
                    <p>{notification.message}</p>
                  </MenuItem>
                ))}
            </NavDropdown>

            <NavDropdown
              open={this.state.isOpen}
              onClick={() => this.setState({ isOpen: !this.state.isOpen })}
              eventKey={3}
              title={
                <div>
                  <i className="fa fa-list" />
                  <p className="hidden-md hidden-lg">
                    Mais
                    <b className="caret" />
                  </p>
                </div>
              }
              noCaret
              id="basic-nav-dropdown"
              bsClass="dropdown-with-icons dropdown"
            >
              {/* 
            <MenuItem eventKey={4.1}>
              <i className="pe-7s-mail" /> Mensagens
            </MenuItem>
            <MenuItem eventKey={4.2}>
              <i className="pe-7s-help1" /> Ajuda
            </MenuItem>
            <MenuItem eventKey={4.3}>
              <i className="pe-7s-tools" /> Configurações
            </MenuItem>
            
            <MenuItem divider />
            */}

              {
                /*
                <MenuItem eventKey={4.4}>
                  <i className="pe-7s-lock" /> Lock Screen
                </MenuItem>
                */

              }
              <MenuItem eventKey={3.1} onClick={() => this.sessionClear()}>
                <div className="text-danger">
                  <i className="pe-7s-close-circle" /> Logout
                </div>
              </MenuItem>



            </NavDropdown>

          </Nav>

        </div>
    );
  }
}
export default HeaderLinks;
