import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import loginRoute from  "./routes/login.jsx";
import indexRoutes from "./routes/index.jsx";

/*import ResetPassword from "views/Pages/ResetPassword.jsx";*/

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/light-bootstrap-dashboard.css?v=1.1.1";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

import { registerPlugin } from 'react-filepond';

//import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
//import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';

import { isAuthenticated, isAuthenticatedResp } from "./services/auth";

registerPlugin(/*FilePondPluginGetFile, */FilePondPluginImagePreview);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        isAuthenticatedResp(rest.resp) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/dashboard" />
        )
      ) : (
        <Redirect to={{ pathname: "login", state: { from: props.location } }} />
      )
    }
  />
);

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/*<Route path="/reset-password" component={ResetPassword} key={3} />*/}
      {loginRoute.map((prop, key) => {
        return <Route path={prop.path} component={prop.component} key={key} />;
      })}
      {indexRoutes.map((prop, key) => {
        return <PrivateRoute path={prop.path} component={prop.component} resp={prop.resp !== undefined ? prop.resp : false} key={key} />;
      })}
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
