import React, { Component } from "react";

import { Grid, Row, Col, Tooltip, OverlayTrigger, FormControl } from "react-bootstrap";
import { NavLink }  from "react-router-dom";

// react component for creating dynamic tables
import ReactTable   from "react-table";
import Card         from "components/Card/Card.jsx";
import Button       from "components/CustomButton/CustomButton.jsx";
import Severity     from "components/SharedForm/Severity.jsx"
import ListOfVal    from "components/SharedForm/ListOfVal";
//import ExcelExport  from "components/ExcelExport/ExcelExport.jsx";
import Datetime     from "react-datetime";

import Time       from 'react-time';

import loading    from "assets/img/loading.gif";

import api        from "services/api";

import Moment from "moment";
import 'moment/locale/pt-br.js';

class Tickets extends Component {
  constructor(props) {
    super(props);
    sessionStorage.setItem('previous-page', window.location.pathname.replace(/^\/|\/$/g,''));
    Moment.locale('pt-br');
  }

  state = {
    rows      : [],
    loading   : true,
    error     : false,
    pages     : 0,
    filter    : true,
    filtered  : this.getFilters()
  }

  getTickets = async (page = false, pageSize = false, sorted = [], filtered = []) => {
    try {
      const response = await api.post("/tickets.php", { 
        method    : 'get',
        page      : page,
        pageSize  : pageSize,
        sorted    : sorted,
        filtered  : filtered,
        postn     : sessionStorage.getItem('postn')
      });

      this.setState({ rows : 
        response.data.rows.map((row) => {
          return {
            id          : row['Id'],                                                // Rowid
            code        : row['Name'],                                              // Cód. Ticket
            title       : row['Subject'],                                           // Título
            solicitant  : row['Requestor'],                                         // Solicitante
            type        : row['Type'],                                             // Tipo
            resp        : row['Responsible'],                                     //Responsável
            date        : (row['Request Date'] !== "0000-00-00" ? 
                          <Time value={row['Request Date']} format="DD/MM/YYYY" /> : 
                          ""), // Data de solicitação
            severity    : <Severity level={row['Priority']} />,                     // Prioridade
            status      : row['Status'],                                            // Status
            actions: (
              // we've added some custom button actions
              <div className="actions-right">
                {/* use this button to add a edit kind of action */}
                  <Button onClick={() => {}} bsStyle="info" simple icon>
                    <NavLink to={ '/tickets/view/' + row['Name']}>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="edit_tooltip">Editar Ticket</Tooltip>}>
                        <Button  simple type="button" bsSize="xs">
                          <i className="fa fa-edit" style={{color:'#6204BF'}}/>
                        </Button>
                      </OverlayTrigger>
                    </NavLink>
                  </Button>{" "}
              </div>
            )
          };
        }),
        loading : false,
        pages   : response.data.pages
      });

    } catch (err) {
      //logout()
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }
  }

  advancedFilter = e => {
    
    e.preventDefault();
    let self = this

    this.setState({
      filter : self.state.filter ? false : true,
    })

    let clean_filter = true;

    if (this.state.filter === true) {
      if (this.state.filtered.length > 0) {
        this.state.filtered.map((row) => {
          if (row.id === 'prefilter') {
            clean_filter = false;
            this.setState({
              filtered : [{
                id    : row.id,
                value : row.value

              }]
            })
          }
        })

        if (clean_filter) {
          this.setState({
            filtered : []
          })
        }
      }
    }

    //this.table.fireFetchData();
  }

  onFilteredChangeCustom = (value, accessor) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;
          
          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
    this.saveFilters(filtered);
    this.table.fireFetchData();
  };

  onDatepickerRef(el) { if (el && el.input) { el.input.inputRef.readOnly = true; } }

  /**
   * sdsadasd
   * @param filter  aa
   */
  saveFilters(filters) {
    sessionStorage.setItem('ticket-filters', JSON.stringify(filters));
  };
  getFilters() {
    let filters = sessionStorage.getItem('ticket-filters');
    if(filters) {
      
      return JSON.parse(filters);
    }
    else {
      return []
    }
  }
  getFilterValue(id) {
    let filters = this.getFilters();
    let value;
    if (!filters.length) {
      return null;
    }
    filters.forEach((i) => {
      if(i.id === id) {
        value = i.value;
      }
    });
    return value;
  }
  render() {
    const filter      = <Tooltip id="filter">Filtro avançado</Tooltip>;
    const filter_dis  = <Tooltip id="filter">Remover filtro</Tooltip>;

    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={
                  <div>
                    <span>Lista de chamados</span>
                    <div className="filter-button">
                      <OverlayTrigger placement="top" overlay={this.state.filter ? filter_dis : filter}>
                        <Button style={{color:'#9305F2'}} simple type="button" onClick={this.advancedFilter} bsSize="xs">
                          <i className={this.state.filter ? "fa fa-filter filterticket" : "fa fa-filter filterticket-disabled"} />
                        </Button>
                      </OverlayTrigger>
                    </div>
                    <div className="table-buttons">
                      <NavLink to={ '/tickets/add/'}>
                        <button className="btnResetPassword" type="button" bsSize="xs">Novo Ticket</button>
                      </NavLink>
                      {/*this.state.rows.length > 0 ? 
                      <NavLink to={'#'}>
                        <ExcelExport ticket_data = {this.state.rows} requester = {window.location.pathname}/>
                      </NavLink> : ''*/}
                    </div>
                    <div className="box-prefilter">
                      <FormControl 
                        className="prefilter"
                        componentClass="select" 
                        name="prefilter" 
                        value={[this.getFilterValue("prefilter")]}
                        ref={element => this.prefilter = element}
                        onChange={event => {
                            this.onFilteredChangeCustom(event.target.value, "prefilter");
                        }}
                      >
                        <option value="">Todos</option>
                        <option value="Pendente Cliente">Pendente Cliente</option>
                        <option value="Em Andamento">Em Andamento</option>
                        <option value="Validação">Validação</option>
                        <option value="Aguardando implantação">Aguardando implantação</option>
                        <option value="Backlog Suporte">Backlog Suporte</option>
                      </FormControl>
                    </div>
                  </div>
                }
                content={
                  <ReactTable
                    ref={(instance) => { this.table = instance; }}
                    data={this.state.rows}
                    filtered={this.state.filtered}

                    getTrProps={(state, rowInfo, column, instance) => ({
                      onDoubleClick: e => window.location.href = 'tickets/view/' +rowInfo.original.code
                    })}


                    onFilteredChange={(filtered, column, value) => {
                      this.onFilteredChangeCustom(value, column.id || column.accessor);
                    }}
                    columns={[
                      {
                        Header: "",
                        accessor: "severity",
                        className: "center",
                        sortable: false,
                        filterable: this.state.filter ? true : false,
                        Filter: ({ filter, onChange }) => {
                          return (
                            <ListOfVal 
                              all={true}
                              name="severity" 
                              reference="severity" 
                              type="ADJUST_PRIORITY"
                              value={[this.getFilterValue("severity")]}
                              onChange={event => {
                                let selectedOptions = [].slice
                                  .call(event.target.selectedOptions)
                                  .map(o => {
                                    return o.value;
                                  });

                                  this.onFilteredChangeCustom(selectedOptions, "severity");
                              }}
                            />
                          );
                        }
                      },
                      /*
                      {
                        Header: "Severidade",
                        accessor: "severity",
                        className: "center"
                      },
                      */
                      {
                        Header: "Cód. Ticket",
                        accessor: "code",
                        filterable: this.state.filter ? true : false
                      },
                      {
                        Header: "Título",
                        accessor: "title",
                        filterable: this.state.filter ? true : false
                      },
                      {
                        Header: "Solicitante",
                        accessor: "solicitant",
                        filterable: this.state.filter ? true : false
                      },
                      {
                        Header: "Tipo",
                        accessor: "type",
                        filterable: this.state.filter ? true : false
                      },
                    /* {
                        Header: "Responsável",
                        accessor: "resp",
                        filterable: this.state.filter ? true : false
                      },*/
                      {
                        Header: "Data",
                        accessor: "date",
                        filterable: this.state.filter ? true : false,
                        Filter: ({ filter, onChange }) => {
                          return (
                            <Datetime
                              locale="pt-br"
                              timeFormat={false}
                              closeOnSelect={true}
                              inputProps={{ placeholder: "", className: "form-control" }}
                              onChange={event => {
                                let value = '';

                                if (event.format && {}.toString.call(event.format) === '[object Function]')
                                  value  = event.format('DD/MM/YYYY')

                                this.onFilteredChangeCustom(value, "date")
                              }}
                              ref={el => this.onDatepickerRef(el)} 
                            />
                          );
                        }
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        filterable: this.state.filter ? true : false
                      },
                      {
                        /*Header: (
                          <OverlayTrigger placement="top" overlay={this.state.filter ? filter_dis : filter}>
                            <Button bsStyle={this.state.filter ? "info" : ""} simple type="button" onClick={this.advancedFilter} bsSize="xs">
                              <i className={this.state.filter ? "fa fa-filter filterticket" : "fa fa-filter filterticket-disabled"} />
                            </Button>
                          </OverlayTrigger>
                          <NavLink to={ '/tickets/add/'}>
                            <OverlayTrigger placement="top" overlay={add}>
                              <Button bsStyle="info" simple type="button" bsSize="xs">
                                <i className="fa fa-plus-circle newticket" title="Novo Ticket" />
                              </Button>
                            </OverlayTrigger>
                          </NavLink>
                        ),*/
                        accessor: "actions",
                        className: "actions-right",
                        sortable: false
                      }
                    ]}
                    resized={[
                      {"id": "severity", "value": 80},
                      {"id": "code"    },
                      {"id": "title", "value": 330},
                      {"id": "solicitant"} ,
                      {"id": "tipo"    },
                      //{"id": "resp",    },
                      {"id": "date"    },
                      {"id": "status"  },
                      {"id": "actions"} 
                    ]}
                    pages={this.state.pages}
                    minRows={10}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próxima"
                    loadingText={<img src={loading} className="loading" alt="Carregando.." />}
                    noDataText={this.state.loading ? "" : (this.state.error ? this.state.error : "Sem registros encontrados")}
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    loading={this.state.loading}
                    manual // this would indicate that server side pagination has been enabled 

                    onFetchData={(state, instance) => {
                      this.setState({
                        loading: true
                      });
                      this.getTickets(state.page, state.pageSize, state.sorted, state.filtered)
                    }}
                  />
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Tickets;
