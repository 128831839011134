import React, { Component } from "react";

import { Grid, Form } from "react-bootstrap";
import { login }      from "services/auth";

import Card       from "components/Card/Card.jsx";
/* import logoLogin  from "assets/img/accLogoWhite.png"; */
import logoLogin  from "assets/img/logoAccenture.svg";
import api        from "services/api";

import "assets/login/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "assets/login/fonts/iconic/css/material-design-iconic-font.min.css";
import "assets/login/vendor/animate/animate.css";
import "assets/login/vendor/css-hamburgers/hamburgers.min.css";
import "assets/login/vendor/animsition/css/animsition.min.css";
import "assets/login/vendor/select2/select2.min.css";
import "assets/login/vendor/daterangepicker/daterangepicker.css";
import "assets/login/css/util.css";
import "assets/login/css/main.css";

import loading_icon from "assets/img/loading.gif";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cardHidden: true,

      username  : "",
      password  : "",
      error     : "",

      loading   : false,
      remember  : false
    };

    sessionStorage.clear()
  }

  getLogo() {
    return (
      <div>{/* <img src={logoLogin} width={100} height={100} alt="Login" /> */}<div className="title-portal">Accenture <span className="songTitle">Song</span></div></div>
    )
  }

  callSSO = async e => {
    e.preventDefault();
    this.setState({ error: "", loading: true });
     const response = await api.post(
        "./www/index.php"
      );
     window.location.href = response.request.responseURL;
  };

  componentDidMount() {
    sessionStorage.clear()

    if (localStorage.getItem('remember')) {
      this.setState({ username: localStorage.getItem('remember'), remember: true });
    }

    setTimeout(
      function() {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
    const searchParams = new URLSearchParams(window.location.search);
    const message = searchParams.get('message');
    const jwt = searchParams.get('jwt');
    const expireAt = searchParams.get('expireAt');
    const posid = searchParams.get('posid');
    const name = searchParams.get('name');
    const company = searchParams.get('company');
    const gender = searchParams.get('gender');
    const resp = searchParams.get('resp');
    const username = this.state.username;
    const password = this.state.password;
    const remember = this.state.remember;
    if (message == 'Successful login.') {
    	  login(
    		  jwt, 
    		  remember, 
    		  username, 
    		  posid, 
    		  name, 
    		  company, 
    		  gender,
    		  resp
    	  )
    	  this.props.history.push("/dashboard")
    }
  }

  handleSignIn = async e => {
    e.preventDefault();

    const username = this.state.username;
    const password = this.state.password;
    const remember = this.state.remember;

    if (username === '' || password === '') {
      this.setState({ error: "É obrigatório o preenchimento de e-mail e senha.", loading : false });
    } else {
      this.setState({ error: "", loading: true });

      try {
        const response = await api.post("/login.php", { username, password });

        if (response.data == 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida.", loading : false });
        } else if (response.data == 'User Blocked') {
          this.setState({ error: "Usuário bloqueado. Por favor, entre em contato com a administração.", loading : false });
        } else if (response.data == 'User Blocked Password Expired') {
          this.setState({ error: "Usuário bloqueado por conta de senha expirada. Por favor, entre em contato com a administração.", loading : false });
        } else if (response.data.message == 'Successful login.') {
          login(
            response.data.jwt, 
            remember, 
            username, 
            response.data.posid, 
            response.data.name, 
            response.data.company, 
            response.data.gender,
            response.data.resp
          )

          this.setState({ loading : false });
          this.props.history.push("/dashboard")
        } else if(response.data.action && response.data.action === 'register_mfa'){
          const routeState = {
            mfaSecretData: response.data.mfaSecretData,
            otpString: response.data.otp_string,
            username: username
          };
          this.props.history.push("/authenticator-page", routeState)
        } else if(response.data.action && response.data.action === 'validate_mfa'){
          const routeState = {
            otpString: response.data.otp_string,
            username: username
          };
          this.props.history.push("/validation-page", routeState)
        } else if(response.data.action && response.data.action === 'new_password') {
          const routeState = {
            otpString: response.data.otp_string,
            username: username
          };
          this.props.history.push("/new-password-page", routeState)
        }

        else {
          this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
        }

      } catch (err) {
        this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
      }
    }
  };

  toggleCheckboxChange = (e) => {
    if (e.target.type === 'checkbox') {
      if (e.target.checked) {
        this.setState({ remember : true })
      } else {
        this.setState({ remember : false })
      }
    }
  }

  render() {
    return (
      <Grid>
          <Card
            hidden={this.state.cardHidden}
            textCenter
            title={this.getLogo()}
            customClass="wrap-login100"
            content={
              <div>
                <Form onSubmit={this.handleSignIn} className="login100-form validate-form">

                  <div className="wrap-input100 validate-input">
                    <input className="input100" type="text" name="username" placeholder="Usuário" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false" onChange={e => this.setState({ username: e.target.value, error : "" })} value={this.state.username} />
                    <span className="focus-input100" data-placeholder="&#xf207;"></span>
                  </div>

                  <div className="wrap-input100 validate-input">
                    <input className="input100" type="password" name="pass" placeholder="Senha" onChange={e => this.setState({ password: e.target.value, error : "" })} />
                    <span className="focus-input100" data-placeholder="&#xf191;"></span>
                  </div>

                  <div className="contact100-form-checkbox">
                    <input className="input-checkbox100" id="ckb1" type="checkbox" name="remember-me" onChange={this.toggleCheckboxChange} checked={this.state.remember} />
                    <label className="label-checkbox100" htmlFor="ckb1" style={{display: "inline-block", float: "left"}}>Lembrar usuário</label>
                  </div>

                  <div className="login-error">{this.state.error}</div>

                  <div className="container-login100-form-btn">
                      <button type="submit" className="btnLogin">{this.state.loading ? <img src={loading_icon} className="loading-login" /> : ''} Login</button>
                  </div>
                </Form>

                <div className="container-login100-form-btn">
                      <button onClick={this.callSSO} className="btnLogin">{this.state.loading ? <img src={loading_icon} className="loading-login" /> : ''} Funcionário Accenture</button>
                  </div>
              </div>
            }
            ftTextCenter
          />
      </Grid>
    );
  }
}

export default LoginPage;
