import React, { Component } from "react";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Announcement   from '@material-ui/icons/Announcement.js';
import AssignmentInd  from '@material-ui/icons/AssignmentInd.js';
import Build          from '@material-ui/icons/Build.js';
import Done           from '@material-ui/icons/Done.js';
import DoneAll        from '@material-ui/icons/DoneAll.js';
import CheckCircle    from '@material-ui/icons/CheckCircle.js';
import VerifiedUser   from '@material-ui/icons/VerifiedUser.js';
import StarIcon       from '@material-ui/icons/Star.js';
import Warning        from '@material-ui/icons/Warning.js';
import Pause          from '@material-ui/icons/Pause.js';
import Error          from '@material-ui/icons/Error.js';
import Cancel         from '@material-ui/icons/Cancel.js';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer.js';
import Help           from '@material-ui/icons/Help.js';
import CheckBox       from '@material-ui/icons/CheckBox.js';
import Input          from '@material-ui/icons/Input.js';
import AccessTime     from '@material-ui/icons/AccessTime.js';

class Timeline extends Component{
    constructor(props){
        super(props);
        this.state = {
            initial	    : '',
            created_by  : '',
            created		: '',
            historic    : ''
        };
    }

    componentDidMount(){

        this.setState({
            initial	    : this.props.st_inicial,
            created_by  : this.props.c_by,
            created		  : this.props.created,
            historic    : this.props.hist
        });
        
    }

    getActionResp = function(status) {
        switch (status) {
    
          case 'Aberto'                 :
          case 'Validado'               :
          case 'Pendente Informação'    :
          case 'Parado'                 :
          case 'Homologação aprovada'   :
          case 'Homologação reprovada'  :
          case 'Reprovado'              :
          case 'Cancelado'              :
          case 'Finalizado'             : { return ('extern')}
    
          case 'Pendente fornecedor'    :
          case 'Melhoria Pendente'      :
          case 'Pendente fornecedor'    :
          case 'Atribuído'              :
          case 'Em desenvolvimento'     :
          case 'Em Homologação'         :
          case 'Pendente aprovação'     : { return ('intern')}
    
          default: {return (<AssignmentInd />) }
        }
      }
      
      getIcon = function(status) {
        switch (status) {
    
          case 'Aberto'                 : { return (<Announcement />)}
          case 'Pendente Cliente'      : { return (<QuestionAnswer />)}
          //case 'Validado'               : { return (<CheckBox />)}
          //case 'Atribuído'              : { return (<AssignmentInd />)}
          case 'Em Andamento'     : { return (<Build />)}
          //case 'Pendente fornecedor'    : { return (<Input />)}
          //case 'Pendente Informação'    : { return (<Help />)}
          //case 'Parado'                 : { return (<Pause />)}
          case 'Validação'         : { return (<Done />)}
          case 'Aguardando Implantação': {return (<AccessTime/>)}
          //case 'Homologação aprovada'   : { return (<DoneAll />)}
          //case 'Homologação reprovada'  : { return (<Error />)}
          //case 'Pendente aprovação'     : { return (<CheckCircle />)}
          //case 'Reprovado'              : { return (<Warning />)}
          //case 'Cancelado'              : { return (<Cancel />)}
          case 'Finalizado'             : { return (<VerifiedUser />)}
    
          default: {return (<AssignmentInd />) }
        }
      }

    render(){
        return(
            <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--extern"
                  date={this.state.created}
                  iconStyle={{ background: 'rgb(33, 150, 243)', color: '#eee' }}
                  icon={<Announcement />}
                >
                    <div>
                      <span className="vertical-timeline-element-title">{this.state.initial}</span>
                      <span className="vertical-timeline-element-subtitle">{this.state.created_by}</span>
                    </div>
                </VerticalTimelineElement>
                
                {this.state.historic.length > 0 ?

                    <div>
                      {this.state.historic.map((item, key) => (
                        <VerticalTimelineElement
                          position={key % 2 === 0 || key == 0 ? "right" : "left"}
                          className={"vertical-timeline-element--" + this.getActionResp(item.final)}
                          date={item.last_updated}
                          iconStyle={{ 
                            background: (this.getActionResp(item.final) == 'intern' ? 'rgb(33, 150, 243)' : 'rgb(177, 169, 171)'), 
                            color: '#eee' }}
                          icon={this.getIcon(item.final)}
                          >
                          <div>
                            <span className="vertical-timeline-element-title">{item.final}</span>
                            <span className="vertical-timeline-element-subtitle">{item.last_updated_by}</span>
                          </div>
                        </VerticalTimelineElement>
                      ))}    
                    </div>

                  : ''}

                  <VerticalTimelineElement
                    iconStyle={{ background: 'rgb(58, 224, 116)', color: '#eee' }}
                    icon={<StarIcon />}
                  />
            </VerticalTimeline>
        
       
        );
    }
}

export default Timeline;