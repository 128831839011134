import api from './api';
import event from './events';

var notificationTimer = null;
var timeout = 1 * 60 * 1000;

export async function getNotification(resetInterval=true) {
    if(notificationTimer !== null){ clearNotificationInterval()}

    const response = await api.post('/notification.php', {
        method: 'get'
    })
    if (response.data) {
        localStorage.setItem('notification', JSON.stringify(response.data))
        notificationEvent.dispatchEvent('notification received')
    }
    if(resetInterval){
        setNotificationInterval()
    }
}


export function setNotificationInterval() {
    if(notificationTimer !== null){ clearNotificationInterval()}
    notificationTimer = setTimeout(getNotification, 300000)
    console.log('setNotificationInterval')
}

export function clearNotificationInterval() {
    clearTimeout(notificationTimer)
    notificationTimer = null
}


export const notificationEvent = new event();

notificationEvent.registerEvent('notification received')


/* notificationEvent.addEventListener('notification received', function(){
    const local = localStorage.getItem('notification')
    console.log('notification received' )
    console.log(JSON.parse(local))
}) */
//setNotificationInterval()
//getNotification()
