import Pages from "layouts/Pages/Pages.jsx";

var loginRoute = [
  { path: "/login", name: "Login", component: Pages },
  { path: "/authenticator-page", name: "AuthenticatorPage", component: Pages },
  { path: "/validation-page", name: "ValidationPage", component: Pages },
  { path: "/reset-mfa-page", name: "Reset MFA Page", component: Pages },
  { path: "/reset-password-page", name: "ResetPasswordAcc", component: Pages },
  { path: "/new-password-page", name: "NewPassword", component: Pages }
];


export default loginRoute;
