import { setNotificationInterval, clearNotificationInterval } from './notifications'
export const TOKEN_KEY = "portal_suporte_token";

export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

export const isAuthenticatedResp = (resp) => (!resp || sessionStorage.getItem("resp").split(',').indexOf(resp) !== -1);

export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

export const login = (token, remember, username, postn, name, company, gender, resp) => {
  sessionStorage.setItem(TOKEN_KEY, token);

  sessionStorage.setItem('username', username);
  sessionStorage.setItem('name', name);
  sessionStorage.setItem('company', company);
  sessionStorage.setItem('postn', postn);
  sessionStorage.setItem('gender', gender);

  sessionStorage.setItem('resp', resp);
  

  if (remember) {
    localStorage.setItem('remember', username)
  } else {
    localStorage.removeItem('remember')
  }
};

export const logout = () => {
  sessionStorage.clear()
  clearNotificationInterval()
  window.location.href = '/login'
};