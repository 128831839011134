import React, { Component } from "react";

import { Grid, Row, Col, FormControl, FormGroup, Collapse } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Board from 'react-trello'
import { notificationEvent, getNotification } from '../../services/notifications'

import loading from "assets/img/loading.gif";
import Multiselect from 'multiselect-react-dropdown';
import api from "services/api";
import Select from "react-select";

import 'moment/locale/pt-br.js';

class BoardPage extends Component {

  constructor(props) {
    super(props);
    sessionStorage.setItem('previous-page', window.location.pathname.replace(/^\/|\/$/g, ''));
    this.state = {
      lanes: [],
      value: '',
      alert: null,
      error: false,
      loading: true,
      isOpen: true,
      ticketNotify: this.getNotificationsTicketsIds(),
      //admin          : false,
      resp: sessionStorage.getItem("resp").split(','),
      orgs: [],
      qtd_rows: [{ value: 5, label: '5 registros' },
      { value: 10, label: '10 registros' },
      { value: 20, label: '20 registros' },
      { value: 25, label: '25 registros' },
      { value: 50, label: '50 registros' },
      { value: 100, label: '100 registros' }],
      selected_limit: this.getFilterLimitValue() ? this.getFilterLimitValue() : 5,
      type_lst         : [],
      //severity_lst     : [],
      squads: [],
      marked_squads: this.getSquadFilter(),
      selectedorg: this.getFilterValue('org') ? this.getFilterValue('org') : '',
      selectedtype     : this.getFilterValue('type')     ? this.getFilterValue('type')     : '' ,
      //selectedseverity : this.getFilterValue('severity') ? this.getFilterValue('severity') : '' ,
      filtered: this.getFilters(),

    }

  }

  componentDidMount() {
    this.getLists();
    this.getBoard();
    notificationEvent.addEventListener('notification received', this.notificationReceived)
  }

  hideAlert() {
    this.setState({
      alert: null,
      loading: false
    });
  }

  


  getLists = async () => {

    try {
      await api.post("/board.php", {
        method: 'getListsOfValue',
        squads: this.state.marked_squads
      }).then((response) => {

        if (response.data === 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida." });
        }
        else {
          this.setState({
            orgs: response.data.orgs,
            squads: response.data.squads,
            type_lst     : response.data.type_list
            //severity_lst : response.data.severity_list
          });
        }

      });
    }
    catch (err) {
      console.log(err);
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
    }
  }

  getNotificationsTicketsIds = () => {
    const localNotification = JSON.parse(localStorage.getItem('notification'))
    const tickets = localNotification.map((ticketNotification) => ticketNotification.ticket_id)
    const uniqueTickets = [... new Set(tickets)]

    return uniqueTickets
  }

  notificationReceived = () => {
    const uniqueTickets = this.getNotificationsTicketsIds()

    if (JSON.stringify(uniqueTickets) !== JSON.stringify(this.state.ticketNotify)) {

      this.setState({
        lanes: this.getNotificationLanes(this.state.lanes, uniqueTickets),
        ticketNotify: uniqueTickets
      })
    }
  }

  getNotificationLanes = (lanes, uniqueTickets) => {
    let newLanes = []
   lanes.forEach(lane => {
      let newCards = [];
      lane.cards.forEach(card => {
        if (uniqueTickets.includes(card.id)) {
          card.className = 'board-card-notification'
        }
        card.tags.forEach(tag => {
            if (tag.title == "Tipo: Melhoria"){
            tag.bgcolor = '#1d2ff2' 
            }
        })
        newCards.push(card)
      })
      lane.cards = newCards
      newLanes.push(lane)
    })
    console.log(newLanes)
    return newLanes
  }
  
  getBoard = /*async*/() => {

    try {
      const postData = {
        method: 'getAvStatus',
        resp: this.state.resp,
        filtered: this.state.filtered,
        squads: this.state.marked_squads,
        types: this.state.type,
        limit: this.state.selected_limit,
      }

      if(this.state.resp.indexOf('4-6-c4f4') === -1) {
        postData.postn = sessionStorage.getItem('postn');
      } 

      api.post("/board.php", postData).then((response_status) => {
        
        if (response_status.data === 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida." });
        }
        else {
          if(response_status.data.av_status !== undefined) {
            response_status.data.av_status.lanes = this.getNotificationLanes(response_status.data.av_status.lanes, this.state.ticketNotify)
            
          }
          this.setState({
            loading: false,
            lanes:
              response_status.data.av_status !== undefined ?
                response_status.data.av_status.lanes.map((row) => {
                  return {
                    id: row['id'],
                    title: row['title'],
                    cards: row['cards']
                  }
                }) : []
            //admin : response_status.data.adm
          });
        }
      });

    }
    catch (err) {
      console.log(err);
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
    }

  }

  getFilters() {
    let filters = sessionStorage.getItem('board-filters');

    if (filters) {
      return JSON.parse(filters);
    }
    else {
      return []
    }
  }

  getLimitFilters() {
    let filters = sessionStorage.getItem('limit-filter');
    if (filters) {
      return filters;
    }
    else {
      return ''
    }
  }

  getSquadFilter() {
    let squad_array = sessionStorage.getItem('selected-squads');
    if (squad_array) {
      return JSON.parse(squad_array);
    }
    else {
      return []
    }
  }

  getFilterValue(id) {
    let filters = this.getFilters();
    let value;
    if (!filters.length) {
      return null;
    }
    filters.forEach((i) => {
      if (i.id === id) {
        value = i.value
      }
    });
    return value;
  }

  getFilterLimitValue() {
    let filters = this.getLimitFilters();

    return filters;
  }

  handleUpdateStatus = (id, newstatus) => {
    this.setState({
      loading: true,
      alert: null
    });

    api.post("/tickets.php", {
      method: 'update_status_board_flow',
      rowid: id,
      status: newstatus

    }).then((response) => {
      if (response.data === 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        response.data.status === 'ok' ?

          this.setState({
            //loading   : false,
            status: newstatus
          }) :

          this.setState({
            loading: false,
            alert: (
              <SweetAlert
                style={{ display: "block", marginTop: "-240px", fontSize: "12px" }}
                warning
                title=""
                onConfirm={() => this.hideAlert()}>{response.data.msg}</SweetAlert>
            )
          });
        this.getBoard();
        getNotification();
      }
    })
  }

  handleUpdateStatus_backlog = (id, newstatus, ref_flg) => {
    this.setState({
      loading: true,
      alert: null
    });

    api.post("/tickets.php", {
      method: 'update_status_board_flow',
      rowid: id,
      status: newstatus,
      refined_flag: ref_flg

    }).then((response) => {
      if (response.data === 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        response.data.status === 'ok' ?

          this.setState({
            //loading   : false,
            status: newstatus
          }) :

          this.setState({
            loading: false,
            alert: (
              <SweetAlert
                style={{ display: "block", marginTop: "-240px", fontSize: "12px" }}
                warning
                title=""
                onConfirm={() => this.hideAlert()}>{response.data.msg}</SweetAlert>
            )
          });
        this.getBoard();

      }
    })
  }

  handleUpdateStatus_finalizado = (id, newstatus, replicated) => {
    this.setState({
      loading: true,
      alert: null
    });

    api.post("/tickets.php", {
      method: 'update_status_board_flow',
      rowid: id,
      status: newstatus,
      replicated: replicated

    }).then((response) => {
      if (response.data === 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        response.data.status === 'ok' ?

          this.setState({
            //loading   : false,
            status: newstatus
          }) :

          this.setState({
            loading: false,
            alert: (
              <SweetAlert
                style={{ display: "block", marginTop: "-240px", fontSize: "12px" }}
                warning
                title=""
                onConfirm={() => this.hideAlert()}>{response.data.msg}</SweetAlert>
            )
          });
        this.getBoard();

      }
    })
  }

  updateTkStatus = (cardId, sourceLaneId, targetLaneId) => {

    if (sourceLaneId !== targetLaneId) {

      if (targetLaneId === 'Backlog') {

        this.setState({
          loading: true,
          alert: (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-240px" }}
              showCancel
              confirmBtnText="Sim"
              cancelBtnText="Não"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              title={<span className="spanAlert">O ticket foi refinado?</span>}
              onConfirm={() => this.handleUpdateStatus_backlog(cardId, targetLaneId, 'Y')}
              onCancel={() => this.hideAlert()}
            >
            </SweetAlert>
          )
        })

      } else if ((sourceLaneId === 'Pendente Cliente' || sourceLaneId === 'Em Andamento' || sourceLaneId === 'Aguardando implantação') && targetLaneId === 'Finalizado') {

        this.setState({
          loading: true,
          alert: (
            <SweetAlert
              warning
              style={{ display: "block", marginTop: "-240px" }}
              showCancel
              confirmBtnText="Sim"
              cancelBtnText="Não"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              title={<span className="spanAlert">Replicado em produção?</span>}
              onConfirm={() => this.handleUpdateStatus_finalizado(cardId, targetLaneId, 'Y')}
              onCancel={() => this.handleUpdateStatus_finalizado(cardId, targetLaneId, 'N')}
            >
            </SweetAlert>
          )
        })

      } else {
        this.handleUpdateStatus(cardId, targetLaneId);
      }

    }
  }

  onChangeFilter = (field_name, value, label) => {
    let filtered = this.state.filtered;
    let insertNewFilter = 1;

    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === field_name) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else {
            filter["value"] = value;
            filter["label"] = label;
          }
          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: field_name, value: value, label: label });
    }

    this.setState({ loading: true, filtered: filtered });
    sessionStorage.setItem('board-filters', JSON.stringify(filtered));
    this.getBoard();

  }
  


  render() {
    var data = {};
    data['lanes'] = this.state.lanes;
    
    return (
      <div className='main-content'>
        {this.state.alert}
        <div className={this.state.loading ? "loading-form" : "fadeOut loading-form"}>
          <img src={loading} alt="Carregando.." />
        </div>
        <Grid fluid>
          <Row>
            <Col md={6}>
              <a onClick={() => this.setState({ isOpen: !this.state.isOpen })}>
                <span style={{ color: '#9305F2' }}>
                  Filtros
                  <b className={this.state.isOpen ? "caret rotate-180" : "caret"} />
                </span>
              </a>
            </Col>
          </Row>
          <Row>
            <Collapse in={this.state.isOpen}>
              <div>

                <Col md={2}>
                  <FormGroup>
                    <FormControl
                      name="responsible"
                      type="text"
                      placeholder="Responsável"
                      ref="responsible"
                      value={[this.getFilterValue("responsible")]}
                      onChange={e => this.onChangeFilter(e.target.name, e.target.value, e.target.value)}
                      readOnly={false}
                    />
                  </FormGroup>
                </Col>

                {/*<Col md={2}>
                  <FormGroup>
                    <FormControl
                      name="limit" 
                      type="text" 
                      placeholder="Limite (máximo de TKs por coluna)" 
                      ref="limit" 
                      value = {[this.getFilterValue("limit")]}
                      onChange={e =>this.onChangeFilter(e.target.name, e.target.value, e.target.value)}
                      readOnly={false}
                    />
                  </FormGroup>
                </Col>*/}

                {this.state.resp.indexOf('4-6-c4f4') !== -1 ?
                  <div>
                    <Col md={2}>
                      <Multiselect
                        id="css_custom"
                        name='squads'
                        style={{
                          searchBox: {
                            border: "none",
                            background: "white",
                            borderRadius: "4px",
                            borderStyle: "solid",
                            borderColor: "#ccc",
                            borderWidth: "1px"
                          },
                          multiselectContainer: {
                            color: "#aaa"
                          }
                        }
                        }

                        showCheckbox={true}
                        options={this.state.squads}
                        displayValue="label"
                        selectedValues={this.state.marked_squads}
                        onSelect={e => {
                          if (e !== null) {
                            sessionStorage.setItem('selected-squads', JSON.stringify(e));
                            this.setState({
                              loading: true,
                              marked_squads: e
                            },
                              () => {
                                this.getBoard()
                                this.getLists()
                              });
                          }
                        }}

                        onRemove={e => {
                          sessionStorage.setItem('selected-squads', JSON.stringify(e));
                          this.setState({
                            loading: true,
                            marked_squads: e
                          },
                            () => {
                              this.getBoard()
                              this.getLists()
                            });
                        }}

                        placeholder="Squad"
                      />
                    </Col>
                    <Col md={2}>
                       <Select
                    placeholder="Tipo"
                    name="type"
                    options={this.state.type_lst}
                    value = {this.state.selectedtype}
                    onChange={e =>{
                                this.setState({selectedtype : e !== null ? e.value : ''})
                                if(e !== null)
                                  this.onChangeFilter("type", e.value, e.label)
                                else
                                  this.onChangeFilter("type", '', '')

                              }}
                  />

                    </Col>

                    <Col md={2}>
                      <Select
                        placeholder="Organização"
                        name="org"
                        options={this.state.orgs}
                        value={this.state.selectedorg}
                        onChange={e => {
                          this.setState({ selectedorg: e !== null ? e.value : '' })
                          if (e !== null) {
                            this.onChangeFilter("org", e.value, e.label)
                          }
                          else
                            this.onChangeFilter("org", '', '')

                        }}
                      />
                    </Col>

                  </div> : ''
                }
                <Col md={2}>
                  <Select
                    placeholder="Limite por coluna"
                    name="limit"
                    options={this.state.qtd_rows}
                    value={this.state.selected_limit}
                    onChange={e => {
                      if (e !== null) {
                        sessionStorage.setItem('limit-filter', JSON.stringify(e.value));
                        this.setState({
                          loading: true,
                          selected_limit: e.value,
                        }, () => { this.getBoard() });
                      }
                    }}
                  />
                </Col>

                {/*<Col md={2}>
                  <Select
                    placeholder="Tipo"
                    name="type"
                    options={this.state.type_lst}
                    value = {this.state.selectedtype}
                    onChange={e =>{
                                this.setState({selectedtype : e !== null ? e.value : ''})
                                if(e !== null)
                                  this.onChangeFilter("type", e.value, e.label)
                                else
                                  this.onChangeFilter("type", '', '')
                                  
                              }}
                  />
                </Col

                <Col md={2}>
                  <Select
                    placeholder="Severidade"
                    name="severity"
                    options={this.state.severity_lst}
                    value={this.state.selectedseverity}
                    onChange={e => {
                                this.setState({selectedseverity : e !== null? e.value : ''})
                                if(e !== null)
                                  this.onChangeFilter("severity", e.value, e.label)
                                else  
                                  this.onChangeFilter("severity", '', '')
                                  
                              }}
                  />
                </Col>*/}
              </div>

            </Collapse>
          </Row>

          <Row>
            {!this.state.loading ?
              <Col md={12}>

                {this.state.lanes.length > 0 ?
                  <>
                    <Board
                      cardDragClass="draggingCard"
                      cardDraggable={this.state.resp.indexOf('4-6-c4f4') !== -1 ? true : false}
                      collapsibleLanes
                      style={{
                        backgroundColor: 'transparent',
                        overflow: 'auto',
                        height: 'auto',
                        maxHeight: '80vh'
                      }}

                      laneStyle={{
                        maxHeight: '72vh',
                        //width: '250px'
                      }}

                      //cardStyle ={{ width: '150px' }}

                      onCardClick={(cardId, metadata, laneId) =>
                        window.location.href = this.state.resp.indexOf('4-6-c4f4') !== -1 ? '/admtickets/view/' + metadata.tk_code
                          : '/tickets/view/' + metadata.tk_code}
                      handleDragEnd={
                        (cardId, sourceLaneId, targetLaneId, position, cardDetails) => {
                          this.updateTkStatus(cardId, sourceLaneId, targetLaneId, position, cardDetails);
                        }
                      }
                      hideCardDeleteIcon
                      data={data}
                    />

                  </>
                  : <p>Ocorreu um erro na montagem do board :</p>}

              </Col> : ''
            }
          </Row>
        </Grid>

      </div>
    );
  }
}

export default BoardPage;
