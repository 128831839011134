export const impact_texts = [
    'Extremamente graves, que podem até se tornar irreversíveis',
    'Grandes impactos, porém reversíveis',
    'Médio impacto (danos regulares)',
    'Baixo impacto (danos mínimos)'
];

export const urgency_texts = [
    'Extremamente urgente, não pode esperar e precisa ser resolvido de imediato',
    'Urgente, porém pode esperar ser atendido conforme priorização',
    'Não urgente, pode esperar, não há pressa em resolver o problema'
];

export const impact_indexes = {
    1 : 'gravissimo',
    2 : 'grave',
    3 : 'medio',
    4 : 'baixo'
}

export const urgency_indexes = {
    1 : 'urgentissimo',
    2 : 'urgente',
    3 : 'nao_urg'     
}

export const define_priority = {

    gravissimo : 
    {
        'urgentissimo' : '04-Critical',
        'urgente'      : '03-Very High',
        'nao_urg'      : '02-High'
    },
    
    grave :
    {
        'urgentissimo' : '03-Very High',
        'urgente'      : '02-High' ,
        'nao_urg'      : '02-High'
    },
    
    medio : 
    {
        'urgentissimo' : '03-Very High',
        'urgente'      : '02-High',
        'nao_urg'      : '01-Normal'
    },

    baixo : 
    {
        "urgentissimo" : '02-High', 
        "urgente"	   : '01-Normal',
        "nao_urg"	   : '01-Normal'
    }

}
