import Dashboard from "layouts/Dashboard/Dashboard.jsx";

import Default from "layouts/Default/Default.jsx";

var indexRoutes = [
  { path: "/admtickets",                      name: "AdmTickets",   resp: '4-6-c4f4', component: Default },
  { path: "/admtickets/view/:handle",         name: "View",         resp: '4-6-c4f4', component: Default },
  { path: "/admtickets/tr/:handle",           name: "TR",           resp: '4-6-c4f4', component: Default },
  { path: "/admtickets/files/:handle",        name: "Files",        resp: '4-6-c4f4', component: Default },
  { path: "/admtickets/hist/:handle",         name: "Hist",         resp: '4-6-c4f4', component: Default },
  { path: "/admtickets/interactions/:handle", name: "Interactions", resp: '4-6-c4f4', component: Default },

  { path: "/tickets/add",                   name: "Add",          component: Default },
  { path: "/tickets/view/:handle",          name: "View",         component: Default },
  { path: "/tickets/files/:handle",         name: "Files",        component: Default },
  { path: "/tickets/hist/:handle",          name: "Hist",         component: Default },
  { path: "/tickets/interactions/:handle",  name: "Interactions", component: Default },

  { path: "/user", name: "User", component: Default },
  { path: "/changePassword", name: "ChangePassPage", component: Default },
  { path: "/favoritos", name: "FavPage", component: Default  },
  { path: "/board-view", name: "Board", component: Default  },
  { path: "/BI", name: "PowerBI", component: Default  },
  { path: "/", name: "Home", component: Dashboard }
];

export default indexRoutes;
