import React, { Component } from "react";

import Card from "components/Card/Card.jsx";

import { Grid, Row, Col } from "react-bootstrap";
import { getNotification } from '../../../services/notifications'

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import TabForm from "../../../components/SharedForm/TabForm";
import Message from "../../../components/Message/Message";

import 'react-chat-widget/lib/styles.css';

import logo from "assets/img/default-avatar-2.png";
import logo2 from "assets/img/default-avatar.png";
import logo3 from "assets/img/default-avatar-un.png";

import send from "assets/img/send.png";
import cancel_edit from "assets/img/cancel-edit.png";

import SweetAlert from "react-bootstrap-sweetalert";

import { Redirect } from "react-router-dom";

import api from "services/api";
import loading from "assets/img/loading.gif";

class Interactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adm: (window.location.href.indexOf("adm") > -1 ? true : false),
      url: (window.location.href.indexOf("adm") > -1 ? 'admtickets' : 'tickets'),

      loading: true,
      redirect: false,
      error: false,

      rowid: '',
      code: '',
      title_text: '',
      status: '',
      priority: '',

      value: '',
      messages: [],
      statuses: [],
      /*messages  : [
        {rowid : '1', logo : logo,   message : 'Qualquer dúvida, estou à disposição',                    respname : 'João de Souza',   datetime : new Date('2019-04-10 09:30'), user : false },
        {rowid : '2', logo : '',     message : 'Favor detalhar o cenário para reprodução do problema.',  respname : '',                datetime : new Date('2019-04-10 10:01'), user : true  },
        {rowid : '3', logo : logo2,  message : 'Anexado arquivo com as evidências encontradas.',         respname : 'Maria da Silva',  datetime : new Date('2019-04-11 14:25'), user : false },
        {rowid : '4', logo : '',     message : 'Obrigado!',                                              respname : '',                datetime : new Date('2019-04-11 16:47'), user : true  }
      ],*/

      edit: false,
      rowid: false,
      alert: null
    }
  }

  componentDidMount() {
    if (!this.state.loading)
      this.inputMsg.focus();

    this.preLoadContent()
  }

  preLoadContent = async () => {
    try {
      const response = await api.post("/tickets.php", { method: 'interactions', code: this.props.match.params.handle });

      if (response.data == 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        api.post("/tickets.php", {
          method: 'getStatuses',
        }).then((response) => {
          if (response.data === 'Validation Error') {
            this.setState({ error: "Usuário e/ou senha inválida." });

          }
          else {
            this.setState({
              statuses: response.data.status_list
            });
          }
        });

        this.setState({

          rowid: response.data.rowid,

          code: response.data.code,
          title_text: this.text_truncate(response.data.subject, 90, '...'),
          status: response.data.status,
          priority: response.data.priority,

          messages: response.data.interactions.map((item) => {
            return {
              rowid: item.rowid,
              logo: (item.gender == 'Feminino' ? logo : (item.gender == 'Masculino' ? logo2 : logo3)),
              message: item.message,
              respname: (item.resp_user ? '' : item.created_by),
              datetime: new Date(item.last_upd),
              user: item.resp_user,
              edit: item.edit
            }
          }),

          loading: false
        });
        getNotification()
      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
    }
  }

  text_truncate = function (str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  onChangeValue = event => {
    this.setState({ value: event.target.value });
  };

  onAddItem = (e) => {

    if (this.state.value !== '') {
      this.setState({ loading: true })

      if (this.state.edit && this.state.rowid) {
        this.onConfirmUpdateItem()
      } else {

        api.post('/tickets.php', {
          method: 'insert_interaction',
          code: this.props.match.params.handle,
          message: this.state.value
        }).then((result) => {

          this.setState({ loading: false })

          let id = result.data.id

          result.data.status === 'ok' ?

            this.setState(state => {
              const messages = [...state.messages,
              { message: state.value, datetime: new Date(), user: true, rowid: id }
              ];

              return {
                messages,
                value: '',
              };
            }) :

            this.setState({
              alert: (
                <SweetAlert
                  style={{ display: "block", marginTop: "-240px" }}
                  error
                  title="Erro ao inserir mensagem"
                  onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
              )
            });

        });
      }
    }
  }

  onUpdateItem = i => {

    this.inputMsg.focus()

    this.setState(state => {
      let id
      let msg

      state.messages.map((item, j) => {
        if (j === i) {
          id = item.rowid
          msg = item.message
        }
      });

      return {
        value: msg,
        edit: true,
        rowid: id
      };
    });
  };

  onConfirmUpdateItem = i => {
    api.post('/tickets.php', {
      method: 'update_interaction',
      rowid: this.state.rowid,
      code: this.props.match.params.handle,
      message: this.state.value
    }).then((result) => {

      this.setState({ loading: false })

      let id = this.state.rowid

      result.data.status === 'ok' ?

        this.setState(state => {

          if (state.value !== '') {
            const messages = state.messages.map((item, j) => {
              if (state.rowid === item.rowid && state.value !== item.message) {
                return { message: state.value, datetime: new Date(), user: true, edit: true, rowid: id };
              } else {
                return item;
              }
            });

            return {
              messages,
              value: '',
              edit: false,
              rowid: false
            };
          }
        }) :

        this.setState({
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              error
              title="Erro ao editar mensagem"
              onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });

    });
  };

  onConfirmRemoveItem(i) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-240px" }}
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={<span className="spanAlert">Tem certeza que deseja remover esta mensagem?</span>}
          onConfirm={() => this.onRemoveItem(i)}
          onCancel={() => this.hideAlert()}
        >Esta ação não poderá ser desfeita!</SweetAlert>
      )
    });
  }

  onRemoveItem = i => {
    this.setState({ loading: true })
    this.hideAlert();
    let id = ''

    this.state.messages.map((item, j) => {
      if (j === i) {
        id = item.rowid
      }
    });

    api.post('/tickets.php', {
      method: 'remove_interaction',
      rowid: id
    }).then((result) => {

      this.setState({ loading: false })

      result.data.status === 'ok' ?

        this.setState(state => {
          const messages = state.messages.filter((item, j) => i !== j);

          return {
            messages,
            alert: null
          };
        }) :

        this.setState({
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              error
              title="Erro ao remover mensagem"
              onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });

    });
  };

  onCancelEdit = i => {
    this.inputMsg.focus()

    this.setState(state => {
      return {
        value: '',
        edit: false
      };
    });
  };

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  /* _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      this.onAddItem()
    } else if (e.key === 'Escape' && this.state.edit) {
      this.onCancelEdit()
    }
  } */

  render() {
    let handle = this.props.match.params.handle;

    let ticket = {
      'code': this.state.code,
      'title': this.state.title_text,
      'status': this.state.status,
      'severity': this.state.priority
    }
    const status_lst = this.state.statuses;

    return (
      this.state.redirect ?
        <Redirect to="/tickets" /> :
        <div className="main-content">
          {this.state.alert}
          <HeaderForm ticket={ticket} status_lst={status_lst}></HeaderForm>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  content={
                    <div>
                      <div className={this.state.loading ? "loading-form" : "fadeOut loading-form"}>
                        <img src={loading} alt="Carregando.." />
                      </div>
                      <form className={this.state.loading ? "opacity form" : "fadeIn form"}>
                        <TabForm handle={handle} active="interactions" admin={this.state.adm}></TabForm>
                        {this.state.loading ? '' :
                          <div>
                            <div id="messages" className="rcw-messages-container">
                              {
                                this.state.messages.length > 0 ?
                                  this.state.messages.map((item, key) => (
                                    <Message index={key} content={item} onRemove={this.onConfirmRemoveItem.bind(this)} onUpdate={this.onUpdateItem}></Message>
                                  )) :
                                  <div class={this.state.loading ? "hide blank-messages" : "fadeIn blank-messages"}>
                                    <div>Ainda não foram registradas interações neste ticket.</div>
                                    <div>Insira abaixo sua primeira mensagem.</div>
                                  </div>
                              }
                            </div>

                            <div className="rcw-sender">
                              <button type="button" className={"rcw-cancel-edit" + (!this.state.edit ? " display-none" : "")}>
                                <img src={cancel_edit} className="rcw-cancel-icon" alt="cancel" onClick={this.onCancelEdit} />
                              </button>
                              <textarea /* ref={(input) => { this.inputMsg = input; }} */ type="text" className="rcw-new-message" name="message" placeholder="Digite uma nova mensagem..." autocomplete="off" value={this.state.value} onChange={this.onChangeValue} /* onKeyDown={this._handleKeyDown} */ />
                              <button type="button" className="rcw-send" onClick={this.onAddItem}>
                                <img src={send} className="rcw-send-icon" alt="send" />
                              </button>
                            </div>
                          </div>}

                        {this.state.loading ? '' : <FooterForm url={this.state.url}></FooterForm>}

                      </form>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
    );
  }
}

export default Interactions;