import AdmTickets from "views/AdmTickets/AdmTickets.jsx"
import AdmTicketsView from "views/AdmTickets/View/View.jsx"
import AdmTicketsTimeReport from "views/AdmTickets/TimeReport/TimeReport.jsx"
import AdmTicketsHist from "views/AdmTickets/Hist/Hist.jsx"
import AdmTicketsFiles from "views/AdmTickets/Files/Files.jsx"
import AdmTicketsInteractions from "views/AdmTickets/Interactions/Interactions.jsx"

import TicketsAdd from "views/Tickets/Add/Add.jsx"
import TicketsView from "views/Tickets/View/View.jsx"
import TicketsHist from "views/Tickets/Hist/Hist.jsx"
import TicketsFiles from "views/Tickets/Files/Files.jsx"
import TicketsInteractions from "views/Tickets/Interactions/Interactions.jsx"
import FavPage from "views/Favorites/Favorites.jsx"
import UserPage from "views/Pages/UserPage.jsx";
import ChangePassPage from "views/Pages/ChangePassPage.jsx";
import BoardPage from "views/Pages/BoardPage.jsx";
import BIPage from "views/Pages/BIPage.jsx";
import TimeReport2 from "../views/TimeReport2/TimeReport2";

var defaultRoutes = [
  {
    path: "/admtickets/view/:handle",
    component: AdmTicketsView
  },
  {
    path: "/admtickets/tr/:handle",
    component: AdmTicketsTimeReport
  },
  {
    path: "/admtickets/hist/:handle",
    component: TicketsHist
  },
  {
    path: "/admtickets/files/:handle",
    component: TicketsFiles
  },
  {
    path: "/admtickets/interactions/:handle",
    component: TicketsInteractions
  },
  {
    path: "/admtickets",
    component: AdmTickets
  },

  {
    path: "/tickets/add",
    component: TicketsAdd
  },
  {
    path: "/tickets/view/:handle",
    component: TicketsView
  },
  {
    path: "/tickets/hist/:handle",
    component: TicketsHist
  },
  {
    path: "/tickets/files/:handle",
    component: TicketsFiles
  },
  {
    path: "/tickets/interactions/:handle",
    component: TicketsInteractions
  },

  {
    path: "/user",
    component: UserPage
  },
  
  {
    path:"/changePassword",
    component: ChangePassPage
  },

  {
    path:"/favoritos",
    component: FavPage
  },

  {
    path:"/board-view",
    component: BoardPage
  },

  {
    path:"/BI",
    component: BIPage
  }

  
];

export default defaultRoutes;