import React, { Component } from "react";

import Card from "components/Card/Card.jsx";

import { Grid, Row, Col, ControlLabel, FormGroup, FormControl } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import ListOfVal from "../../../components/SharedForm/ListOfVal";

import { FilePond, registerPlugin, } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import SweetAlert from "react-bootstrap-sweetalert";

import api from "services/api";

import loading from "assets/img/loading.gif";
import { impact_indexes, urgency_indexes, define_priority } from "../../../components/SharedForm/val";

registerPlugin(FilePondPluginFileValidateType);

class Add extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      alert: null,
      redirect: false,
      error: false,
      options: {},
      code: '',
      title: '',
      status: 'Aberto',
      impact: '',
      imp_index: '',
      urg_index: '',
      urgency: '',
      severity: '',
      result_severity: '',

      form: {
        title: { value: '', required: true },
        impact: { value: '', required: true },
        urgency: { value: '', required: true },
        severity: { value: '', required: false },
        desc: { value: '', required: true },
        attachment: { value: [], required: false },
      },

      formErrors: {
        title: false,
        impact: false,
        urgency: false,
        severity: false,
        desc: false
      },

      formMsg: false
    };
  }

  componentDidMount() {
    this.preLoadContent()
  }

  preLoadContent = async () => {
    try {
      const response = await api.post("/tickets.php", { method: 'preLoadContent', postn: sessionStorage.getItem('postn') });

      if (response.data == 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        this.setState({
          code: response.data.code,
          loading: false
        });

        api
          .post("/list_of_val.php", { type: 'ADJUST_PRIORITY' })
          .then(response => {
            this.setState({
              options: response.data.lov
            });
          })
          .catch(error => console.log(error.response));


      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
    }
  }

  handleTypeValidation = e => {
    e.preventDefault();

    let self = this
    let formMsg = false

    Object.keys(this.state.form).map(function (index) {
      self.setState(prevState => ({
        formErrors: {
          ...prevState.formErrors,
          [index]: self.state.form[index].value === "" && self.state.form[index].required ? true : false
        },
      }));

      formMsg = self.state.form[index].value === "" && self.state.form[index].required ? true : formMsg
    })

    this.setState({
      formMsg: formMsg
    })

    if (!formMsg)
      this.handleSubmit(e)
  };

  setNewSeverity(state, new_severity_index) {
    this.setState({
      result_severity: state.options[new_severity_index]
    },
      function () {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            ['severity']: {
              ...prevState.form['severity'],
              value: this.state.result_severity
            },
          },

          formErrors: {
            ...prevState.formErrors,
            ['severity']: this.state.result_severity === "" ? true : false
          },

        }));
      });
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.impact !== this.state.impact || prevState.urgency !== this.state.urgency) {

      if ((this.state.impact !== '' && this.state.impact !== undefined) && (this.state.urgency !== '' && this.state.urgency !== undefined)) {
        var { selected_impact, selected_urgency, key_impact, key_urgency, new_severity } = '';

        selected_impact = this.state.imp_index;
        selected_urgency = this.state.urg_index;

        key_impact = impact_indexes[selected_impact];
        key_urgency = urgency_indexes[selected_urgency];

        new_severity = define_priority[key_impact][key_urgency];

        this.setNewSeverity(this.state, new_severity);

      }

      else {
        this.setState({ result_severity: '' });
      }

    }
  }

  handleChange = e => {
    e.preventDefault();

    const { name, value, selectedIndex } = e.target;

    if (name === 'impact') {
      this.setState({
        impact: value,
        imp_index: selectedIndex
      })

    }

    if (name === 'urgency') {
      this.setState({
        urgency: value,
        urg_index: selectedIndex
      })

    }

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: {
          ...prevState.form[name],
          value: value
        },
      },

      formErrors: {
        ...prevState.formErrors,
        [name]: value === "" ? true : false
      },
    }),
    );

    let fmsg = false;
    let self = this;

    setTimeout(function () {
      if (document.getElementsByClassName('has-error').length >= 1) {
        fmsg = true;
      }

      self.setState({
        formMsg: fmsg
      })

    }, 300)

  };

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      loading: true
    });

    const { title, severity, desc, attachment } = this.state.form;

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    const formData = new FormData();

    formData.append('method', 'insert');
    formData.append('postn', sessionStorage.getItem('postn'));
    formData.append('subject', title.value);
    formData.append('priority', severity.value);
    formData.append('description', desc.value);

    if (attachment.value[0] !== undefined) {
      formData.append('attachment', attachment.value[0]);
    }

    api.post('/tickets.php', formData, config).then((result) => {
      console.log(result.data)

      if(result.status === 200){
        this.setState({
          loading: false,
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              success
              title="Obrigado!"
              onConfirm={() => this.successAlert()}>Seu ticket foi criado com sucesso!</SweetAlert>
          )
        })
        return
      }
      else if(result.data.status === 401){
        this.setState({
          loading: false,
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              error
              title="Erro de conexão"
              onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });
        return
      }
      else if(result.status === 500){
        this.setState({
          loading: false,
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              error
              title="Erro interno do servidor"
              onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });
        return
      }
      else{
        this.setState({
          loading: false,
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              error
              title="Erro ao criar chamado"
              onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });
      }

      /* result.data.status === 'ok' ?

        this.setState({
          loading: false,
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              success
              title="Obrigado!"
              onConfirm={() => this.successAlert()}>Seu ticket foi criado com sucesso!</SweetAlert>
          )
        }) :

        this.setState({
          loading: false,
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              error
              title="Erro ao criar chamado"
              onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        }); */

     });
  }


  successAlert() {
    this.setState({
      redirect: true
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  render() {

    return (
      this.state.redirect ?
        <Redirect to="/tickets" /> :
        <div className="main-content">
          {this.state.alert}
          <div className="header-form-box">
            <HeaderForm ticket={this.state} className={this.state.loading ? "opacity" : "fadeIn"}></HeaderForm>
          </div>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  content={
                    <div>
                      <div className={this.state.loading ? "loading-form" : "fadeOut loading-form"}>
                        <img src={loading} alt="Carregando.." />
                      </div>
                      <form className={this.state.loading ? "opacity form" : "fadeIn form"}>
                        <div className={this.state.formMsg ? "error-msg fadeIn" : "hide"}>Campos de preenchimento obrigatório</div>
                        <div className="row">
                          <Col sm={12}>
                            <FormGroup className={this.state.formErrors.title ? "has-error" : ""}>
                              <ControlLabel>Título</ControlLabel>
                              <FormControl
                                autoFocus
                                name="title"
                                type="text"
                                placeholder="Digite o título do chamado"
                                ref="title"
                                defaultValue={this.state.title}
                                onChange={this.handleChange}
                                maxLength={80}
                              />
                            </FormGroup>
                          </Col>
                        </div>



                        <div className="row">

                          <Col sm={6}>
                            <FormGroup className={this.state.formErrors.urgency ? "has-error" : ""}>
                              <ControlLabel>Urgência</ControlLabel>
                              <ListOfVal
                                name="urgency"
                                reference="urgency"
                                onChange={this.handleChange}
                              />
                            </FormGroup>
                          </Col>

                          <Col sm={6}>
                            <FormGroup className={this.state.formErrors.impact ? "has-error" : ""}>
                              <ControlLabel>Impactos</ControlLabel>
                              <ListOfVal
                                name="impact"
                                reference="impact"
                                onChange={this.handleChange}
                              />
                            </FormGroup>
                          </Col>
                        </div>

                        <div className="row">
                          <Col sm={6}>
                            <FormGroup >
                              <ControlLabel>Ticket Original</ControlLabel>
                              <FormControl value={this.state.code} readOnly={true}/>
                            </FormGroup>
                          </Col>
                        </div>
                        {/*
                      <div className="row">
                          <Col sm={12}>
                            <FormGroup className={this.state.formErrors.severity ? "has-error" : ""}>
                              <ControlLabel>Severidade</ControlLabel>
                              {/*<ListOfVal 
                                name="severity" 
                                reference="severity" 
                                type="ADJUST_PRIORITY"
                                onChange={this.handleChange}
                                readOnly = {false}
                                index = {this.state.teste}
                              />*/}

                        {/*<FormControl
                                name="severity" 
                                type="text" 
                                placeholder="Severidade" 
                                ref="severity"
                                readOnly = {true}
                                value={this.state.result_severity} 
                                onChange={this.handleChangeSeverity} 
                              />
                            </FormGroup>
                          </Col>
                       </div>*/}

                        <div className="row">
                          <Col sm={12}>
                            <FormGroup className={this.state.formErrors.desc ? "has-error" : ""}>
                              <ControlLabel>Descrição</ControlLabel>
                              <FormControl
                                name="desc"
                                componentClass="textarea"
                                required
                                rows="5"
                                cols="80"
                                placeholder="Descreva aqui os detalhes do chamado" ref="desc"
                                onChange={this.handleChange}
                                maxLength={999}
                              />
                            </FormGroup>
                          </Col>
                        </div>

                        <div className="row">
                          <Col sm={12}>
                            <FormGroup>
                              <ControlLabel>Anexo</ControlLabel>
                              <FilePond
                                labelIdle="Arraste e solte ou <span class='filepond--label-action'>procure</span> um arquivo"
                                labelInvalidField="Arquivo inválido"
                                labelFileWaitingForSize="Aguardando.."
                                labelFileSizeNotAvailable="Tamanho não permitido"
                                labelFileLoading="Carregando.."
                                labelFileLoadError="Erro durante o carregamento"
                                labelFileProcessing="Carregando.."
                                labelFileProcessingComplete="Upload completo"
                                labelFileProcessingAborted="Upload cancelado"
                                labelFileProcessingError="Erro durante o upload"
                                labelFileProcessingRevertError="Erro ao reverter"
                                labelFileRemoveError="Erro ao remover"
                                labelTapToCancel="Cancelar"
                                labelTapToRetry="Tentar novamente"
                                labelTapToUndo="Desfazer"
                                labelButtonRemoveItem="Remover"
                                labelButtonAbortItemLoad="Abortar"
                                labelButtonRetryItemLoad="Tentar novamente"
                                labelButtonAbortItemProcessing="Cancelar"
                                labelButtonUndoItemProcessing="Desfazer"
                                labelButtonRetryItemProcessing="Tentar novamente"
                                labelButtonProcessItem="Upload"
                                instantUpload={false}
                                ref={ref => this.pond = ref}
                                allowMultiple={false}
                                // onupdatefiles={(fileItems) => {
                                //   this.setState(prevState => ({
                                //     form: {
                                //       ...prevState.form,
                                //       attachment: {
                                //         ...prevState.form.attachment,
                                //         value: fileItems.map(fileItem => fileItem.file)
                                //       }
                                //     }
                                //   }));
                                // }}
                                allowFileTypeValidation={true}
                                acceptedFileTypes={["image/*", "video/*", "application/pdf", "application/zip","application/vnd.rar","application/x-rar-compressed","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/x-zip-compressed","application/x-excel"]}
                                fileValidateTypeLabelExpectedTypes="Tipos de arquivos suportados são: imagem, pdf, video, zip e rar "
                                labelFileTypeNotAllowed="Tipo de arquivo não suportado "
                                onaddfile={(error, source, test) => {
                                  const attachmentValue = !error ? [source.file] : []   

                                  this.setState(prevState => ({
                                    form: {
                                      ...prevState.form,
                                      attachment: {
                                        ...prevState.form.attachment,
                                        value: attachmentValue,
                                      }
                                    }
                                  }));
                                }}
                                >
                              </FilePond>
                            </FormGroup>
                          </Col>
                        </div>
                        <FooterForm url="tickets" disabled={false} click={this.handleTypeValidation}></FooterForm>
                      </form>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
    );
  }
}

export default Add;