import React, { Component } from "react";

import { Text } from 'react';
import { Grid, Row, Col, Tooltip, OverlayTrigger, FormControl } from "react-bootstrap";
import { NavLink } from "react-router-dom";

// react component for creating dynamic tables
import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Severity from "components/SharedForm/Severity.jsx"
import ListOfVal from "components/SharedForm/ListOfVal";
import ExcelExport from "components/ExcelExport/ExcelExport.jsx";
import Datetime from "react-datetime";

import Time from 'react-time';

import loading from "assets/img/loading.gif";

import api from "services/api";

import Moment from "moment";
import 'moment/locale/pt-br.js';
import { Link } from "react-bootstrap/lib/Navbar";

class TimeReport2 extends Component {
  constructor(props) {
    super(props);
    Moment.locale('pt-br');
  }

  state = {
    rows: [],
    loading: true,
    error: false,
    pages: 0,
    filter: true,
    filtered: this.getFilters()
  }



  getTRs = async (page = false, pageSize = false, filtered = []) => {
    try {
      const response = await api.post("/tickets.php", {
        method: 'timereport2',
        mode: sessionStorage.getItem("resp").split(',').indexOf('a228f118-7ad2-9e7b-6190-fa6dc4e176e1') !== -1 ? 'leader' : 'normal',
        page: page,
        pageSize: pageSize,
        filtered: filtered
      });
      this.setState({
        rows:
          response.data.timereport.map((row) => {

            return {
              person_name: row['person_name'],
              id: row['rowid'],
              period_id: row['period_id'],
              period_name: row['period_name'],
              cc_name: row['cc_name'],
              date: (row['date'] !== "0000-00-00" ?
                <Time value={row['date']} format="DD/MM/YYYY" /> :
                ""),
              tk_code: row['tk_code'],
              comments: row['comments'],
              hours: row['hour'],
              extra_hours: row['extra_hour'],
              night_hours: row['night_hour'],
              warning_hours: row['warning_hour']
            };

          }),
        loading: false,
        pages: response.data.pages
      });

    } catch (err) {
      console.log(err);
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
    }
  }

  advancedFilter = e => {
    e.preventDefault();
    let self = this

    this.setState({
      filter: self.state.filter ? false : true,
    })


    let clean_filter = true;

    if (this.state.filter === true) {
      if (this.state.filtered.length > 0) {
        this.state.filtered.map((row) => {
          if (row.id === 'prefilter') {
            clean_filter = false;
            this.setState({
              filtered: [{
                id: row.id,
                value: row.value
              }]
            })
          }
        })

        if (clean_filter) {
          this.setState({
            filtered: []
          })
        }
      }
    }

    //this.table.fireFetchData();
  }

  onFilteredChangeCustom = (value, accessor) => {

    let filtered = this.state.filtered;
    let insertNewFilter = 1;
    if (filtered.length) {
      filtered.forEach((filter, i) => {
        if (filter["id"] === accessor) {
          if (value === "" || !value.length) filtered.splice(i, 1);
          else filter["value"] = value;

          insertNewFilter = 0;
        }
      });
    }

    if (insertNewFilter) {
      filtered.push({ id: accessor, value: value });
    }

    this.setState({ filtered: filtered });
    this.saveFilters(filtered);
    this.table && this.table.fireFetchData()
    console.log(this.table && this.table.fireFetchData())
  };

  onDatepickerRef(el) { if (el && el.input) { el.input.inputRef.readOnly = true; } }

  saveFilters(filters) {
    sessionStorage.setItem('tr2-filters', JSON.stringify(filters));
  };
  getFilters() {
    let filters = sessionStorage.getItem('tr2-filters');
    if (filters) {

      return JSON.parse(filters);
    }
    else {
      return []
    }
  }
  getFilterValue(id) {
    let filters = this.getFilters();
    let value;
    if (!filters.length) {
      return null;
    }
    filters.forEach((i) => {
      if (i.id === id) {
        value = i.value;
      }
    });
    return value;
  }

  render() {
    const filter = <Tooltip id="filter">Filtro avançado</Tooltip>;
    const filter_dis = <Tooltip id="filter">Remover filtro</Tooltip>;


    return (
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                minheight={true}
                title={
                  <div>
                    <span>Time Report - Portal</span>
                    <div className="filter-button">
                      <OverlayTrigger placement="top" overlay={this.state.filter ? filter_dis : filter}>
                        <button style={{ color: '#9305F2' }} simple type="button" onClick={this.advancedFilter} bsSize="xs">
                          <i className={this.state.filter ? "fa fa-filter filterticket" : "fa fa-filter filterticket-disabled"} />
                        </button>
                      </OverlayTrigger>

                    </div>
                    {this.state.rows.length > 0 ?
                      <div className="table-buttons">
                        <p style={{ fontSize: '9px' }}>A funcionalidade de lançar as horas através dessa tela está em desenvolvimento</p>
                        <ExcelExport dataset={this.state.rows} />
                      </div> : ''}

                  </div>
                }
                content={
                  <ReactTable
                    ref={(instance) => { this.table = instance; }}
                    data={this.state.rows}
                    filtered={this.state.filtered}
                    onResizedChange={(newResized, event) => { }}
                    onFilteredChange={(filtered, column, value) => {
                      this.onFilteredChangeCustom(value, column.id || column.accessor);

                    }}
                    columns={[

                      {
                        Header: "Colaborador",
                        accessor: "person_name",
                        className: "center",
                        filterable: this.state.filter ? true : false
                      },

                      {
                        Header: "Período",
                        accessor: "period_name",
                        className: "center",
                        filterable: this.state.filter ? true : false,
                      },
                      {
                        Header: "Centro de Custos",
                        accessor: "cc_name",
                        className: "center",
                        filterable: this.state.filter ? true : false
                      },

                      {
                        Header: "Data",
                        accessor: "date",
                        className: "center",
                        filterable: this.state.filter ? true : false,
                        Filter: ({ filter, onChange }) => {
                          return (
                            <Datetime
                              locale="pt-br"
                              timeFormat={false}
                              closeOnSelect={true}
                              inputProps={{ placeholder: "", className: "form-control" }}
                              onChange={event => {
                                let value = '';

                                if (event.format && {}.toString.call(event.format) === '[object Function]')
                                  value = event.format('DD/MM/YYYY')

                                this.onFilteredChangeCustom(value, "date")
                              }}
                              ref={el => this.onDatepickerRef(el)}
                            />
                          );
                        }
                      },

                      {
                        Header: "Cód.Ticket",
                        accessor: "tk_code",
                        className: "center",
                        filterable: this.state.filter ? true : false
                      },

                      {
                        Header: "Comentários",
                        accessor: "comments",
                        filterable: false
                      },

                      {
                        Header: "Hora",
                        accessor: "hours",
                        className: "center",
                        filterable: false
                      },
                      {
                        Header: "Extras",
                        accessor: "extra_hours",
                        className: "center",
                        filterable: false
                      },
                      {
                        Header: "Noturna",
                        accessor: "night_hours",
                        className: "center",
                        filterable: false
                      },

                      {
                        Header: 'Sobreaviso',
                        accessor: "warning_hour",
                        className: "center",
                        filterable: false
                      }

                    ]}

                    pages={this.state.pages}
                    minRows={10}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom
                    className="-striped -highlight"
                    previousText="Anterior"
                    nextText="Próxima"
                    loadingText={<img src={loading} className="loading" alt="Carregando.." />}
                    noDataText={this.state.loading ? "" : (this.state.error ? this.state.error : "Sem registros encontrados")}
                    pageText="Página"
                    ofText="de"
                    rowsText="registros"
                    loading={this.state.loading}
                    manual // this would indicate that server side pagination has been enabled 
                     pageSizeOptions={[5, 10, 20, 50, 100, 250, 500]}
                    onFetchData={(state, instance) => {
                      this.setState({ loading: true });
                      this.getTRs(state.page, state.pageSize, state.filtered);
                      /* console.log(state) */
                    }}
                  />

                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default TimeReport2;