import React, { Component } from "react";
import { Grid, Col, Row } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import StatsCard from "components/Card/StatsCard.jsx";
import Tasks from "components/Tasks/Tasks.jsx";
import Legend from "components/Legend/Legend.jsx";

import api from "services/api";
import loading from "assets/img/loading.gif";

import { Line, Bar, Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

// react component that creates a dropdown menu for selection
import Select from "react-select";
import "react-select/dist/react-select.css";

/*var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920
};*/

class Dashboard extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      loading_dashboard: false,

      error: false,

      orgs: [],
      period: [],

      hours: '',
      consumed: '',
      over_time: '',
      nr_open_tickets: '',
      nr_closed_tickets: '',

      open_tickets: [{ legend: [], value: [] }],
      open_tickets_support: [{ legend: [], value: [] }],
      open_tickets_client: [{ legend: [], value: [] }],
      open_sum: '',
      open_support_sum: '',
      open_client_sum: '',
      consumed_hours: [{ legend: [], value: [] }],
      tickets_historic: [{ legend: [], value: [] }],
      open_tikets_age: [{ value: [] }],

      last_tickets: [],

      defaultorg: '',
      selectedorg: localStorage.getItem('org') ? localStorage.getItem('org') : '',
      selectedperiod: localStorage.getItem('period') ? { value: localStorage.getItem('period') } : { value: 'all' }
    }
  }

  

  componentDidMount() {
    this.preLoadContent();

  }

  preLoadContent = async () => {

    try {
      const response = await api.post("/dashboard.php", {
        method: 'preLoadContent',
        org: this.state.selectedorg,
        period: this.state.selectedperiod.value
      });
      console.log(response)


      if (response.data == 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        this.setState({
          loading: false,

          admin: response.data.admin,

          orgs: response.data.orgs,
          defaultorg: response.data.default_value,

          selectedorg: response.data.admin === true && localStorage.getItem('org') ? localStorage.getItem('org') : response.data.default_value,
          selectedperiod: response.data.admin === true && localStorage.getItem('period') ? { value: localStorage.getItem('period') } : { value: 'all' },

          period: response.data.period,

          hours: response.data.hours,

          consumed: response.data.consumed != '' && response.data.consumed != 0 ?
            (response.data.consumed + (response.data.consumed == 1 ? '' : '')) :
            0,
            
          over_time: response.data.over_time,
          nr_open_tickets: response.data.nr_open_tickets,
          nr_closed_tickets: response.data.nr_closed_tickets,

          open_tickets: response.data.open_tickets,
          open_tickets_support: response.data.open_tickets_support,
          open_tickets_client: response.data.open_tickets_client,

          consumed_hours: response.data.consumed_hours,
          tickets_historic: response.data.tickets_historic,
          open_tikets_age: response.data.open_tickets_age ? response.data.open_tickets_age: "",

          last_tickets: response.data.last_tickets
        }, function () {

          var open = 0,
            p_suporte = 0,
            p_cliente = 0;

          for (var i = 0; i < this.state.open_tickets.value.datasets[0].data.length; i++) {
            open += parseInt(this.state.open_tickets.value.datasets[0].data[i]);
          }

          for (var i = 0; i < this.state.open_tickets_support.value.datasets[0].data.length; i++) {
            p_suporte += parseInt(this.state.open_tickets_support.value.datasets[0].data[i]);
          }

          for (var i = 0; i < this.state.open_tickets_client.value.datasets[0].data.length; i++) {
            p_cliente += parseInt(this.state.open_tickets_client.value.datasets[0].data[i]);
          }

          this.setState
            ({
              open_sum: open,
              open_support_sum: p_suporte,
              open_client_sum: p_cliente
            });
        });
      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
    }
  }


  componentDidUpdate(prevProps, prevState) {


    const Chart = require('react-chartjs-2');
    

    Chart.Chart.pluginService.register({
      beforeDraw: function (chart) {

        var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";

        var text = chart.config.options.centerText.text,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;

        ctx.fillText(text, textX, textY);
        ctx.save();

      },
    });

  }
  getDate() {
    var d = new Date();
    var m = d.getMonth() + 1;

    if (m < 10)
      return '0' + m + ' / ' + d.getFullYear();

    return m + ' / ' + d.getFullYear();
  }

  getDefaultFilterDate() {
    var d = new Date();
    var m = d.getMonth() + 1;

    if (m < 10)
      return d.getFullYear() + '-0' + m;
    else if (m == 13)
      return (d.getFullYear() + 1) + '-01';

    return d.getFullYear() + '-' + m;
  }

  formatDate(date) {
    if (date == '' || date == 'all')
      return this.getDate()

    var d = new Date(date);
    var m = d.getMonth() + 2;

    if (m < 10)
      return '0' + m + '/' + d.getFullYear();
    else if (m == 13)
      return '01/' + (d.getFullYear() + 1);

    return m + '/' + d.getFullYear();
  }

  clickChart(e) {
    var url = "",
      storage_key = "",
      filterJson = "";

    if (this.state.admin) {
      url = "/admtickets";
      storage_key = "adm-ticket-filters";
      var org_id = this.state.selectedorg;
      var orgName = "";

      for (var i = 0; i < this.state.orgs.length; i++) {
        if (this.state.orgs[i].value === org_id)
          orgName = this.state.orgs[i].label;
      }

      filterJson = [
        {
          id: "org",
          value: orgName
        },
        {
          id: "status",
          value: e[0]._view.label.trim()
        }
      ];

    }

    else {
      url = "/tickets"
      storage_key = 'ticket-filters';
      filterJson = [
        {
          id: "status",
          value: e[0]._view.label.trim()
        }
      ];
    }
    console.log(filterJson)
    this.defineFiltersClick(url, storage_key, filterJson);
  }

  defineFiltersClick(tk_url, session_storage_key, filters) {

    sessionStorage.setItem(session_storage_key, JSON.stringify(filters));
    window.location.href = tk_url;
  }

  loadDashboard(org, period) {
    localStorage.setItem('org', org)
    localStorage.setItem('period', period)

    api.post('/dashboard.php', {
      method: 'reload',

      orgid: org,
      period: period

    }).then((result) => {

      this.setState({ loading_dashboard: false })
      if (result.data.status === 'ok') {
        this.setState({
          
          hours: result.data.hours === null || result.data.hours === '' ? '-' : result.data.hours,

          consumed: result.data.consumed != '' && result.data.consumed != 0 ?
            (result.data.consumed + (result.data.consumed == 1 ? '' : '')) :
            0,

          over_time: result.data.over_time,

          nr_open_tickets: result.data.nr_open_tickets,
          nr_closed_tickets: result.data.nr_closed_tickets,

          open_tickets: result.data.open_tickets,
          open_tickets_support: result.data.open_tickets_support,
          open_tickets_client: result.data.open_tickets_client,

          consumed_hours: result.data.consumed_hours,
          tickets_historic: result.data.tickets_historic,
          open_tikets_age: result.data.open_tickets_age ? result.data.open_tickets_age: "",

          last_tickets: result.data.last_tickets
        }, function () {
          var open = 0,
            p_suporte = 0,
            p_cliente = 0;

          for (var i = 0; i < this.state.open_tickets.value.datasets[0].data.length; i++) {
            open += parseInt(this.state.open_tickets.value.datasets[0].data[i]);
          }

          for (var i = 0; i < this.state.open_tickets_support.value.datasets[0].data.length; i++) {
            p_suporte += parseInt(this.state.open_tickets_support.value.datasets[0].data[i]);
          }

          for (var i = 0; i < this.state.open_tickets_client.value.datasets[0].data.length; i++) {
            p_cliente += parseInt(this.state.open_tickets_client.value.datasets[0].data[i]);
          }

          this.setState
            ({
              open_sum: open,
              open_support_sum: p_suporte,
              open_client_sum: p_cliente
            });

        })
      }
    });
  }

  volumetriaChamadoAbertos(e) {
    if(!e || !e[0] || !e[0]._chart) return //verifica antes se no json do gráfico o _chart está vazio

    var graphY = e[0]._chart.data.datasets[0].data[e[0]._index] // busca os valores de Y no gráfico
    
    var today = new Date();
    today.setDate(today.getDate() - parseInt(graphY))


    var url = "",
      storage_key = "",
      filterJson = "";

    if (this.state.admin) {
      url = "/admtickets";
      storage_key = "adm-ticket-filters";
      var org_id = this.state.selectedorg;
      var orgName = "";

      for (var i = 0; i < this.state.orgs.length; i++) {
        if (this.state.orgs[i].value === org_id)
          orgName = this.state.orgs[i].label;
      }

      filterJson = [
        {
          id: "org",
          value: orgName
        },
        {
          id: "status",
          value: '',
        },
        {
          id: 'date',
          value: today.toLocaleDateString('pt-BR')
        }
      ];

    }

    else {
      url = "/tickets"
      storage_key = 'ticket-filters';
      filterJson = [
        {
          id: "status",
          value: ''
        },
        {
          id: 'date',
          value: today.toLocaleDateString('pt-BR')
        }
      ];
    }
    this.salvaVolumetriaChamado(url, storage_key, filterJson);
    console.log(filterJson)
  }
  salvaVolumetriaChamado(tk_url, session_storage_key, filters) {
    sessionStorage.setItem(session_storage_key, JSON.stringify(filters));
    window.location.href = tk_url;
  }


  render() {
    return (
      <div className="main-content">

        <div className={this.state.loading || this.state.loading_dashboard ? "loading-form" : "fadeOut loading-form"}>
          <img src={loading} alt="Carregando.." />
        </div>

        {this.state.loading ? '' :
          <div>

            <Grid fluid>
              <Row>
                <Col md={8}>
                  <div className="dashboard-filter">
                    <h5 style={{ fontSize: '2rem', fontFamily: 'Graphik Regular, Arial, Sans-Serif' }}>Organização</h5>
                    <Select
                      placeholder="Selecione uma organização"
                      name="org"
                      disabled={this.state.admin === true ? false : true}
                      value={this.state.selectedorg}
                      options={this.state.orgs}
                      onChange={e => {
                        this.setState({
                          selectedorg: e !== null ? e.value : '',
                          loading_dashboard: e !== null ? true : this.state.loading_dashboard
                        })

                        if (e !== null)
                          this.loadDashboard(e.value, this.state.selectedperiod.value)
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="dashboard-filter">
                    <h5 style={{ fontSize: '2rem', fontFamily: 'Graphik Regular, Arial, Sans-Serif' }}>Período</h5>
                    <Select
                      placeholder="Selecione o período"
                      name="period"
                      value={this.state.selectedperiod.value}
                      options={this.state.period}
                      onChange={e => {
                        this.setState({
                          selectedperiod: { value: e !== null ? e.value : '' },
                          loading_dashboard: e !== null ? true : this.state.loading_dashboard
                        })

                        if (e !== null)
                          this.loadDashboard(this.state.selectedorg, e.value)
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Grid>

            {this.state.loading_dashboard ? '' :
              <Grid fluid className="gridCards" >
              
                <Row >
                  <Col lg={6} sm={6} style={{ fontFamily: 'Graphik Regular, Arial, Sans-Serif' }}>
                    <StatsCard
                      bigIcon={<i style={{ background: '#9305F2', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontWeight: 'bold', fontSize: '1.5vw' }} className="pe-7s-wristwatch text-info " />}
                      statsText="Horas contratadas / Horas consumidas"
                      statsValue={`${this.state.hours === null || this.state.hours === '' ? '-' : this.state.hours} / ${this.state.consumed}`}
                    /* statsIcon={<i className="fa fa-clock-o" />}
                    statsIconText={this.getDate()} */
                    />
                  </Col>

                  {/* <Col lg={3} sm={6}>
                    <StatsCard
                      bigIcon={<i style={{background:'#9305F2', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent',  fontWeight:'bold', fontSize:'1.5vw'}} className="pe-7s-note2 text-info" />}
                      statsText="Horas consumidas"
                      statsValue={this.state.consumed}
                      footerText={"Horas extras: " + this.state.over_time}
                      statsIcon={<i className="fa fa-clock-o" />}
                      statsIconText={this.formatDate(this.state.selectedperiod.value)}
                    />
                    <p></p>
                  </Col> */}
                  {/* <Col lg={3} sm={6}>
                    <StatsCard
                      bigIcon={<i style={{background:'#9305F2', WebkitBackgroundClip:'text', WebkitTextFillColor:'transparent',  fontWeight:'bold', fontSize:'1.5vw'}} className="pe-7s-graph1 text-danger " />}
                      statsText="Chamados abertos"
                      statsValue={this.state.nr_open_tickets}
                      statsIcon={<i className="fa fa-clock-o" />}
                      statsIconText={this.formatDate(this.state.selectedperiod.value)}
                    />
                  </Col> */}
                  <Col lg={6} sm={6}>
                    <StatsCard
                      bigIcon={<i style={{ background: '#9305F2', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', fontWeight: 'bold', fontSize: '1.5vw' }} className="pe-7s-check text-success " />}
                      statsText="Chamados fechados"
                      statsValue={this.state.nr_closed_tickets}
                      /* statsIcon={<i className="fa fa-clock-o" />}
                      statsIconText={this.formatDate(this.state.selectedperiod.value)} */
                      statsTextClass="padding-left-zero"
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Card
                      title="Franquia de horas"
                      /* category="Horas contratadas vs Horas consumidas" */
                      stats={
                        <div>

                          {this.state.consumed_hours.avg_hours > 0 ?
                            'Média de horas consumidas mensalmente: ' + this.state.consumed_hours.avg_hours
                            : ''}
                          {this.state.consumed_hours.avg_hours > 0 ?
                            <br />
                            : ''}

                          {/* <i className="fa fa-clock-o" /> {this.formatDate(this.state.selectedperiod.value)} */}
                        </div>
                      }
                      className="graph-horas"
                      content={
                        <div className="chart-bar">
                          <Line
                            height={120}
                            data={this.state.consumed_hours.value}
                            onElementsClick={() => { console.log(this.state.consumed_hours.value) }}
                            options={{
                              maintainAspectRatio: false,
                              showTooltips: false,
                              tooltips: { enabled: true },
                              plugins: { datalabels: { display: false } },
                              scales: {
                                xAxes: [{
                                  gridLines: {
                                    drawBorder: false,
                                    display: false
                                  }
                                }],
                                yAxes: [{
                                  ticks: {
                                    /*suggestedMin  : 0,,
                                    stepSize        : 10,*/
                                    padding: 10
                                  },
                                  gridLines: {
                                    drawBorder: false,
                                    borderDash: [2, 2],
                                    color: "#DDD"
                                  }
                                }]
                              },
                              centerText: {
                                display: false,
                                text: ''
                              }
                            }}
                            legend={{ display: false }}
                          />
                        </div>
                      }
                      legend={<Legend value={this.state.consumed_hours.legend} />}
                    />
                  </Col>
                  <Col md={4}>
                    <Card
                      title="Histórico de chamados"
                      /* stats={
                        <div>
                          <i className="fa fa-clock-o" /> {this.formatDate(this.state.selectedperiod.value)}
                        </div>
                      } */
                      /* category="Abertos vs Fechados" */
                      content={
                        <div className="chart-bar">
                          {this.state.tickets_historic.data > 0 ?
                            <Bar
                              height={120}
                              data={this.state.tickets_historic.value}
                              onElementsClick={
                                (e) => {
                                  this.clickChart(e)
                                }
                              }
                              options={{
                                maintainAspectRatio: false,
                                showTooltips: false,
                                tooltips: { enabled: true },
                                plugins: { datalabels: { display: false } },
                                scales: {
                                  xAxes: [{
                                    gridLines: {
                                      drawBorder: false,
                                      display: false
                                    }
                                  }],
                                  yAxes: [{
                                    ticks: {
                                      /*suggestedMin  : 0,
                                      stepSize        : 1,*/
                                      padding: 10
                                    },
                                    gridLines: {
                                      drawBorder: false,
                                      borderDash: [2, 2],
                                      color: "#DDD"
                                    }
                                  }]
                                },
                                centerText: {
                                  display: false,
                                  text: ''
                                }
                              }}
                              legend={{ display: false }}
                            /> : <div className="no-data-message">Sem dados no período selecionado.</div>}
                        </div>
                      }
                      legend={<Legend value={this.state.tickets_historic.legend} />}

                    />
                  </Col>
                  <Col md={4}>
                    <Card
                      title="Volumetria de chamados abertos"
                      content={
                        <div className="chart-bar">
                          {this.state.open_tikets_age.data > 0 ?
                            <Bar
                              height={120}
                              data={this.state.open_tikets_age.value}
                              onElementsClick={
                                (e) => {
                                  this.volumetriaChamadoAbertos(e)
                                }
                              }
                              options={{
                                maintainAspectRatio: false,
                                showTooltips: false,
                                tooltips: { enabled: true },
                                plugins: { datalabels: { display: false } },
                                scales: {
                                  xAxes: [{
                                    gridLines: {
                                      drawBorder: false,
                                      display: false
                                    },
                                    /*  scaleLabel : {
                                       display: true,
                                       labelString: 'Volumetria de Chamados',
                                       fontSize: 12,
                                       padding: 10,
                                       fontStyle: 'bold',
                                       lineHeight: 2
                                     }    */
                                  }],
                                  yAxes: [{
                                    ticks: {
                                      /* suggestedMin  : 1,
                                      stepSize        : 1, */
                                      padding: 5
                                    },
                                    gridLines: {
                                      drawBorder: false,
                                      borderDash: [2, 2],
                                      color: "#DDD"
                                    },
                                    /* scaleLabel : {
                                      display: true,
                                      labelString: 'Dias em Aberto',
                                      fontSize: 12,
                                      padding: 10,
                                      fontStyle: 'bold',
                                      lineHeight: 2
                                    } */
                                  }]
                                },
                                centerText: {
                                  display: false,
                                  text: ''
                                }
                              }}
                              legend={{ display: false }}
                            /> : <div className="no-data-message">Sem dados no período selecionado.</div>}
                        </div>
                      }
                    /* stats={
                      <div>
                        <i className="fa fa-clock-o" /> {this.formatDate(this.state.selectedperiod.value)}
                      </div>
                    } */
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={4}>
                    <Card
                      title="Chamados abertos"
                      /* category="Por status" */
                      /* stats={
                        <div className="statsCard">
                          <i className="fa fa-clock-o" /> {this.formatDate(this.state.selectedperiod.value)}
                        </div>
                      } */
                      content={
                        this.state.open_tickets.data > 0 ?
                          <Doughnut
                            height={100}
                            data={this.state.open_tickets.value}
                            options={{
                              maintainAspectRatio: false,
                              showTooltips: false,
                              tooltips: { enabled: true },
                              plugins: { datalabels: { color: '#FFF' } },
                              centerText: {
                                display: true,
                                text: this.state.open_sum
                              }
                            }}

                            onElementsClick={(e) => {
                              this.clickChart(e);
                            }}

                            legend={{ display: false }}
                          /> : <div className="no-data-message">Nenhum chamado pendente, aberto no período selecionado.</div>
                      }
                      legend={<Legend value={this.state.open_tickets.legend} />}
                    />
                  </Col>
                  <Col md={4}>
                    <Card
                      title="Pendente Suporte"
                      /* category="Por status" */
                      /* stats={
                        <div>
                          <i className="fa fa-clock-o" /> {this.formatDate(this.state.selectedperiod.value)}
                        </div>
                      } */
                      content={
                        this.state.open_tickets_support.data > 0 ?
                          <Doughnut
                            height={100}
                            style={{ marginTop: '10px' }}
                            data={this.state.open_tickets_support.value}
                            options={{
                              maintainAspectRatio: false,
                              showTooltips: false,
                              tooltips: { enabled: true },
                              plugins: { datalabels: { color: '#FFF' } },
                              centerText: {
                                display: true,
                                text: this.state.open_support_sum
                              }
                            }}

                            onElementsClick={(e) => {
                              this.clickChart(e);
                            }}

                            legend={{ display: false }}
                          /> : <div className="no-data-message">Nenhum chamado pendente suporte, aberto no período selecionado.</div>
                      }
                      legend={<Legend value={this.state.open_tickets_support.legend} />}

                    />
                  </Col>
                  <Col md={4}>
                    <Card
                      title="Pendente Cliente"
                      /* stats={
                        <div>
                          <i className="fa fa-clock-o" /> {this.formatDate(this.state.selectedperiod.value)}
                        </div>
                      } */
                      /* category="Por status" */
                      content={
                        this.state.open_tickets_client.data > 0 ?
                          <Doughnut
                            height={100}
                            data={this.state.open_tickets_client.value}
                            options={{
                              maintainAspectRatio: false,
                              showTooltips: false,
                              tooltips: { enabled: true },
                              plugins: { datalabels: { color: '#FFF' } },
                              centerText: {
                                display: true,
                                text: this.state.open_client_sum
                              }
                            }}

                            onElementsClick={(e) => {
                              this.clickChart(e);
                            }}

                            legend={{ display: false }}
                          /> : <div className="no-data-message">Nenhum chamado pendente cliente, aberto no período selecionado.</div>
                      }
                      legend={<Legend value={this.state.open_tickets_client.legend} />}

                    />
                  </Col>
                </Row>




                {/* <Row>
                  <Col md={12}>
                    <Card
                      title="Últimos chamados"
                      category=""     
                      content={
                        <table className="table">
                        <tr>
                          <th>Ticket</th>
                          <th>Título</th>
                          <th>Data de Abertura</th>
                          <th>Status</th>
                          <th>Tipo</th>
                          <th>&nbsp;</th>
                        </tr>
                          {this.state.last_tickets.length > 0 ? 
                            <Tasks tickets={this.state.last_tickets} /> : 
                            <tr><td>Esta organização ainda não possui tickets abertos no período selecionado.</td></tr>
                          }
                        </table>
                      }
                      stats={
                        <div>
                          <i style={{color:'#9305F2'}} /> {this.getDate()}
                        </div>
                      }
                    />
                  </Col>
                </Row> */}

              </Grid>}

          </div>
        }
      </div>
    );

  }
}

export default Dashboard;