import React, { Component } from "react";

import { Grid, Form } from "react-bootstrap";
import { login, logout } from "services/auth";

import Card from "components/Card/Card.jsx";
/* import logoLogin  from "assets/img/accLogoWhite.png"; */
import logoLogin from "assets/img/logoAccenture.svg";
import api from "services/api";
import "assets/login/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "assets/login/fonts/iconic/css/material-design-iconic-font.min.css";
import "assets/login/vendor/animate/animate.css";
import "assets/login/vendor/css-hamburgers/hamburgers.min.css";
import "assets/login/vendor/animsition/css/animsition.min.css";
import "assets/login/vendor/select2/select2.min.css";
import "assets/login/vendor/daterangepicker/daterangepicker.css";
import "assets/login/css/util.css";
import "assets/login/css/main.css";
import "assets/mfa/css/main.css";

import loading_icon from "assets/img/loading.gif";

class validationPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: "",
      loading: false,
      inputCode: ""
    };
    this.data = {
      userName: props.location.state.username,
      otpString: props.location.state.otpString,
    };
  }

  HandleInputChange = e => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({inputCode: e.target.value})
    }
  }

  //criar uma função para chamar a página reset e no botão Clique aqui.
  ForwardReset = e => {
    const routeState = {
      otpString: this.data.otpString,
      username: this.data.userName
    };
    this.props.history.push("/reset-mfa-page", routeState)
  }

  HandleValidate = async (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      const username = this.data.userName
      const otpString = this.data.otpString
      const inputCode = this.state.inputCode
      const response = await api.post("/mfa_validate.php", { username, inputCode, otpString });
      const remember = false
      
      if (response.data == 'Validation Error') {
        this.setState({ error: "Código inválido.", loading: false });
      } else if (response.data == 'User Blocked') {
        this.setState({ error: "Usuário bloqueado. Por favor, entre em contato com a administração.", loading : false });
      } else if (response.data.message == 'Successful login.') {
        login(
          response.data.jwt,
          remember,
          username,
          response.data.posid,
          response.data.name,
          response.data.company,
          response.data.gender,
          response.data.resp
        )
        this.setState({ loading: false });
        this.props.history.push("/dashboard")
      } else if (response.data == 'Timeout Error') {
        logout()
      } else {
        this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
      }

    } catch (error) {
      console.log(error)
    }

  }

  getLogo() {
    return (
      <div>{/* <img src={logoLogin} width={100} height={100} alt="Login" /> */}<div className="title-portal">Accenture <span className="songTitle">Song</span></div></div>
    )
  }

  componentDidMount() {
    sessionStorage.clear()

    if (localStorage.getItem('remember')) {
      this.setState({ username: localStorage.getItem('remember'), remember: true });
    }

    setTimeout(
      function () {
        this.setState({ cardHidden: false });
      }.bind(this),
      700
    );
  }
  render() {
    return (
      <Grid>
        <Card
          textCenter
          title={this.getLogo()}
          customClass="wrap-login100"
          content={
            <div>
              <Form onSubmit={this.HandleValidate} className="login100-form validate-form">
                <div className="lbl-form-login text-justify">
                  <label className="lbl-form-login">Insira abaixo o código exibido no seu aplicativo de autenticação. O código é único e é renovado a cada  30 segundos.</label>
                </div>

                <div className="mfa-code-container">
                  <input onChange={this.HandleInputChange} value={this.state.inputCode} className="mfa-code-container2" type="text" maxLength="6" size="6" min="0" max="9" pattern="[0-9]{6}" />
                  <span className="focus-mfa-input100"></span>
                </div>

                <div className="lbl-form-login text-center">
                  <label className="lbl-form-login">Não tem mais acesso ao aplicativo?&nbsp;<span class="lbl-form-login-btn-link" onClick={this.ForwardReset}>Clique aqui</span></label>
                </div>

                <div className="login-error">{this.state.error}</div>

                <div className="container-login100-form-btn">
                  <button type="submit" className="btnLogin">{this.state.loading ? <img src={loading_icon} className="loading-login" /> : ''} Login</button>
                </div>
              </Form>
              
            </div>
          }
          ftTextCenter
        />
      </Grid>
    );
  }
}

export default validationPage;
