import React, { Component } from "react";

import { NavLink } from "react-router-dom";

import api from "services/api";

class UserTabs extends Component{
    constructor(props) {
        super(props);
      }

      render(){
          return(

            <div>
                <ul className="nav nav-tabs">
                    <li><NavLink to={ '/user' }>Geral</NavLink></li>
                    <li><NavLink to={ '/changePassword' }>Alterar senha</NavLink></li>
                </ul>
            </div>
          );
      }
}

export default UserTabs;
