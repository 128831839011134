import React, { Component } from "react";

import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import Button from "components/CustomButton/CustomButton.jsx";

export class Tasks extends Component {
  /*constructor(props){
    super(props);
    this.state = {
      tickets : this.props.tickets,
    }
  }

  componentDidMount(){
    console.log(this.props.tickets);

    for (var i = 0; i < this.state.tickets.length; i++){

      if (this.state.tickets[i].title.length >=80){
        this.state.ticketMap[this.state.tickets[i].code] =  true;
      }
      
      else{
        this.state.ticketMap[this.state.tickets[i].code] =  false;
      }
      
    }
  }*/

  handleCheckbox = event => {
    const target = event.target;

    this.setState({
      [target.name]: target.checked
    });
  };

  redirectToTicket(e){
    e.persist();
    var tr = e.target.parentElement; //retorna a tag "pai" do objeto que foi clicado, nesse caso, a tag <tr>
    window.location.href = '/admtickets/view/' + tr.cells[0].textContent; //pega apenas a célula de indice 0 que é referente ao cód.ticket
  }


  render() {
    const edit = <Tooltip id="edit_tooltip">Editar ticket</Tooltip>;
    
    /*const tickets = [
      {'code' : 'TK-01-000006', 'title' : 'Erro ao exportar dados'    },
      {'code' : 'TK-01-000005', 'title' : 'Problema ao sincronizar'   },
      {'code' : 'TK-01-000004', 'title' : 'Erro ao gerar relatório'   },
      {'code' : 'TK-01-000003', 'title' : 'Melhorar fluxo'            },
      {'code' : 'TK-01-000002', 'title' : 'Novo relatório'            },
      {'code' : 'TK-01-000001', 'title' : 'Problema na base histórica'}
    ];*/

    const tickets = this.props.tickets !== undefined ? this.props.tickets : []
    var tasks = [];

    for (var i = 0; i < tickets.length; i++) {
      tasks.push(
        <tr key={i}
          onDoubleClick = {e => this.redirectToTicket(e)} 
          style={{fontSize : '14px'}}
        >

          <td /*onDoubleClick={e => this.redirectTicket(e)}*/>
            {tickets[i].code}
          </td>

          <td>
              {tickets[i].title.length >=80 ?
                tickets[i].title.substr(0,50)+'...' : tickets[i].title
              }
          </td>

          <td>
            {new Date(tickets[i].request_date).toLocaleDateString()}
          </td>

          <td>
            {tickets[i].status}
          </td>
            
          <td>
            {tickets[i].type}
          </td>
          <td className="td-actions text-right">
            <NavLink to={ '/admtickets/view/' + tickets[i].code}>
              <OverlayTrigger placement="top" overlay={edit}>
                <Button style={{color:'#9305F2'}} simple type="button" bsSize="xs">
                  <i className="fa fa-edit" />
                </Button>
              </OverlayTrigger>
            </NavLink>
          </td>    
        </tr>
      );
    }
    return <tbody>{tasks}</tbody>;
  }
}

export default Tasks;
