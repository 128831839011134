import React, { Component } from "react";

import Card from "components/Card/Card.jsx";
import { getToken } from "../../../services/auth";
import { Grid, Row, Col, ControlLabel, FormGroup, FormControl } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import TabForm from "../../../components/SharedForm/TabForm";
import ListOfVal from "../../../components/SharedForm/ListOfVal";

import { FilePond, registerPlugin, } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';

import SweetAlert from "react-bootstrap-sweetalert";

import api from "services/api";

import loading from "assets/img/loading.gif";

registerPlugin(FilePondPluginFileValidateType);

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading   : true,
      alert     : null,
      redirect  : false,
      error     : false,

      title_text      : '',

      rowid           : '',
      code            : '',
      title           : '',
 			status 			    : '',
			desc	          : '',
			requestor_email : '',
      requestor			  : '',
      type            : '',
      classification  : '',
      priority			  : '',
      effort          : '',
      ro_flg          : false,
      path            : '',
      filename        : '',
      file            : [],
      checked         : false,
      statuses        : [],

      form : {
        title       : { value : '', required : true},
        severity    : { value : '', required : true},
        desc        : { value : '', required : true},
        attachment  : { value : [], required : false},
      },

      formErrors : {
        title     : false,
        severity  : false,
        desc      : false
      },

      buttons : [],

      formMsg : false
    };
  }

  componentDidMount() {
    this.preLoadContent()
  }

  preLoadContent = async () => {
    try {
      const response = await api.post("/tickets.php", { 
        method  : 'preLoadContent_edit', 
        mode    : 'normal',
        code    : this.props.match.params.handle 
      });

      if (response.data == 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        api.post("/tickets.php", { 
          method  : 'getStatuses', 
        }).then((response)=>{
          if (response.data === 'Validation Error') {
            this.setState({ error: "Usuário e/ou senha inválida." });
          }
          else{
            this.setState({
              statuses : response.data.status_list
            });
          }
        });

        this.setState({
          title_text      : this.text_truncate(response.data.subject, 90, '...'),
          adm             : response.data.adm,
          rowid           : response.data.rowid,
          code            : response.data.code,
          title           : response.data.subject,
          status 			    : response.data.status,
          desc		        : response.data.description,
          effort          : response.data.effort,
          requestor_email : response.data.requestor_email,
          requestor			  : response.data.requestor,
          type            : response.data.type,
          classification  : response.data.classification,
          priority			  : response.data.priority,
          ro_flg          : response.data.ro_flg,
          path            : response.data.link,
          filename        : response.data.filename,
          resolution      : response.data.resolution,

          file : response.data.link != "" ? [{
            source  : response.data.link,
            options : {
              type: 'local',
              file: {
                name: response.data.filename,
                size: response.data.filesize
              }
            }
          }] : [],

          form : {
            title       : { value : response.data.subject,      required : true},
            severity    : { value : response.data.priority,     required : true},
            desc        : { value : response.data.description,  required : true}
          },

          loading : false,
          buttons : response.data.buttons
        });
      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }
  }

  text_truncate = function(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }

  handleTypeValidation = e => {
    e.preventDefault();

    let self = this
    let formMsg = false

    Object.keys(this.state.form).map(function (index) {
      self.setState(prevState => ({
          formErrors: {
            ...prevState.formErrors,
            [index] : self.state.form[index].value === "" && self.state.form[index].required ? true : false
          },
      }));

      formMsg = self.state.form[index].value === "" && self.state.form[index].required ? true : formMsg
    })

    this.setState({
      formMsg : formMsg
    })

    if (!formMsg)
      this.handleSubmit(e)
  }

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name] : value
    })

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name] : { 
          ...prevState.form[name],
          value : value 
        }
      },

      formErrors: {
        ...prevState.formErrors,
        [name] : value === "" ? true : false
      },
    }));

    let fmsg = false;
    let self = this;

    setTimeout( function() {
      if (document.getElementsByClassName('has-error').length >= 1) {
        fmsg = true;
      }
  
      self.setState({
        formMsg : fmsg
      })
 
    }, 300)

  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      loading : true
    });

    const { title, severity, desc, attachment } = this.state.form;

    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }

    const formData = new FormData();

    formData.append('method',       'update');
    formData.append('rowid',        this.state.rowid);
    formData.append('subject',      title.value);
    formData.append('priority',     severity.value);
    formData.append('description',  desc.value);

    if (attachment !== undefined && attachment.value[0] !== undefined) {
        formData.append('attachment', attachment.value[0]);
    }

    api.post('/tickets.php', formData, config).then((result) => {
      console.log(result.status)
      if(result.status === 200){
        this.setState({
          loading : false,
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            success 
            title="Obrigado!"
            onConfirm={() => this.successAlert()}>Ticket atualizado com sucesso!</SweetAlert>
          )
        })
        return
      }
      else if(result.status === 401){
        this.setState({
          loading : false,
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            error 
            title="Erro de conexão" 
            onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });
        return
      }
      else if(result.status === 500){
        this.setState({
          loading : false,
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            error 
            title="Erro interno do servidor" 
            onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });
        return
      }
      else{
        this.setState({
          loading : false,
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            error 
            title="Erro ao atualizar chamado" 
            onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });
      }

     /*  result.data.status === 'ok' ?

      this.setState({
        loading : false,
        alert   : (
          <SweetAlert 
          style={{ display: "block", marginTop: "-240px" }}
          success 
          title="Obrigado!"
          onConfirm={() => this.successAlert()}>Ticket atualizado com sucesso!</SweetAlert>
        )
      }) :

      this.setState({
        loading : false,
        alert   : (
          <SweetAlert 
          style={{ display: "block", marginTop: "-240px" }}
          error 
          title="Erro ao atualizar chamado" 
          onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
        )
      }); */

    });
  }

  onConfirmUpdateStatus = e => {
    e.preventDefault();

    const { id, value } = e.target;

    let msg   = ''

    switch (value) {
      case 'Aberto':
        msg = <span className="spanAlert">Confirmar reabertura do ticket?</span>
        break;
      case 'Homologação aprovada':
        msg = <span className="spanAlertBig">Ao aprovar a homologação deste ticket, você indica que o mesmo foi testado e aprovado em ambiente de homologação. Deseja prosseguir?</span>
        break;
      case 'Homologação reprovada':
        msg = <span className="spanAlertBig">Ao reprovar a homologação deste ticket, você indica que o mesmo foi testado e reprovado em ambiente de homologação. Deseja prosseguir?</span>
        break;
      case 'Finalizado':
        msg = <span className="spanAlertBig">Ao aprovar este ticket, ele será automaticamente finalizado. Deseja prosseguir?</span>
        break;
    }

    if (msg == '') {
      this.handleUpdateStatus(id, value)
    } else {
      this.setState({
        alert: (
          <SweetAlert 
                warning
                style={{ display: "block", marginTop: "-240px" }}
                showCancel
                confirmBtnText="Sim"
                cancelBtnText="Não"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="default"
                title={msg}
                onConfirm={() => this.handleUpdateStatus(id, value)}
                onCancel={() => this.hideAlert()}
          >Esta ação não poderá ser desfeita!</SweetAlert>
        )
      });
    }
  }

  handleUpdateStatus(id, value, reopen_flg='N') {

    this.setState({
      loading : true,
      alert   : null,
      checked: false
    });

    api.post("/tickets.php", { 
      method      : 'update_status_board_flow', 
      rowid       : id,
      status      : value,
      reopen_flag : reopen_flg
    }).then((response) => {
      if (response.data == 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        response.data.status === 'ok' ?

        this.setState({
          loading   : false,
          status    : value,

          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            success 
            title="Obrigado!"
            onConfirm={() => this.hideAlert()}>Status atualizado com sucesso!</SweetAlert>
          )
        }) :

        this.setState({
          loading : false,
          alert   : (
            <SweetAlert 
            style={{ display: "block", marginTop: "-240px" }}
            error 
            title="Erro ao atualizar o status do chamado" 
            onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });
      }
    })
  }

  handleCheckboxChange = event =>{
    this.setState({ checked: !this.state.checked});
    if(event.target.checked){
      this.setState({
        alert:(
          <SweetAlert 
            warning
            style={{ display: "block", marginTop: "-240px" }}
            showCancel
            confirmBtnText="Sim"
            cancelBtnText="Não"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={"Confirmação de reabertura"}
            onConfirm={() => this.handleUpdateStatus(this.state.rowid, 'Priorizado', 'Y')}
            onCancel={() => this.hideAlert()}>
          Reabrir chamado?
          </SweetAlert>)
      });

    }
  }


  successAlert() {
    this.setState({
      redirect : true
    });
  }

  hideAlert() {
    this.setState({
      alert : null,
      checked: false
    });
  }

  render() {

    let ticket = {
      'code'      : this.state.code,
      'title'     : this.state.title_text,
      'status'    : this.state.status,
      'severity'  : this.state.priority
    }
    const status_lst = this.state.statuses;

    return (
      this.state.redirect ? 
      <Redirect to="/tickets" /> :
      <div className="main-content">
        {this.state.alert}
        
        <div className={this.state.loading ? "loading-form" : "fadeOut loading-form" }>
          <img src={loading} alt="Carregando.." />
        </div>
        
        <HeaderForm ticket={ticket} status_lst = {status_lst}></HeaderForm>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    {/*<div className={this.state.loading ? "loading-form" : "fadeOut loading-form" }>
                      <img src={loading} alt="Carregando.." />
                    </div>*/}
                    <form className={this.state.loading ? "opacity form" : "fadeIn form" }>
                      <TabForm 
                        handle={this.state.code} 
                        active="home" 
                        showButtons={true} 
                        buttons={this.state.buttons[this.state.status] !== undefined ? this.state.buttons[this.state.status] : []}
                        rowid={this.state.rowid}
                        buttonclick={this.onConfirmUpdateStatus}
                      >
                      </TabForm>
                      {this.state.loading ? '' :
                      <div>
                        <div className="row">
                            <Col sm={this.state.effort != '' ? 11 : 12}>
                              <FormGroup className={this.state.formErrors.title ? "has-error" : ""}>
                                <ControlLabel>Título</ControlLabel>
                                <FormControl
                                  name="title" 
                                  type="text" 
                                  placeholder="Digite o título do chamado" 
                                  ref="title" 
                                  value={this.state.title} 
                                  readOnly={this.state.ro_flg ? true : false}
                                  onChange={this.handleChange} 
                                />
                              </FormGroup>
                              
                            </Col>

                            {this.state.effort != '' ?
                              <Col sm={1}>
                                <FormGroup>
                                  <ControlLabel>Esforço</ControlLabel>
                                  <FormControl
                                    name="effort" 
                                    type="text" 
                                    placeholder="" 
                                    ref="effort" 
                                    value={this.state.effort} 
                                    readOnly={true}
                                  />
                                </FormGroup>
                              </Col>
                            : ''}

                        </div>

                        <div className="row">
                            {this.state.status ==='Finalizado' ? 
                              <Col sm = {3}>
                                <label htmlFor="checkbox_id" style={{fontWeight:"normal", fontSize:"15px", marginBottom: "15px"}}> 
                                  Reabrir ticket 
                                  <span>&nbsp;&nbsp;</span>
                                </label>
                                <input
                                  id ="checkbox_id"
                                  type="checkbox"
                                  checked={this.state.checked}
                                  onChange={e=>{this.handleCheckboxChange(e)}}
                                />
                              </Col>: ''}
                        </div>

                        <div className="row">
                            <Col sm={12}>
                              <FormGroup>
                                  <ControlLabel>Severidade</ControlLabel>
                                  <ListOfVal
                                    name="severity" 
                                    reference="severity" 
                                    type="ADJUST_PRIORITY" 
                                    onChange={this.handleChange} 
                                    value={this.state.priority} 
                                    readOnly={/*this.state.ro_flg*/ true}
                                  />
                              </FormGroup>
                            </Col>

                        </div>

                        <div className="row">
                            <Col sm={6}>
                              <FormGroup>
                                  <ControlLabel>Tipo</ControlLabel>
                                  <ListOfVal 
                                    name="type" 
                                    reference="type" 
                                    type="ADJUSTMENT_TYPE" 
                                    value={this.state.type} 
                                    readOnly={true}
                                  />
                              </FormGroup>
                            </Col>

                            <Col sm={6}>
                              <FormGroup>
                                  <ControlLabel>Classificação</ControlLabel>
                                  <ListOfVal 
                                    name="classification" 
                                    reference="classification" 
                                    type="ADM_CLASS"
                                    value={this.state.classification} 
                                    readOnly={true}
                                  />
                              </FormGroup>
                            </Col>
                        </div>

                        <div className="row">
                          <Col sm={12}>
                            <FormGroup>
                                <ControlLabel>Descrição</ControlLabel>
                                <FormControl 
                                  name="desc"
                                  componentClass="textarea" 
                                  rows="3" 
                                  cols="80" 
                                  placeholder="Descreva aqui os detalhes do chamado" ref="desc" 
                                  onChange={this.handleChange}
                                  value={this.state.desc} 
                                  readOnly={/*this.state.ro_flg ?*/ true}
                                />
                            </FormGroup>
                          </Col>
                        </div>


                        <div className="row">
                          <Col sm={12}>
                            <FormGroup>
                              <ControlLabel>Anexo</ControlLabel>
                                {this.state.path !== '' ?
                                  <div className={this.state.loading ? "fadeOut link-anexo" : "fadeIn link-anexo" }>
                                    <a href={this.state.path + '&token='+getToken()} download={this.state.filename}>
                                      <span className="download-icon" title="Baixar arquivo"></span>
                                    </a>
                                  </div>
                                : ''}

                                <FilePond 
                                  labelIdle="Arraste e solte ou <span class='filepond--label-action'>procure</span> um arquivo"
                                  labelInvalidField="Arquivo inválido"
                                  labelFileWaitingForSize="Aguardando.."
                                  labelFileSizeNotAvailable="Tamanho não permitido"
                                  labelFileLoading=""
                                  labelFileLoadError="Erro durante o carregamento"
                                  labelFileProcessing="Carregando.."
                                  labelFileProcessingComplete="Upload completo"
                                  labelFileProcessingAborted="Upload cancelado"
                                  labelFileProcessingError="Erro durante o upload"
                                  labelFileProcessingRevertError="Erro ao reverter"
                                  labelFileRemoveError="Erro ao remover"
                                  labelTapToCancel="Cancelar"
                                  labelTapToRetry="Tentar novamente"
                                  labelTapToUndo="Desfazer"
                                  labelButtonRemoveItem="Remover"
                                  labelButtonAbortItemLoad="Abortar"
                                  labelButtonRetryItemLoad="Tentar novamente"
                                  labelButtonAbortItemProcessing="Cancelar"
                                  labelButtonUndoItemProcessing="Desfazer"
                                  labelButtonRetryItemProcessing="Tentar novamente"
                                  labelButtonProcessItem="Upload"
                                  files={this.state.file}
                                  instantUpload={false}
                                  ref={ref => this.pond = ref}
                                  allowMultiple={false}
                                  disabled={this.state.ro_flg ? true : false}
                                  labelButtonDownloadItem="Baixar arquivo"
                                  // onupdatefiles={(fileItems) => {
                                  //   this.setState(prevState => ({
                                  //     file : fileItems.map(fileItem => fileItem.file),
                                  //     form: {
                                  //       ...prevState.form,
                                  //       attachment : {
                                  //         ...prevState.form.attachment,
                                  //         value : fileItems.map(fileItem => fileItem.file)
                                  //       }
                                  //     }
                                  //   }));
                                  // }}
                                  allowFileTypeValidation={true}
                                  acceptedFileTypes={["image/*", "video/*", "application/pdf", "application/zip","application/vnd.rar","application/x-rar-compressed","application/vnd.ms-excel","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/x-zip-compressed","application/x-excel","application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document"]}
                                  fileValidateTypeLabelExpectedTypes="Tipos de arquivos suportados são: imagem, pdf, video, zip e rar "
                                  labelFileTypeNotAllowed="Tipo de arquivo não suportado "
                                  onaddfile={(error, source, test) => {
                                    const attachmentValue = !error ? [source.file] : []   
                                    this.setState(prevState => ({
                                      file : [source.file],
                                      form: {
                                        ...prevState.form,
                                        attachment : {
                                          ...prevState.form.attachment,
                                          value : attachmentValue
                                        }
                                      }
                                    }));
                                   }}
                                >
                              </FilePond>
                            </FormGroup>
                          </Col>
                        </div>
                      </div>}
                      <div>
                          <div className="row">
                          <Col sm={12}>
                                  <FormGroup>
                                      <ControlLabel>Resolução</ControlLabel>
                                      <FormControl 
                                      componentClass="textarea"
                                      disabled placeholder="" 
                                      ref="resolution"
                                      value={this.state.resolution} 
                                      rows="6"                                    
                                      />
                                  </FormGroup>
                            </Col>
                          </div>
                        </div>
                      {this.state.loading ? '' : <FooterForm /*url="tickets"*/ url={sessionStorage.getItem('previous-page')} disabled={this.state.ro_flg ? true : false} click={this.handleTypeValidation}></FooterForm>}
                    </form>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default View;
