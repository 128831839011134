import React, { Component } from "react";
import { Grid, Row, Col, ControlLabel, FormGroup, FormControl } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

import api from "services/api";
import { getToken } from "services/auth";

import loading from "assets/img/loading.gif";
import "react-select/dist/react-select.css";

import Select from "react-select";
import UserTabs from "../../components/SharedForm/UserTabs";

const passwordRequirement = ' - No mínimo 8 caracteres; \n' +
	' - No mínimo 1 número; \n' +
	' - No mínimo 1 caractere especial; \n' +
	' - No mínimo 1 letra maiúscula; \n' +
	' - No mínimo 1 letra minúscula; \n' +
	' - Não repetir o mesmo caractere por mais que 4 vezes; \n' +
	' - Não usar padrões de teclado (qwerty, 1234, etc); \n' +
	' - Ser diferente das últimas 24 senhas usadas (se houver). \n';

class ChangePassPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			alert: null,
			currentpassword: '',
			newpassword: '',
			confirmnewpassword: '',

			form: {
				currentpassword: { value: '', required: true },
				newpassword: { value: '', required: true },
				confirmnewpassword: { value: '', required: true }
			},

			formErrors: {
				currentpassword: false,
				newpassword: false,
				confirmnewpassword: false
			},

			selectedpostn: '',
			postns: []

		};

	}
	componentDidMount() {
		this.preLoadContent()
	}

	preLoadContent = async () => {
		try {
			const response = await api.post("/user.php", {
				method: 'preLoadContent',
				username: sessionStorage.getItem('username')
			});

			if (response.data == 'Validation Error') {
				this.setState({ error: "Usuário e/ou senha inválida." });
			} else {

				this.setState({
					selectedpostn: (sessionStorage.getItem('postn') !== '' ? sessionStorage.getItem('postn') : response.data.selectedpostn),
					postns: response.data.postns,

					loading: false

				});
			}

		} catch (err) {
			this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
		}
	}

	handleTypeValidation = e => {
		e.preventDefault();

		let self = this
		let formMsg = false

		Object.keys(this.state.form).map(function (index) {

			self.setState(prevState => ({
				formErrors: {
					...prevState.formErrors,
					[index]: self.state.form[index].value === "" && self.state.form[index].required ? true : false
				},
			}));

			formMsg = self.state.form[index].value === "" && self.state.form[index].required ? true : formMsg
		})

		this.setState({
			formMsg: formMsg
		})

		if (!formMsg)
			this.handleSubmit(e)
	}

	handleChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		this.setState({
			[name]: value

		})

		this.setState(prevState => ({
			form: {
				...prevState.form,
				[name]: {
					...prevState.form[name],
					value: value
				}


			},

			formErrors: {
				...prevState.formErrors,
				[name]: value === "" ? true : false

			},
		}));

		let fmsg = false;
		let self = this;

		setTimeout(function () {
			if (document.getElementsByClassName('has-error').length >= 1) {
				fmsg = true;
			}

			self.setState({
				formMsg: fmsg
			})

		}, 300)

	}

	handleSubmit = e => {
		e.preventDefault();

		this.setState({
			loading: true
		});

		const { currentpassword, newpassword, confirmnewpassword } = this.state.form;

		const old_password = currentpassword;
		const new_password = newpassword;
		const confirm_password = confirmnewpassword;

		const token = getToken();
		console.log(token)

		if (old_password.value == '' || new_password.value == '' || confirm_password.value == ''){
			this.setState({
				loading: false,
				alert: (
					<SweetAlert
						style={{ display: "block", marginTop: "-240px", width: "500px" }}
						key = '1'
						error
						title="Erro!"
						onConfirm={() => {
							this.hideAlert();
						}}
					>
						<div className="lbl-form-new-password-alert">
							<label className="lbl-form-new-password-alert">Por favor, preencha todos os campos.</label>
						</div>
					</SweetAlert>
				)
			});
		}
		else if (new_password.value != confirm_password.value){
			this.setState({
				loading: false,
				alert: (
					<SweetAlert
						style={{ display: "block", marginTop: "-240px", width: "500px" }}
						key = '1'
						error
						title="Erro!"
						onConfirm={() => {
							this.hideAlert();
						}}
					>
						<div className="lbl-form-new-password-alert">
							<label className="lbl-form-new-password-alert">As senhas inseridas são diferentes.</label>
						</div>
					</SweetAlert>
				)
			});
		}
		else {

			api.post("/changePassword.php", { old_password, new_password, confirm_password, token }).then((result) => {

				result.data.status == true 
				?

					this.setState({
						loading: false,
						alert: (
							<SweetAlert
								style={{ display: "block", marginTop: "-240px" , width: "500px"}}
								success
								title="Obrigado!"
								onConfirm={() => this.hideAlert()}
							> 
								{result.data.message} 
							</SweetAlert>
						)
					}) 
				:

					this.setState({
						loading: false,
						alert: (
							<SweetAlert
								style={{ display: "block", marginTop: "-240px" , width: "500px"}}
								error
								title="Erro!"
								onConfirm={() => this.hideAlert()}
							> 
								{result.data.message.split(" / ").map(function (requirement) {
									return (
										<div className="lbl-form-new-password-alert">
											<label className="lbl-form-new-password-alert">{requirement}</label>
										</div>
									)
								})}
							</SweetAlert>
						),
						form: {
							currentpassword: { value: '', required: true },
							newpassword: { value: '', required: true },
							confirmnewpassword: { value: '', required: true }
						},

					});
			});
		}
	}

	hideAlert() {
		this.setState({
			alert: null
		});
	}



	render() {
		return (
			<div className="main-content">
				{this.state.alert}
				<Grid fluid>
					<Row>
						<Col md={7}>
							<Card
								title="Editar Perfil"
								content={
									<div>
										<div className={this.state.loading ? "loading-form" : "fadeOut loading-form"}>
											<img src={loading} alt="Carregando.." />
										</div>
										<form className={this.state.loading ? "opacity form-user" : "fadeIn form-user"}>
											<UserTabs></UserTabs>
											{this.state.loading ? '' :
												<div>
													<div className="row">
														<Col sm={5}>
															<FormGroup className={this.state.formErrors.currentpassword ? "has-error" : ""}>
																<ControlLabel>Senha atual</ControlLabel>
																<FormControl
																	name="currentpassword"
																	type="password"
																	placeholder="Senha atual"
																	ref="currentpassword"
																	onChange={this.handleChange}

																/>
															</FormGroup>
														</Col>
													</div>

													<div className="row">
														<Col sm={5}>
															<FormGroup className={this.state.formErrors.newpassword ? "has-error" : ""}>
																<ControlLabel>Nova senha</ControlLabel>
																<FormControl
																	name="newpassword"
																	type="password"
																	placeholder="Nova senha"
																	ref="newpassword"
																	onChange={this.handleChange}

																/>
															</FormGroup>
														</Col>
													</div>

													<div className="row">
														<Col sm={5}>
															<FormGroup className={this.state.formErrors.confirmnewpassword ? "has-error" : ""}>
																<ControlLabel>Confirmar senha</ControlLabel>
																<FormControl
																	name="confirmnewpassword"
																	type="password"
																	placeholder="Confirmar senha"
																	ref="confirmnewpassword"
																	onChange={this.handleChange}

																/>
															</FormGroup>
														</Col>
													</div>

													<div className="row">
														<button className="btnResetPassword" id="update-user" pullRight fill type="button" onClick={this.handleTypeValidation}>Alterar Senha</button>
													</div>
												</div>}
										</form>
									</div>
								}
							/>
						</Col>

						<Col>
							<div className="lbl-form-update-password-title text-justify">
								<label className="lbl-form-update-password-title">Sua senha deve conter:</label>
							</div>

							{passwordRequirement.split("\n").map(function (requirement) {
								return (
									<div className="lbl-form-update-password-requirement text-justify ">
										<label className="lbl-form-update-password-requirement ">{requirement}</label>
									</div>
								)
							})}

							
						</Col>

					</Row>
				</Grid>



			</div>
		);
	}
}

export default ChangePassPage;