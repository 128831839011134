import React, { Component } from "react";

import { Grid, Form } from "react-bootstrap";
import { getToken } from "services/auth";
import SweetAlert from "react-bootstrap-sweetalert";

import Card from "components/Card/Card.jsx";
/* import logoLogin  from "assets/img/accLogoWhite.png"; */
import logoLogin from "assets/img/logoAccenture.svg";
import api from "services/api";

import "assets/login/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "assets/login/fonts/iconic/css/material-design-iconic-font.min.css";
import "assets/login/vendor/animate/animate.css";
import "assets/login/vendor/css-hamburgers/hamburgers.min.css";
import "assets/login/vendor/animsition/css/animsition.min.css";
import "assets/login/vendor/select2/select2.min.css";
import "assets/login/vendor/daterangepicker/daterangepicker.css";
import "assets/login/css/util.css";
import "assets/login/css/main.css";
import "assets/mfa/css/main.css";
import bgImage from "assets/img/accentureLoginNew.png";

import loading_icon from "assets/img/loading.gif";

const passwordRequirement = ' - No mínimo 8 caracteres; \n' +
	' - No mínimo 1 número; \n' +
	' - No mínimo 1 caractere especial; \n' +
	' - No mínimo 1 letra maiúscula; \n' +
	' - No mínimo 1 letra minúscula; \n' +
	' - Não repetir o mesmo caractere por mais que 4 vezes; \n' +
	' - Não usar padrões de teclado (qwerty, 1234, etc); \n' +
	' - Ser diferente das últimas 24 senhas usadas (se houver). \n';

class NewPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cardHidden: true,

			newpassword: "",
			confirmnewpassword: "",
			currentpassword: "",
			error: "",
			loading: false,
			alert: null,
			selectedpostn: '',
			postns: [],

			form: {
				currentpassword: { value: '', required: true },
				newpassword: { value: '', required: true },
				confirmnewpassword: { value: '', required: true }
			},

			formErrors: {
				currentpassword: false,
				newpassword: false,
				confirmnewpassword: false
			},
		};

		this.data = {
			token: props.location.state.otpString,
			userName: props.location.state.username
		};
	}

	getLogo() {
		return (
			<div>{/* <img src={logoLogin} width={100} height={100} alt="Login" /> */}<div className="title-portal">Accenture <span className="songTitle">Song</span></div></div>
		)
	}

	/*componentDidMount() {
	  this.preLoadContent()
	}
  
	preLoadContent = async () => {
	  try {
		const response = await api.post("/user.php", { 
		  method    : 'preLoadContent', 
		  username  : sessionStorage.getItem('username')
		});
  
		if (response.data == 'Validation Error') {
			this.setState({ error: "Usuário e/ou senha inválida." });
		} else {
  
		  this.setState({
			selectedpostn : (sessionStorage.getItem('postn') !== '' ? sessionStorage.getItem('postn') : response.data.selectedpostn),
			postns        : response.data.postns,
  
			loading : false
		    
		  });
		}
  
	  } catch (err) {
		this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
	  }
	}*/

	handleChange = e => {
		e.preventDefault();
		const { name, value } = e.target;
		console.log('handleChange name:', name);
		console.log('handleChange value:', value);

		this.setState({
			[name]: value

		})

		this.setState(prevState => ({
			form: {
				...prevState.form,
				[name]: {
					...prevState.form[name],
					value: value
				}


			},

			formErrors: {
				...prevState.formErrors,
				[name]: value === "" ? true : false

			},
		}));

		let fmsg = false;
		let self = this;

		setTimeout(function () {
			if (document.getElementsByClassName('has-error').length >= 1) {
				fmsg = true;
			}

			self.setState({
				formMsg: fmsg
			})

		}, 300)

	}

	handleSignIn = async e => {
		e.preventDefault();

		this.setState({
			loading: true
		});

		const { newpassword, confirmnewpassword } = this.state.form;

		const password = newpassword.value;
		const confirm_password = confirmnewpassword.value;
		const token = this.data.token;

		if (password === '' || confirm_password === '') {
			this.setState({
				loading: false,
				alert: (
					<SweetAlert
						style={{ display: "block", marginTop: "-240px", width: "500px" }}
						key = '1'
						error
						title="Erro!"
						onConfirm={() => {
							this.hideAlert();
						}}
					>
						<div className="lbl-form-new-password-alert">
							<label className="lbl-form-new-password-alert">Por favor, preencha todos os campos.</label>
						</div>
					</SweetAlert>
				)
			});
		} else if (password != confirm_password) {
			this.setState({
				loading: false,
				alert: (
					<SweetAlert
						style={{ display: "block", marginTop: "-240px" , width: "500px" }}
						key = '2'
						error
						title="Erro!"
						onConfirm={() => {
							this.hideAlert();
						}}
					>
						<div className="lbl-form-new-password-alert">
							<label className="lbl-form-new-password-alert">As senhas inseridas são diferentes.</label>
						</div>
					</SweetAlert>
				)
			});
		} else {
			

			const response = await api.post("/resetPassword.php", { password, confirm_password, token });
			console.log(response);
			if (response.data.status == true) {
				this.setState({
					loading: false,
					alert: (
						<SweetAlert
							style={{ display: "block", marginTop: "-240px" , width: "500px" }}
							key = '3'
							success
							title="Senha atualizada!"
							onConfirm={() => {
								this.hideAlert();
								this.props.history.push("/login")
							}}
						>
							<div className="lbl-form-new-password-alert">
								<label className="lbl-form-new-password-alert">{response.data.message}</label>
							</div>
							
						</SweetAlert>
					)
				});

			} else {
				this.setState({
					loading: false,
					alert: (
						<SweetAlert
							style={{ display: "block", marginTop: "-240px" , width: "500px"  }}
							key = '4'
							error
							title="Erro!"
							onConfirm={() => {
								this.hideAlert();
							}}
						>
							{response.data.message.split(" \/ ").map(function (requirement) {
								return (
									<div className="lbl-form-new-password-alert">
										<label className="lbl-form-new-password-alert">{requirement}</label>
									</div>
								)
							})}
						</SweetAlert>
					)
				});
			}
	
			//this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
			
		}
	}

	hideAlert() {
		this.setState({
			alert: null
		});
	}

	render() {
		return (
			<div className="wrapper wrapper-full-page">
				<div className={"full-page"} data-image={bgImage}>
					<div className="content">
						{this.state.alert }
						<Grid>
							<Card
								hidden={this.state.cardHidden}
								textCenter
								title={this.getLogo()}
								customClass="wrap-login100"
								content={
									<div>
										<Form onSubmit={this.handleSignIn} className="login100-form validate-form">
											<div className="lbl-form-new-password text-justify">
												<label className="lbl-form-new-password">Sua senha está expirada! Por favor, cadastre uma nova senha.</label>
											</div>

											<div className="lbl-form-new-password text-justify">
												<label className="lbl-form-new-password">Sua senha deve conter:</label>
											</div>

											{passwordRequirement.split("\n").map(function (requirement) {
												return (
													<div className="lbl-form-new-password-requirement text-justify ">
														<label className="lbl-form-new-password-requirement ">{requirement}</label>
													</div>
												)
											})}

											<div className="wrap-input100 validate-input">
												<input className="input100" type="password" name="newpassword" placeholder="Nova senha" onChange={this.handleChange} />
												<span className="focus-input100" data-placeholder="&#xf191;"></span>
											</div>

											<div className="wrap-input100 validate-input">
												<input className="input100" type="password" name="confirmnewpassword" placeholder="Confirme a nova senha" onChange={this.handleChange} />
												<span className="focus-input100" data-placeholder="&#xf191;"></span>
											</div>

											<div className="login-error">{this.state.error}</div>

											<div className="container-login100-form-btn">
												<button type="submit" className="btnLogin">{this.state.loading ? <img src={loading_icon} className="loading-login" /> : ''} Atualizar senha</button>
											</div>

										</Form>
									</div>
								}
								ftTextCenter
							/>
						</Grid>
					</div>
					<div className="full-page-background"
						style={{ backgroundImage: "url(" + bgImage + ")" }} />
				</div>
			</div>
		);
	}
}

export default NewPassword;
