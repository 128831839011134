import React, { Component } from "react";

import { severity } from "variables/Variables.jsx";

class Severity extends Component {

  render() {
    return (
      ((this.props.level != "" && typeof severity[this.props.level] !== 'undefined') ?
        <div className={"img-table circle_" + severity[this.props.level]['num']} title={severity[this.props.level]['title']}></div>
      : "")
    );
  }
}

export default Severity;