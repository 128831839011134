import Dashboard from "views/Dashboard/Dashboard.jsx";
import Report from "views/Report/Report.jsx";
import Tickets from "views/Tickets/Tickets.jsx";
import AdmTickets from "views/AdmTickets/AdmTickets.jsx";
import BoardPage from "views/Pages/BoardPage.jsx";
import BIPage from "views/Pages/BIPage.jsx";
import TimeReport2 from "views/TimeReport2/TimeReport2.jsx";;
var dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "pe-7s-graph",
    component: Dashboard,
    resp: false
  },
  {
    path: "/report",
    name: "Report de horas",
    icon: "pe-7s-clock",
    component: Report,
    resp: false
  },
  {
    path: "/tickets",
    name: "Tickets",
    icon: "pe-7s-note",
    component: Tickets,
    resp: false
  },
  {
    path: "/admtickets",
    name: "Adm. Tickets",
    icon: "pe-7s-comment",
    component: AdmTickets,
    resp: '4-6-c4f4'
  },
  {
    path: "/board-view",
    name: "Board",
    icon: "pe-7s-display1",
    component: BoardPage,
    resp: false
  },
 /*  {
    path: "/BI",
    name: "PowerBI",
    icon: "pe-7s-graph1",
    component: BIPage,
    resp: 'a228f118-7ad2-9e7b-6190-fa6dc4e176e1'
  }, */
  {
    path: "/tr-portal",
    name: "Time Report - Portal",
    icon: "pe-7s-wristwatch",
    component: TimeReport2,
    resp: '4-6-c4f4'
  },
  { redirect: true, path: "/", pathTo: "/dashboard", name: "Dashboard" }
];

export default dashboardRoutes;
