import React, { Component } from "react";

import Card from "components/Card/Card.jsx";

import { Grid, Row, Col } from "react-bootstrap";

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import TabForm from "../../../components/SharedForm/TabForm";

import Timeline from '../Hist/timeline';
import 'react-vertical-timeline-component/style.min.css';

import Announcement   from '@material-ui/icons/Announcement.js';
import AssignmentInd  from '@material-ui/icons/AssignmentInd.js';
import Build          from '@material-ui/icons/Build.js';
import Done           from '@material-ui/icons/Done.js';
import DoneAll        from '@material-ui/icons/DoneAll.js';
import CheckCircle    from '@material-ui/icons/CheckCircle.js';
import VerifiedUser   from '@material-ui/icons/VerifiedUser.js';
import StarIcon       from '@material-ui/icons/Star.js';
import Warning        from '@material-ui/icons/Warning.js';
import Pause          from '@material-ui/icons/Pause.js';
import Error          from '@material-ui/icons/Error.js';
import Cancel         from '@material-ui/icons/Cancel.js';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer.js';
import Help           from '@material-ui/icons/Help.js';
import CheckBox       from '@material-ui/icons/CheckBox.js';
import Input          from '@material-ui/icons/Input.js';

import { Redirect } from "react-router-dom";

import api      from "services/api";
import loading  from "assets/img/loading.gif";

class Hist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adm       : (window.location.href.indexOf("adm") > -1 ? true : false),
      url       : (window.location.href.indexOf("adm") > -1 ? 'admtickets' : 'tickets'),

      loading   : true,
      redirect  : false,
      error     : false,

      rowid       : '',
      code        : '',
      title_text  : '',
 			status      : '',
      priority    : '',

      initial     : '',
      created_by  : '',
      created     : '',

      historic    : []
    };
  }

  componentDidMount() {
    this.preLoadContent()
  }

  preLoadContent = async () => {
    try {
      const response = await api.post("/tickets.php", { method : 'historic', code : this.props.match.params.handle });

      if (response.data == 'Validation Error') {
          this.setState({ error: "Usuário e/ou senha inválida." });
      } else {
        this.setState({

          rowid       : response.data.rowid,

          code        : response.data.code,
          title_text  : this.text_truncate(response.data.subject, 90, '...'),
          status 			: response.data.status,
          priority    : response.data.priority,

          initial	    : response.data.initial,
          created_by  : response.data.created_by,
          created		  : response.data.created,

          historic    : response.data.historic,

          loading : false
        });
      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading : false });
    }
  }

  text_truncate = function(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  /*getIcon = function(status) {
    switch (status) {

      case 'Aberto'                 : { return (<Announcement />)}
      case 'Melhoria Pendente'      : { return (<QuestionAnswer />)}
      case 'Validado'               : { return (<CheckBox />)}
      case 'Atribuído'              : { return (<AssignmentInd />)}
      case 'Em desenvolvimento'     : { return (<Build />)}
      case 'Pendente fornecedor'    : { return (<Input />)}
      case 'Pendente Informação'    : { return (<Help />)}
      case 'Parado'                 : { return (<Pause />)}
      case 'Em Homologação'         : { return (<Done />)}
      case 'Homologação aprovada'   : { return (<DoneAll />)}
      case 'Homologação reprovada'  : { return (<Error />)}
      case 'Pendente aprovação'     : { return (<CheckCircle />)}
      case 'Reprovado'              : { return (<Warning />)}
      case 'Cancelado'              : { return (<Cancel />)}
      case 'Finalizado'             : { return (<VerifiedUser />)}

      default: {return (<AssignmentInd />) }
    }
  }

  getActionResp = function(status) {
    switch (status) {

      case 'Aberto'                 :
      case 'Validado'               :
      case 'Pendente Informação'    :
      case 'Parado'                 :
      case 'Homologação aprovada'   :
      case 'Homologação reprovada'  :
      case 'Reprovado'              :
      case 'Cancelado'              :
      case 'Finalizado'             : { return ('extern')}

      case 'Pendente fornecedor'    :
      case 'Melhoria Pendente'      :
      case 'Pendente fornecedor'    :
      case 'Atribuído'              :
      case 'Em desenvolvimento'     :
      case 'Em Homologação'         :
      case 'Pendente aprovação'     : { return ('intern')}

      default: {return (<AssignmentInd />) }
    }
  }*/

  render() {
    let handle = this.props.match.params.handle;

    let ticket = {
      'code'      : this.state.code,
      'title'     : this.state.title_text,
      'status'    : this.state.status,
      'severity'  : this.state.priority
    }

    return (
      this.state.redirect ? 
      <Redirect to="/tickets" /> :
      <div className="main-content">
        <HeaderForm ticket={ticket}></HeaderForm>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <div>
                    <div className={this.state.loading ? "loading-form" : "fadeOut loading-form" }>
                      <img src={loading} alt="Carregando.." />
                    </div>
                    <form className={this.state.loading ? "opacity form" : "fadeIn form" }>
                      <TabForm handle={handle} active="hist" admin={this.state.adm}></TabForm>
                        <div className="box-vertical-timeline">
                          {this.state.initial != '' ?
                            <Timeline
                              st_initial = {this.state.initial}
                              c_by = {this.state.created_by}
                              created = {this.state.created}
                              hist = {this.state.historic}
                            />

                          : ''}
                        </div>
                      {this.state.initial != '' ?
                        <FooterForm url={this.state.url}></FooterForm>
                      : ''}
                    </form>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Hist;