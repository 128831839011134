import React from "react";
import ReactExport from "react-export-excel";
import Button      from "components/CustomButton/CustomButton.jsx";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class ExcelExport extends React.Component {

    render() {
        return (
            <ExcelFile filename="Extração TR2" 
                       element={
                            <button className="btnResetPassword" style = {{float:'right',marginBottom:'10px'}} simple type="button" bsSize="xs" >
                                Extrair horas
                            </button>
                        }
            >
                <ExcelSheet data={this.props.dataset} name="Horas">
                    <ExcelColumn label="Colaborador" value="person_name"/>
                    <ExcelColumn label="Período" value="period_name"/>
                    <ExcelColumn label="Centro de custos" value="cc_name"/>
                    <ExcelColumn label="Data" value="date"/>
                    <ExcelColumn label="Cod.Ticket" value="tk_code"/>
                    <ExcelColumn label="Comentários" value="comments"/>
                    <ExcelColumn label="Hora" value="hours"/>
                    <ExcelColumn label="Extra" value="extra_hours"/>
                    <ExcelColumn label="Noturna" value="night_hours"/>
                    <ExcelColumn label="Sobreaviso" value="warning_hour"/>
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
export default ExcelExport;