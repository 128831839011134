import React, { Component } from "react";

import Card from "components/Card/Card.jsx";

import { Grid, Row, Col } from "react-bootstrap";

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import TabForm from "../../../components/SharedForm/TabForm";

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import Announcement from '@material-ui/icons/Announcement.js';
import AssignmentInd from '@material-ui/icons/AssignmentInd.js';
import Build from '@material-ui/icons/Build.js';
import Done from '@material-ui/icons/Done.js';
import DoneAll from '@material-ui/icons/DoneAll.js';
import CheckCircle from '@material-ui/icons/CheckCircle.js';
import VerifiedUser from '@material-ui/icons/VerifiedUser.js';
import StarIcon from '@material-ui/icons/Star.js';

class Hist extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let handle = this.props.match.params.handle;

    let ticket = {
      'code'      : this.props.match.params.handle,
      'title'     : 'Erro ao exportar dados',
      'status'    : 'Reprovado',
      'severity'  : '1'
    }

    return (
      <div className="main-content">
        <HeaderForm ticket={ticket}></HeaderForm>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                content={
                  <form>
                    <TabForm handle={handle} active="hist"></TabForm>
                    <VerticalTimeline>
                      <VerticalTimelineElement
                        className="vertical-timeline-element--extern"
                        date="11/04/2019"
                        iconStyle={{ background: 'rgb(177, 169, 171)', color: '#eee' }}
                        icon={<Announcement />}
                      >
                        <h3 className="vertical-timeline-element-title">Aberto</h3>
                        <h4 className="vertical-timeline-element-subtitle">Renan Guedes</h4>
                      </VerticalTimelineElement>
                      <VerticalTimelineElement
                        className="vertical-timeline-element--intern"
                        date="12/04/2019"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#eee' }}
                        icon={<AssignmentInd />}
                      >
                        <h3 className="vertical-timeline-element-title">Atribuído</h3>
                        <h4 className="vertical-timeline-element-subtitle">Rodrigo Alves</h4>
                      </VerticalTimelineElement>
                      <VerticalTimelineElement
                        className="vertical-timeline-element--intern"
                        date="13/04/2019"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#eee' }}
                        icon={<Build />}
                      >
                        <h3 className="vertical-timeline-element-title">Em desenvolvimento</h3>
                        <h4 className="vertical-timeline-element-subtitle">Rodrigo Alves</h4>
                      </VerticalTimelineElement>
                      <VerticalTimelineElement
                        className="vertical-timeline-element--intern"
                        date="15/04/2019"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#eee' }}
                        icon={<Done />}
                      >
                        <h3 className="vertical-timeline-element-title">Em homologação</h3>
                        <h4 className="vertical-timeline-element-subtitle">Rodrigo Alves</h4>
                      </VerticalTimelineElement>
                      <VerticalTimelineElement
                        className="vertical-timeline-element--extern"
                        date="17/04/2019"
                        iconStyle={{ background: 'rgb(177, 169, 171)', color: '#eee' }}
                        icon={<DoneAll />}
                      >
                        <h3 className="vertical-timeline-element-title">Homologação aprovada</h3>
                        <h4 className="vertical-timeline-element-subtitle">Renan Guedes</h4>
                      </VerticalTimelineElement>
                      <VerticalTimelineElement
                        className="vertical-timeline-element--intern"
                        date="17/04/2019"
                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#eee' }}
                        icon={<CheckCircle />}
                      >
                        <h3 className="vertical-timeline-element-title">Pendente aprovação</h3>
                        <h4 className="vertical-timeline-element-subtitle">Rodrigo Alves</h4>
                      </VerticalTimelineElement>
                      <VerticalTimelineElement
                        className="vertical-timeline-element--extern"
                        date="18/04/2019"
                        iconStyle={{ background: 'rgb(177, 169, 171)', color: '#eee' }}
                        icon={<VerifiedUser />}
                      >
                        <h3 className="vertical-timeline-element-title">Finalizado</h3>
                        <h4 className="vertical-timeline-element-subtitle">Renan Guedes</h4>
                      </VerticalTimelineElement>
                      <VerticalTimelineElement
                        iconStyle={{ background: 'rgb(58, 224, 116)', color: '#eee' }}
                        icon={<StarIcon />}
                      />
                    </VerticalTimeline>
                    <FooterForm url="admtickets"></FooterForm>
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Hist;