import React, { Component } from "react";

import ReactTable from "react-table";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import { Grid, Row, Col, ControlLabel, FormGroup, FormControl, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Redirect } from "react-router-dom";

import HeaderForm from "../../../components/SharedForm/HeaderForm";
import FooterForm from "../../../components/SharedForm/FooterForm";
import TabForm from "../../../components/SharedForm/TabForm";

import Datetime from "react-datetime";

import SweetAlert from "react-bootstrap-sweetalert";

import api from "services/api";

import loading from "assets/img/loading.gif";

import Autosuggest from 'react-autosuggest';

const theme = {
  container: {
    position: 'relative'
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    display: 'block',
    position: 'absolute',
    top: 39,
    width: "100%",
    border: '1px solid #aaa',
    backgroundColor: '#fff',
    fontFamily: 'inherit',
    fontSize: 14,
    zIndex: 2
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 12px'
  },
  suggestionHighlighted: {
    backgroundColor: '#eee'
  }
};

// Use your imagination to render suggestions.
const renderSuggestion_period = suggestion_period => (
  <div>
    {suggestion_period.name}
  </div>
);

const renderSuggestion_cc = suggestion_cc => (
  <div>
    {suggestion_cc.name}
  </div>
);

class TimeReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      loading_report: true,

      alert: null,
      redirect: false,
      error: false,

      form_mode: false,

      code: '',
      title_text: '',
      status: '',
      priority: '',

      rows: [],

      rowid: '',

      period: '',
      period_name: '',
      period_status: '',
      period_start: '',
      period_final: '',

      cc: '',
      cc_name: '',

      date: '',
      hour: '',
      extra_hour: '',
      night_hour: '',
      warning_hour: '',
      comments: '',
      statuses: [],
      form: {
        period: { value: '', required: true },
        cc: { value: '', required: true },
        date: { value: '', required: true },
        hour: { value: '', required: true },
        extra_hour: { value: '', required: false },
        night_hour: { value: '', required: false },
        warning_hour: { value: '', required: false },
        comments: { value: '', required: true }
      },

      formErrors: {
        period: false,
        cc: false,
        date: false,
        hour: false,
        comments: false
      },

      formMsg: false,

      // Suggest

      suggestions_period: [],
      suggestions_cc: [],

      periods: [],
      ccs: []
    };
  }

  componentDidMount() {
    this.preLoadContent()
  }

  preLoadContent = async () => {
    try {
      const response = await api.post("/tickets.php", {
        method: 'timereport',
        code: this.props.match.params.handle
      });

      if (response.data === 'Validation Error') {
        this.setState({ error: "Usuário e/ou senha inválida." });
      } else {

        api.post("/tickets.php", {
          method: 'getStatuses',
        }).then((response) => {
          if (response.data === 'Validation Error') {
            this.setState({ error: "Usuário e/ou senha inválida." });
          }
          else {
            this.setState({
              statuses: response.data.status_list
            });
          }
        });

        this.setState({

          rowid: response.data.rowid,

          code: response.data.code,
          title_text: this.text_truncate(response.data.subject, 90, '...'),
          status: response.data.status,
          priority: response.data.priority,

          rows: response.data.timereport.map((item) => {
            return {
              rowid: item.rowid,

              period: item.period,
              period_name: item.period_name,
              period_status: item.period_status,
              period_start: item.period_start,
              period_final: item.period_final,

              cc: item.cc,
              cc_code: item.cc_code,
              cc_name: item.cc_name,
              date: item.date,
              hour: item.hour,
              extra_hour: item.extra_hour,
              night_hour: item.night_hour,
              warning_hour: item.warning_hour,
              comments: item.comments,

              actions: (
                <div className="actions-right">
                  {item.ro_flg !== 'Y' ?
                    <div>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="edit_tooltip">Editar</Tooltip>}>
                        <Button style={{ color: '#9305F2' }} onClick={this.onEditItem.bind(this, item.rowid)} bsStyle="info" simple type="button" bsSize="xs">
                          <i className="fa fa-edit" />
                        </Button>
                      </OverlayTrigger>
                      <OverlayTrigger placement="top" overlay={<Tooltip id="edit_tooltip">Remover</Tooltip>}>
                        <Button style={{ color: '#9305F2' }} content={item} onClick={this.onConfirmRemoveItem.bind(this, item.rowid)} bsStyle="info" simple type="button" bsSize="xs">
                          <i className="fa fa-remove" />
                        </Button>
                      </OverlayTrigger>
                    </div>
                    : ''}
                </div>
              )
            }
          }),

          loading: false,
          loading_report: false
        });

      }

    } catch (err) {
      this.setState({ error: "Não foi possível conectar-se à base de dados.", loading: false });
    }
  }

  text_truncate = function (str, length, ending) {
    if (length === null) {
      length = 100;
    }
    if (ending === null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  handleTypeValidation = e => {
    e.preventDefault();

    let self = this
    let formMsg = false

    Object.keys(this.state.form).map(function (index) {
      self.setState(prevState => ({
        formErrors: {
          ...prevState.formErrors,
          [index]: self.state.form[index].value === "" && self.state.form[index].required ? true : false
        },
      }));

      formMsg = self.state.form[index].value === "" && self.state.form[index].required ? true : formMsg
    })

    this.setState({
      formMsg: formMsg
    })

    if (!formMsg)
      this.handleSubmit(e)
  }

  handleChange = e => {
    e.preventDefault();
    const { name, value } = e.target;

    this.setState({
      [name]: value
    })

    this.setState(prevState => ({
      form: {
        ...prevState.form,
        [name]: {
          ...prevState.form[name],
          value: value
        }
      },

      formErrors: {
        ...prevState.formErrors,
        [name]: value === "" ? true : false
      },
    }));

    let fmsg = false;
    let self = this;

    setTimeout(function () {
      if (document.getElementsByClassName('has-error').length >= 1) {
        fmsg = true;
      }

      self.setState({
        formMsg: fmsg
      })

    }, 300)

  }

  handleSubmit = e => {
    e.preventDefault();

    this.setState({
      loading: true
    });

    let method = this.state.rowid === '' ? 'insert_tr' : 'update_tr'

    const { period, cc, date, hour, extra_hour, night_hour, warning_hour, comments } = this.state.form;

    api.post('/tickets.php', {
      method: method,

      code: this.props.match.params.handle,

      rowid: this.state.rowid,
      period: period.value,

      period_status: this.state.period_status,
      period_start: this.state.period_start,
      period_final: this.state.period_final,

      cc: cc.value,
      tr_date: date.value,
      hour: hour.value,
      extra_hour: extra_hour.value,
      night_hour: night_hour.value,
      warning_hour: warning_hour.value,
      comments: comments.value

    }).then((result) => {

      result.data.status === 'ok' ?

        this.setState({
          form_mode: false,
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              success
              title="Obrigado!"
              onConfirm={() => this.successAlert()}>{this.state.rowid === '' ? 'Time report apontado com sucesso!' : 'Time report atualizado com sucesso!'}</SweetAlert>
          )
        }) :

        this.setState({
          form_mode: true,
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              error
              title={<span className="spanAlert">{result.data.msg}</span>}
              onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });

      result.data.status === 'ok' ? this.preLoadContent() : this.setState({ loading: false })

    });
  }

  successAlert() {
    this.setState({
      alert: null
    });
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  formMode(mode) {
    this.setState({
      form_mode: mode,

      rowid: '',

      period: '',
      period_name: '',
      period_status: '',
      period_start: '',
      period_final: '',

      cc: '',
      cc_name: '',

      date: '',
      hour: '',
      extra_hour: '',
      night_hour: '',
      warning_hour: '',
      comments: '',

      form: {
        period: { value: '', required: true },
        cc: { value: '', required: true },
        date: { value: '', required: true },
        hour: { value: '', required: true },
        extra_hour: { value: '', required: false },
        night_hour: { value: '', required: false },
        warning_hour: { value: '', required: false },
        comments: { value: '', required: true }
      },

      formErrors: {
        period: false,
        cc: false,
        date: false,
        hour: false,
        comments: false
      },

      formMsg: false
    });
  }

  onEditItem(rowid) {
    this.setState({ loading: true })

    this.state.rows.map((item, j) => {
      if (item.rowid === rowid) {
        this.setState({
          form_mode: true,

          rowid: item.rowid,

          period: item.period,
          period_name: item.period_name,
          period_status: item.period_status,
          period_start: item.period_start,
          period_final: item.period_final,

          cc: item.cc,
          cc_name: item.cc_name,

          date: item.date,
          hour: item.hour,
          extra_hour: item.extra_hour,
          night_hour: item.night_hour,
          warning_hour: item.warning_hour,
          comments: item.comments,

          form: {
            period: { value: item.period, required: true },
            cc: { value: item.cc, required: true },
            date: { value: item.date, required: true },
            hour: { value: item.hour, required: true },
            extra_hour: { value: item.extra_hour, required: false },
            night_hour: { value: item.night_hour, required: false },
            warning_hour: { value: item.warning_hour, required: false },
            comments: { value: item.comments, required: true }
          },

          formErrors: {
            period: false,
            cc: false,
            date: false,
            hour: false
          },

          loading: false,
          formMsg: false

        })
      }
    });
  }

  onConfirmRemoveItem(rowid) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "-240px" }}
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={<span className="spanAlert">Tem certeza que deseja remover este apontamento?</span>}
          onConfirm={() => this.onRemoveItem(rowid)}
          onCancel={() => this.hideAlert()}
        >Esta ação não poderá ser desfeita!</SweetAlert>
      )
    });
  }

  onRemoveItem = (rowid) => {
    this.setState({ loading_report: true, alert: null })

    let id = ''

    this.state.rows.map((item, j) => {
      if (item.rowid === rowid) {
        id = item.rowid
      }
    });

    api.post('/tickets.php', {
      method: 'remove_tr',
      rowid: id
    }).then((result) => {

      this.setState({ loading_report: false })

      result.data.status === 'ok' ?

        this.setState(state => {
          const rows = state.rows.filter((item, j) => item.rowid !== rowid);

          return {
            rows
          };
        }) :

        this.setState({
          alert: (
            <SweetAlert
              style={{ display: "block", marginTop: "-240px" }}
              error
              title="Erro ao remover apontamento"
              onConfirm={() => this.hideAlert()}>Por favor, tente novamente.</SweetAlert>
          )
        });

    });
  };

  // Suggest

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions_period(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    api.post("/pickapplet.php", {
      method: 'period',
      value: value
    }).then((response) => {

      const period = response.data

      this.setState({
        suggestions_period: inputLength < 3 ? [] : period.filter(p =>
          (p['name'].toLowerCase().indexOf(inputValue) > -1)
        )
      });
    })
  };

  getSuggestions_cc(value) {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    api.post("/pickapplet.php", {
      method: 'cc',
      value: value
    }).then((response) => {

      const cc = response.data

      this.setState({
        suggestions_cc: inputLength < 3 ? [] : cc.filter(c =>
          (c['name'].toLowerCase().indexOf(inputValue) > -1)
        )
      });
    })
  };

  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue_period(suggestion) {
    return suggestion.name;
  }

  getSuggestionValue_cc(suggestion) {
    return suggestion.name;
  }

  onSuggestionSelected_period = (event, { suggestion }) => {
    this.setState(prevState => ({
      period: suggestion.rowid,
      period_name: suggestion.name,
      form: {
        ...prevState.form,
        period: {
          ...prevState.form.period,
          value: suggestion.rowid
        }
      },

      formErrors: {
        ...prevState.formErrors,
        period: suggestion.rowid === "" ? true : false
      }
    }));

    let fmsg = false;
    let self = this;

    setTimeout(function () {
      if (document.getElementsByClassName('has-error').length >= 1) {
        fmsg = true;
      }

      self.setState({
        formMsg: fmsg
      })

    }, 300)

  };

  onSuggestionSelected_cc = (event, { suggestion }) => {
    this.setState(prevState => ({
      cc: suggestion.rowid,
      cc_name: suggestion.name,
      form: {
        ...prevState.form,
        cc: {
          ...prevState.form.cc,
          value: suggestion.rowid
        }
      },

      formErrors: {
        ...prevState.formErrors,
        cc: suggestion.rowid === "" ? true : false
      }
    }));

    let fmsg = false;
    let self = this;

    setTimeout(function () {
      if (document.getElementsByClassName('has-error').length >= 1) {
        fmsg = true;
      }

      self.setState({
        formMsg: fmsg
      })

    }, 300)

  };

  onChangeSuggest_period = (event, { newValue }) => {
    this.setState({
      period: '',
      period_name: newValue
    });
  };

  onChangeSuggest_cc = (event, { newValue }) => {
    this.setState({
      cc: '',
      cc_name: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested_period = ({ value }) => {
    this.getSuggestions_period(value)
  };

  onSuggestionsFetchRequested_cc = ({ value }) => {
    this.getSuggestions_cc(value)
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested_period = () => {
    this.setState({
      suggestions_period: []
    });
  };

  onSuggestionsClearRequested_cc = () => {
    this.setState({
      suggestions_cc: []
    });
  };

  onSuggestionsBlur_period = () => {
    if (this.state.period === '') {
      this.setState({
        period_name: ''
      });
    }
  };

  onSuggestionsBlur_cc = () => {
    if (this.state.cc === '') {
      this.setState({
        cc_name: ''
      });
    }
  };

  onDatepickerRef(el) { if (el && el.input) { el.input.inputRef.readOnly = true; } }

  render() {

    let ticket = {
      'code': this.state.code,
      'title': this.state.title_text,
      'status': this.state.status,
      'severity': this.state.priority
    }

    const { period_name, cc_name, suggestions_period, suggestions_cc } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps_period = {
      className: 'form-control',
      value: period_name,
      onChange: this.onChangeSuggest_period,
      onBlur: this.onSuggestionsBlur_period,
      autoFocus: this.state.rowid === '' ? true : false,
      spellCheck: false
    };

    const inputProps_cc = {
      className: 'form-control',
      value: cc_name,
      onChange: this.onChangeSuggest_cc,
      onBlur: this.onSuggestionsBlur_cc,
      spellCheck: false
    };
    const status_lst = this.state.statuses;
    return (
      this.state.redirect ?
        <Redirect to="/admtickets" /> :
        <div className="main-content">
          {this.state.alert}
          <HeaderForm ticket={ticket} status_lst={status_lst}></HeaderForm>
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  content={
                    <div>
                      <div className={this.state.loading ? "loading-form" : "fadeOut loading-form"}>
                        <img src={loading} alt="Carregando..." />
                      </div>

                      <form className={this.state.loading ? "opacity form" : "fadeIn form"}>
                        <TabForm
                          handle={this.state.code}
                          active="tr"
                          rowid={this.state.rowid}
                          admin={true}
                        >
                        </TabForm>
                        {!this.state.form_mode ? '' :
                          <div>
                            <div className={this.state.formMsg ? "error-msg-tr fadeIn" : "hide"}>Campos de preenchimento obrigatório</div>
                            <div className="row">
                              <Col sm={4}>
                                <FormGroup className={this.state.formErrors.period ? "has-error" : ""}>
                                  <ControlLabel>Período</ControlLabel>
                                  <Autosuggest
                                    suggestions={suggestions_period}
                                    onSuggestionSelected={this.onSuggestionSelected_period}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested_period}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested_period}
                                    getSuggestionValue={this.getSuggestionValue_period}
                                    renderSuggestion={renderSuggestion_period}
                                    inputProps={inputProps_period}
                                    theme={theme}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={4}>
                                <FormGroup className={this.state.formErrors.cc ? "has-error" : ""}>
                                  <ControlLabel>Centro de custos</ControlLabel>
                                  <Autosuggest
                                    suggestions={suggestions_cc}
                                    onSuggestionSelected={this.onSuggestionSelected_cc}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested_cc}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested_cc}
                                    getSuggestionValue={this.getSuggestionValue_cc}
                                    renderSuggestion={renderSuggestion_cc}
                                    inputProps={inputProps_cc}
                                    theme={theme}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={4}>
                                <FormGroup className={this.state.formErrors.date ? "has-error" : ""}>
                                  <ControlLabel>Data</ControlLabel>
                                  <Datetime
                                    locale="pt-br"
                                    timeFormat={false}
                                    closeOnSelect={true}
                                    inputProps={{ placeholder: "", className: "form-control" }}
                                    onChange={event => {
                                      let value = '';

                                      if (event.format && {}.toString.call(event.format) === '[object Function]')
                                        value = event.format('DD/MM/YYYY')

                                      this.setState(prevState => ({
                                        date: value,

                                        form: {
                                          ...prevState.form,
                                          date: {
                                            ...prevState.form.date,
                                            value: value
                                          }
                                        },

                                        formErrors: {
                                          ...prevState.formErrors,
                                          date: value === "" ? true : false
                                        }
                                      }));

                                      let fmsg = false;
                                      let self = this;

                                      setTimeout(function () {
                                        if (document.getElementsByClassName('has-error').length >= 1) {
                                          fmsg = true;
                                        }

                                        self.setState({
                                          formMsg: fmsg
                                        })

                                      }, 300)

                                    }}
                                    ref={el => this.onDatepickerRef(el)}
                                    value={this.state.date}
                                  />
                                </FormGroup>
                              </Col>

                            </div>

                            <div className="row">
                              <Col sm={3}>
                                <FormGroup className={this.state.formErrors.hour ? "has-error" : ""}>
                                  <ControlLabel>Horas</ControlLabel>
                                  <FormControl
                                    name="hour"
                                    type="number"
                                    min="0"
                                    placeholder=""
                                    value={this.state.hour}
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup>
                                  <ControlLabel>Horas-extra</ControlLabel>
                                  <FormControl
                                    name="extra_hour"
                                    type="number"
                                    min="0"
                                    placeholder=""
                                    value={this.state.extra_hour}
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup>
                                  <ControlLabel>Horas Noturnas (22:00 - 05:00)</ControlLabel>
                                  <FormControl
                                    name="night_hour"
                                    type="number"
                                    min="0"
                                    placeholder=""
                                    value={this.state.night_hour}
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                              <Col sm={3}>
                                <FormGroup>
                                  <ControlLabel>Sobreaviso</ControlLabel>
                                  <FormControl
                                    name="warning_hour"
                                    type="number"
                                    min="0"
                                    placeholder=""
                                    value={this.state.warning_hour}
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </div>

                            <div className="row">
                              <Col sm={12}>
                                <FormGroup className={this.state.formErrors.comments ? "has-error" : ""}>
                                  <ControlLabel>Comentários</ControlLabel>
                                  <FormControl
                                    name="comments"
                                    componentClass="textarea"
                                    rows="3"
                                    cols="80"
                                    placeholder="Descreva aqui os detalhes do apontamento" ref="comments"
                                    value={this.state.comments}
                                    onChange={this.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </div>
                            <div className="btn-tr">
                              <div className="timereport-separator"></div>
                              <button className="btnResetPassword" type="submit" fill onClick={this.handleTypeValidation}>Salvar registro</button>
                              <button className="btnResetPassword" onClick={() => this.formMode(false)} fill>Horas apontadas</button>
                            </div>
                          </div>}

                        {this.state.form_mode ? '' :
                          <div className={this.state.loading ? "opacity" : "fadeIn"}>
                            <h4 class="title title-tr">
                              <div>
                                <span>Time Report</span>
                                {this.state.rows.length > 0 || !this.state.loading_report ?
                                  <div className="table-buttons">
                                    <button className="btnResetPassword" type="button" onClick={() => this.formMode(true)}>Novo registro</button>
                                  </div>
                                  : ''}
                              </div>
                            </h4>

                            <ReactTable
                              ref={(instance) => { this.table = instance; }}
                              data={this.state.rows}
                              style={{ fontSize: 14 }}
                              columns={[
                                {
                                  Header: "Período",
                                  accessor: "period_name",
                                  className: "center"
                                },
                                {
                                  Header: "Centro de custos",
                                  accessor: "cc_name"
                                },
                                {
                                  Header: "Data",
                                  accessor: "date"
                                },
                                {
                                  Header: "Hora",
                                  accessor: "hour",
                                  className: "center"
                                },
                                {
                                  Header: "Extra",
                                  accessor: "extra_hour",
                                  className: "center"
                                },
                                {
                                  Header: "Noturna",
                                  accessor: "night_hour",
                                  className: "center"
                                },
                                {
                                  Header: "Sobreaviso",
                                  accessor: "warning_hour",
                                  className: "center"
                                },
                                {
                                  Header: "",
                                  accessor: "actions",
                                  sortable: false
                                }
                              ]}
                              resized={[
                                { "id": "period_name", "value": 200 },
                                { "id": "cc_name", "value": 200 },
                                { "id": "date", "value": 85 },
                                { "id": "hour", "value": 85 },
                                { "id": "extra_hour", "value": 95 },
                                { "id": "night_hour", "value": 95 },
                                { "id": "warning_hour", "value": 95 },
                                { "id": "actions", "value": 95 }
                              ]}
                              sortable={false}
                              minRows={5}
                              defaultPageSize={5}
                              showPaginationTop={false}
                              showPaginationBottom
                              className="-striped -highlight"
                              previousText="Anterior"
                              nextText="Próxima"
                              loadingText={<img src={loading} className="loading" alt="Carregando..." />}
                              noDataText={this.state.loading_report ? "" : (this.state.error ? this.state.error : <p style={{ fontSize: 14 }}>Sem registros encontrados</p>)}
                              pageText="Página"
                              ofText="de"
                              rowsText="registros"
                              loading={this.state.loading_report}
                            />
                            <div className="separator"></div>
                            {this.state.loading_report ? '' : <FooterForm url="admtickets"></FooterForm>}
                          </div>
                        }
                      </form>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
    );
  }
}

export default TimeReport;